/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "./LeadCard.scss";
import { format, parseISO } from "date-fns";
import { ru } from "date-fns/locale";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Typography } from "@mui/material";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

const LeadCard = ({ lead }) => {
  const managers = useSelector(({ entitiesTypes }) => entitiesTypes.user);

  function setLeadTimeStatus(date_due) {
    const twoHoursInMillis = 2 * 60 * 60 * 1000; // Two hours in milliseconds
    const dueDate = new Date(date_due);
    const currentTime = new Date();
    // Calculate the time difference in milliseconds
    const timeDiff = dueDate - currentTime;
    if (timeDiff < 0) {
      return "expired-lead";
    }
    if (timeDiff < twoHoursInMillis) {
      return "approaching-deadline-lead";
    }
    return "new-lead";
  }

  // get comment from object with object as
  const getComment = (source) => {
    if (source) {
      const { comment = "" } = source[source.length - 1];
      return comment;
    }
    return "недопустимый объект";
  };

  const getCreator = (source) => {
    if (source) {
      const { creator = "неизвестный" } = source[source.length - 1];
      const finded = findLabelByID(creator, managers);
      return finded;
    }
    return "неизвестный";
  };

  // time formatter
  const getTimeAndDate = (time) => {
    const timeAndDate = parseISO(time);
    const formattedDate = format(timeAndDate, "dd MMMM yyyy HH:mm", {
      locale: ru,
    });
    return formattedDate;
  };


  return (
    <div className={`lead-card ${setLeadTimeStatus(lead.date_due)} ${lead.status}` }>
      <div className="lead-details">
        <div className="lead-name">
          {lead.name || ""}, {lead.phone || ""}
        </div>
        {lead.duplicate && <div className="duplicate-info" >это дубликат</div> }
        <div className="lead-info">
          <p className="lead-info-item">{lead.email || ""} </p>
          <p className="lead-info-item manager">
            {" "}
            {findLabelByID(lead.manager, managers)}
          </p>
        </div>
      </div>
      <div className="lead-time-date">
        Создан: {getTimeAndDate(lead.date_created)}
      </div>
      <div className="lead-time-date">
        Дедлайн: {getTimeAndDate(lead.date_due)}
      </div>

      <Tooltip
        key={lead.id_lead}
        TransitionProps={{ timeout: 800 }}
        title={
          <Typography color="inherit">{getComment(lead.comment)}</Typography>
        }
        arrow
        placement="top-end"
      >
        <div className="lead-info-comment">
          {getComment(lead.comment)}
        </div>
      </Tooltip>
      <div className=" lead-info-item manager">
        ({getCreator(lead.comment)})
      </div>
    </div>
  );
};

export default LeadCard;
