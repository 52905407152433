import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropPartOfScreen = ({ open }) => {
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  return (
    <Backdrop open={open} sx={backdropStyles}>
      <CircularProgress value={50} />{" "}
    </Backdrop>
  );
};

// loader for modals
export const SimpleLoader = () => (
  <div
    style={{
      width: "100%",
      zIndex: 1000,
      opacity: 0.5,
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      backgroundColor: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </div>
);
