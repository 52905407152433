import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, DialogContentText } from "@mui/material";

export default function AlertDialog({ open, setOpen, confirm }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 700 }}>
          Вы уверенны, что хотите удалить запись?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Удаленные данные восстановить невозможно.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ fontWeight: 700 }}
            variant="text"
            autoFocus
          >
            Отмена
          </Button>
          <Button
            onClick={confirm}
            sx={{ fontWeight: 700 }}
            color="error"
            variant="text"
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
