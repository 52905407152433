// function getAllowedRoutes(routes, permissions) {
//   return routes.filter((obj) => {
//     if (obj.permissions.every((p) => permissions.includes(p))) return true;
//     return null;
//   });
// }
// export default getAllowedRoutes;


// function getAllowedRoutes(routes, permissions) {
//   return routes
//     .map(group => {
//       // Check if the group has a type and items (indicating it's a grouped structure)
//       if (group.type && Array.isArray(group.items)) {
//         // Filter the items within the group
//         const allowedItems = group.items.filter(item => 
//           item.permissions.every(p => permissions.includes(p))
//         );
//         // Return the group with the allowed items if any
//         if (allowedItems.length > 0) {
//           return { ...group, items: allowedItems };
//         }
//         return null;
//       } 
//         // If it's not a group, check the permissions directly
//         if (group.permissions.every(p => permissions.includes(p))) {
//           return group;
//         }
//         return null;
      
//     })
//     .filter(group => group !== null); // Filter out null groups
// }

// export default getAllowedRoutes;


function getAllowedRoutes(routes, permissions) {
  return routes
    .map((route) => {
      // Check if the route is a group (has 'children' property)
      if (route.children && Array.isArray(route.children)) {
        // Filter the children based on permissions
        const allowedChildren = route.children.filter((child) =>
          child.permissions.every((p) => permissions.includes(p))
        );
        // Return the route with only the allowed children if any
        if (allowedChildren.length > 0) {
          return { ...route, children: allowedChildren };
        }
        return null;
      } 
      
      // If it's a regular route, check the permissions directly
      if ( !route.children && route.permissions?.every((p) => permissions.includes(p))) {
        return route;
      }
      
      return null;
    })
    .filter((route) => route !== null); // Filter out null routes
}

export default getAllowedRoutes;
