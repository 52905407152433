import "./SimpleConfirmModal.scss";
import { Button } from "@mui/material";
import React from "react";

const SimpleConfirmModal = ({ isOpen, onConfirm, content }) => (
  <>
    {isOpen && (
      <div className="simple__modal-overlay">
        <div className="simple__modal-content">
          <p>{content}</p>
          <Button variant="contained" onClick={onConfirm}>
            OK
          </Button>
        </div>
      </div>
    )}
  </>
);

export default SimpleConfirmModal;
