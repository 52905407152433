import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

function flattenRoutes(routes) {
  const flatRoutes = [];

  routes.forEach((route) => {
    if (route.children && Array.isArray(route.children)) {
      route.children.forEach((item) => flatRoutes.push(item));
    } else {
      flatRoutes.push(route);
    }
  });

  return flatRoutes;
}

function MapAllowedRoutes({ routes, basePath }) {
  const match = useRouteMatch(basePath);
  const flattenedRoutes = flattenRoutes(routes);

  return (
    <Switch>
      {flattenedRoutes.map((route) => {
        const {
          path,
          component: Component,
          title,
          permission,
          ...rest
        } = route;

        // Check if path exists before rendering the Route
        if (!path) return null;

        return (
          <Route key={path} path={`${match.path}${path}`} {...rest}>
            <Component title={title} />
          </Route>
        );
      })}

      <Route exact path={basePath}>
        {flattenedRoutes.length > 0 && flattenedRoutes[0].path ? (
          <Redirect
            to={`${match.path}${flattenedRoutes[0].path.replace(/[:].*/, "")}`}
          />
        ) : (
          <Redirect to={match.path} />
        )}
      </Route>

      <Route path="*">
        {flattenedRoutes.length > 0 && flattenedRoutes[0].path ? (
          <Redirect
            to={`${match.path}${flattenedRoutes[0].path.replace(/[:].*/, "")}`}
          />
        ) : (
          <Redirect to={match.path} />
        )}
      </Route>
    </Switch>
  );
}

export default MapAllowedRoutes;

// function flattenRoutes(routes) {
//   const flatRoutes = [];

//   routes.forEach((route) => {
//     if (route.items && Array.isArray(route.items)) {
//       route.items.forEach((item) => flatRoutes.push(item));
//     } else {
//       flatRoutes.push(route);
//     }
//   });

//   return flatRoutes;
// }

// function MapAllowedRoutes({ routes, basePath }) {
//   const match = useRouteMatch(basePath);
//   const flattenedRoutes = flattenRoutes(routes);

//   return (
//     <Switch>
//       {flattenedRoutes.map((route) => {
//         const {
//           path,
//           component: Component,
//           title,
//           permission,
//           ...rest
//         } = route;
//         return (
//           <Route key={path} path={`${match.path}${path}`} {...rest}>
//             <Component title={title} />
//           </Route>
//         );
//       })}
//       <Route exact path={basePath}>
//         {/* Redirect to the first allowed route and remove route params in the query string */}
//         <Redirect to={`${match.path}${flattenedRoutes[0].path.replace(/[:].*/, "")}`} />
//       </Route>
//       <Route path="*">
//         <Redirect to={`${match.path}${flattenedRoutes[0].path.replace(/[:].*/, "")}`} />
//       </Route>
//     </Switch>
//   );
// }

// export default MapAllowedRoutes;
