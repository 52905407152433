import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import DataGrid from "../DataGrid/DataGrid";
import {
  fetchContactHistory,
  getContacts,
} from "../../redux/actions/contactsActions";
import ParentForLeadsAndContact from "../ModalsForSalesWithAgreementsAndLeads/ParentForLeadsAndContact";
import { contactsApi } from "../../utils/api";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

const ContactsBase = ({
  contacts,
  permissions,
  loading,
  fetchContact,
  ...props
}) => {
  const globalLoad = [loading];
  const dispatch = useDispatch();
  const historyLoading = contacts.loadingHistory;
  const history = contacts.contactHistory;
  const entities = useSelector(({ entitiesTypes }) => entitiesTypes);

  // passing customer information to the modal window
  const [contact, setContract] = React.useState([]);

  const [ContactModalOpen, setContactModalOpen] = React.useState(false);
  const handleContactOpen = () => {
    setContactModalOpen(true);
  };
  const handleContactClose = () => {
    setContactModalOpen(false);
  };

  const handleRowDetails = (row) => {
    console.log(row);
    setContract(row.row);
    dispatch(fetchContactHistory(row.row.id_contact));
    historyLoading ? null : history && handleContactOpen();
  };

  function getFullName(params) {
    return `${params.row.name || ""} ${params.row.last_name || ""}`;
  }

  const initialSearchState = {
    full_name: "",
    phone: "",
    comment: "",
  };

  const [inputs, setInputs] = React.useState(initialSearchState);
  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log("inputs", inputs);
  };

  const handleStartSearch = () => {
    const str = Object.values(inputs).join(" ");
    // console.log(str);
    contactsApi.search(str).then((response) => {
      const united = Object.assign([
        ...response.data.contacts,
        ...response.data.leads,
      ]);
      dispatch(getContacts(united));
      // console.log(united);
    });
  };

  const [hideColumns, setHideColums] = React.useState(false);
  const handleToggleColumns = () => {
    setHideColums(!hideColumns);
  };

  const displayed = [
    "name",
    "phone",
    "kid_name",
    "city",
    "comment",
    "id_lead",
    "id_contact",
  ];

  const changeColumnsQuantity = (trigger, col) =>
    trigger ? col.filter((item) => displayed.includes(item.field)) : col;

  const columns = [
    {
      field: "id_contact" || "id_lead",
      headerName: "Тип",
      hide: false,
      renderCell: (params) => {
        // console.log(params);
        if (params.row.id_lead) {
          return <div style={{ color: "red" }}>ЛИД</div>;
        }
        return <div style={{ color: "green" }}>КОНТАКТ</div>;
      },
    },
    {
      field: "name",
      headerName: "Имя",
      valueGetter: getFullName,
      flex: 2,
    },
    {
      field: "date_created",
      headerName: "Дата создания",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Телефон",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Почта",
      flex: 1,
    },
    {
      field: "kid_name",
      headerName: "Ребенок",
      flex: 1,
    },
    {
      field: "kid_class",
      headerName: "Класс",
      flex: 1,
    },
    {
      field: "year_of_birth",
      headerName: "Год рождения",
      flex: 1,
    },

    {
      field: "city",
      headerName: "Город",
      flex: 1,
    },
    {
      field: "passport_contact",
      headerName: "Паспортные данные",
      flex: 1,
    },
    {
      field: "registration_contact",
      headerName: "Регистрация",
      flex: 1,
    },
    {
      field: "comment",
      headerName: "Комменты",
      flex: 1,
      renderCell: (params) => {
        if (typeof params.value === "object") {
          return params.value.map((item) => item.comment);
        }
        return params.value;
      },
    },
    {
      field: "utm_source",
      headerName: "Источник",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) return "---";
        return findLabelByID(params.value, entities.utm_source);
      },
    },
    {
      field: "interested_program",
      headerName: "Интересно",
      flex: 1,
    },
  ];

  return (
    <>
      <div>
        Двойное нажатие на строку с контактом откроет окно редактирования
        контакта
      </div>

      {/* <div>Расширенный поиск контактов</div> */}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "40%", marginRight: "50px" }}>
          <TextField
            variant="standard"
            size="small"
            name="full_name"
            label="ФИО / телефон / имя ребенка / слова в комментариях"
            value={inputs.full_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          {/* <TextField
            variant="standard"
            size="small"
            label="Номер телефона"
            name="phone"
            value={inputs.phone}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            variant="standard"
            size="small"
            label="Комментарий"
            name="comment"
            value={inputs.comment}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            height:"30px",
            flexWrap: "wrap"
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setInputs(initialSearchState);
            }}
          >
            Очистить
          </Button>
          <Button variant="contained" size="small" onClick={handleStartSearch}>
            Поиск
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleToggleColumns}
          >
            {hideColumns ? "показать все колонки" : "спрятать часть колонок"}
          </Button>
        </div>
      </div>

      <DataGrid
        columns={changeColumnsQuantity(hideColumns, columns)}
        onRowDoubleClick={(event, row) => handleRowDetails(event, row)}
        entityName="payload"
        getRowId={(row) => `${row.id_contact}`}
        permissions={permissions}
        data={contacts.payload}
        globalState={globalLoad}
        components={{}}
        {...props}
      />
      <ParentForLeadsAndContact
        open={ContactModalOpen}
        onClose={handleContactClose}
        contactFromContactDialog={contact}
        contactHistory={history}
        type="contact"
      />
    </>
  );
};

export default ContactsBase;
