import React from "react";
import "./CreateLeadDialog.scss";
import { useDispatch, useSelector } from "react-redux";
import CreateLeadDialog from "./CreateLeadDialog";
import ContactDialog from "./ContactDialog";
import { addContact } from "../../redux/actions/contactsActions";

// If the contact is called from createLead component, 
// callback is triggered by launching contactDialog Open, 
// you also need to start getHistory from CreateLead to get contact at contacts.contactData
const ParentForLeadsAndContact = ({ ...props }) => {
  const dispatch = useDispatch();
  const contact = useSelector(({ contacts }) => contacts.contactData);
  // const contact = useSelector(({ contacts }) => contacts.payload[0]);
  const history = useSelector(({ contacts }) => contacts.contactHistory);
  const [ContactModalOpen, setContactModalOpen] = React.useState(false);
  const [forceUpd, setForceUpd] = React.useState(false);

  const handleContactOpen = () => {
    setContactModalOpen(true);
  };
  const handleContactClose = () => {
    setForceUpd(!forceUpd);
    setContactModalOpen(false);
  };

  const handleGoToContactDialog = () => {
    handleContactOpen();
    //   console.log("КОНТАКТ", currentContact)
  };
  const callbackParent = () => {
    // console.log("call me");
    // console.log('props', props)
    // dispatch(addContact(contact?.payload));
    setContactModalOpen(true);
  };

  return (
    <>
      {props.type === "contact" && (
        <ContactDialog
          open={props.open}
          onClose={props.onClose}
          contactFromContactDialog={props.contactFromContactDialog}
          contactHistory={props.contactHistory}
        />
      )}
      {props.type === "lead" && (
        <CreateLeadDialog
          callback={callbackParent}
          open={props.open}
          onClose={props.onClose}
          lead={props.lead}
          forceUpd={forceUpd}
        />
      )}
      {contact?.currentContact && (
        <ContactDialog
          open={ContactModalOpen}
          onClose={handleContactClose}
          contactFromContactDialog={contact?.currentContact}
          contactHistory={history}
        />
      )}
    </>
  );
};

export default React.memo(ParentForLeadsAndContact);
