import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ruRU } from "./dataGridLib/x-data-grid-pro";
import { LicenseInfo } from "./dataGridLib/x-license-pro";

import store from "./redux/store";

import "./scss/index.scss";

import App from "./App";

LicenseInfo.setLicenseKey(
  "ba580767893eb4d7ada466dea869e106T1JERVI6TVVJLTEyMyxFWFBJUlk9MTYzODkxMDA0ODI5MixLRVlWRVJTSU9OPTE="
);
const theme = createTheme(
  {
    palette: {
      type: "light",
      primary: {
        main: "#2999DD",
        light: "#5AABF8",
        dark: "#2d79c1",
      },
      secondary: {
        main: "#24ab7e",
        light: "rgba(39, 221, 160, 0.15)",
        dark: "#20A377",
      },
      grey: {
        main: "#eeeeee",
        light: "#f5f5f5",
        dark: "#bdbdbd",
      },
      error: {
        main: "#fa0202",
      },
    },
    shape: {
      borderRadius: 4,
    },
    props: {
      MuiAppBar: {
        color: "transparent",
      },
    },
    typography: {
      fontFamily: "Nunito Sans",
      h1: {
        fontSize: "2.5rem",
        fontWeight: "400",
      },
      h2: {
        fontSize: "2rem",
        fontWeight: "400",
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: "400",
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: "600",
      },
      h5: {
        fontSize: "1.15rem",
        fontWeight: "700",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: "700",
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
          },
        },
      },
    },
  },
  ruRU
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// comment AAnn 
