import { groupsApi, studentsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getStudents = (data) => ({
  type: "GET_STUDENTS",
  payload: data,
  loading: false,
});

export const loadingStudents = (data) => ({
  type: "LOADING_STUDENTS",
  loading: data,
});

export const modalIsOpen = () => ({
  type: "MODAL_IS_OPEN",
  modal: true,
});

export const modalClose = () => ({
  type: "MODAL_CLOSE",
  modal: false,
});

export const getConfirm = (data) => ({
  type: "GET_CONFIRM_DATA",
  payload: data,
  modal: true,
  loading: false,
});

export const updateStudents = (data) => ({
  type: "UPDATE_STUDENTS",
  payload: data,
  loading: false,
});

export const fetchStudents = () => (dispatch) => {
  dispatch(loadingStudents(true));
  return groupsApi.get().then(({ data }) => dispatch(getStudents(data.groups)));
};

export const getConfirmData = (obj) => (dispatch) => {
  dispatch(loadingStudents(true));
  return studentsApi.update(obj).then(({ data }) => {
    dispatch(getConfirm(data.show));
  });
};

export const updateStudentsAfterConfirm = (obj) => (dispatch) => {
  dispatch(loadingStudents(true));
  return studentsApi.update(obj).then(({ data }) => {
    dispatch(updateStudents(data.show));
    dispatch(fetchStudents());
    notification.success("Группы изменены");
  });
};
