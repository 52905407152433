/* eslint-disable consistent-return */
import React from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";
import DataGrid from "../../components/DataGrid/DataGrid";
import { fetchDebt } from "../../redux/actions/debtActions";
import { setPageTitle } from "../../redux/actions/globalActions";

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.debt.payload,
  loading: state.debt.loading,
  permissions: {
    modify: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(fetchDebt()),
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function DebtPage({ title, setTitle, ...props }) {
  const locations = useSelector(({ entitiesTypes }) => entitiesTypes.location);
  const { data } = props;
  // console.log("debt", data);
  const [expandBalance, setExpandBalance] = React.useState(false);
  const [totalBalance, setTotalBalance] = React.useState({});
  const [allBalance, setAllBalance] = React.useState(0);

  const getLocationsLabel = (obj, l) => {
    if (!obj) {
      return;
    }
    if (!l) {
      return;
    }
    const fullNamesObj = {};
    Object.keys(obj).map((key) =>
      l.map((location) => {
        if (location.value === key) {
          // console.log(obj[location.label]);
          fullNamesObj[location.label] = Math.abs(
            obj[location.value].toFixed(2)
          );
        }
        return fullNamesObj;
      })
    );
    return fullNamesObj;
  };

  const summorizer = (arr) => {
    if (!arr) {
      return;
    }
    const balanceByLocation = {};
    arr.forEach((item) => {
      if (!balanceByLocation[item.location_id]) {
        balanceByLocation[item.location_id] = 0;
      }
      if (item.balance < 0) {
        balanceByLocation[item.location_id] += item.balance;
      }
    });
    setTotalBalance(getLocationsLabel(balanceByLocation, locations));
  };

  const calculateTotalBalance = (arr) => {
    if (!arr) {
      return;
    }
    let all_balance = 0;
    arr.forEach((item) => {
      if (item.balance < 0) {
        all_balance += item.balance;
      }
    });
    setAllBalance(Math.abs(all_balance.toFixed(2)));
  };

  React.useEffect(() => {
    summorizer(data);
    calculateTotalBalance(data);
  }, [data]);

  React.useEffect(() => {
    setTitle(title);
  }, []);

  const changeLocations = (params) => {
    locations.map((item) => {
      if (item.value === params.value) {
        params.value = item.label;
      }
      return params.value;
    });
    return params.value;
  };

  const columns = [
    {
      field: "full_name",
      headerName: "ФИО",
      flex: 1.2,
      renderCell: (params) => (
        <Link
        to= {{ pathname:`/app/contracts/${params.row.contract_id}/${params.row.number}/${params.row.full_name}`, state:"debt"}}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "number",
      headerName: "Договор",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Телефон",
      flex: 1,
    },
    {
      field: "debt",
      headerName: "Дебет",
      flex: 1,
      valueFormatter: (params) => `${params.value}р.`,
    },
    {
      field: "credit",
      headerName: "Кредит",
      flex: 1,
      valueFormatter: (params) => `${params.value}р.`,
    },
    {
      field: "balance",
      headerName: "Сумма",
      flex: 1,
      renderCell: ({ value }) => {
        if (value < 0) {
          return (
            <span style={{ color: "red" }}>
              {value.toString().replace("-", "")}р.
            </span>
          );
        }
        return `${value}р.`;
      },
    },
    {
      field: "contract_url",
      hide: true,
    },
    {
      field: "location_id",
      headerName: "Филиал",
      flex: 1.2,
      valueGetter: (params) => changeLocations(params),
    },
  ];
  const theme = useTheme();
  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>
      <div style={{ marginLeft: "20px" }}>
        {" "}
        Общий остаток(без переплат): {allBalance}
      </div>
      {totalBalance && (
        <div style={{ marginLeft: "20px" }}>
          <Button
            variant="contained"
            sx={{
              cursor: "pointer",
              padding: "2px",
              [theme.breakpoints.down("md")]: {
                fontSize: "10px",
              },
              mb: "10px",
            }}
            onClick={() => setExpandBalance(!expandBalance)}
          >
            {expandBalance
              ? "Скрыть остаток по филиалам"
              : "Показать остаток по филиалам"}
          </Button>
          <div style={{ display: expandBalance ? "block" : "none" }}>
            <ul
              style={{
                border: "1px solid rgba(192,192,192,0.5)",
                width: "fit-content",
                padding: "5px",
              }}
            >
              {Object.keys(totalBalance).map((key) => (
                <li key={key}>
                  {" "}
                  {key} : {totalBalance[key]} .p
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <DataGrid
        columns={columns}
        dataGridName="debt"
        getRowId={(row) => `${row.number}${row.contract_id}`}
        {...props}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DebtPage);
