/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";

import "./CreatingContractMain.scss";
import { TextField, MenuItem, Button } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CreateConfirmDialog from "./CreateConfirmDialog";
import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import { fetchFakeLeads, updLead } from "../../redux/actions/leadsActions";
import filterArray from "../../utils/helpers/forCreateContract";

// проверка на пустые свойства в объекте inputs
// TODO:  сделать нормальную валидацию
export const checkEmptyFields = (obj) => {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] === "" &&
      key !== "utm_campaign" &&
      key !== "utm_term" &&
      key !== "utm_source" &&
      key !== "payments" &&
      key !== "city"
    ) {
      return key; // Если найдено пустое поле, возвращаем true
    }
  }
  return false; // Если пустых полей не найдено, возвращаем false
};

function CreatingAcademicYear({ data, entities, lead, contact, lastLeadId }) {
  const { location, requisites } = entities;
  const { courses, groups } = data;
  const today = new Date().toISOString().split("T")[0];
  const defaultUser = useSelector(({ user }) => user.info);
  const kindergartenCourses = useSelector(
    (state) => state.entitiesTypes.course_of_kindergarten
  );
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [disableActionBtns, setDisableActionBtns] = React.useState(false);
  const [contractIinfo, setContractInfo] = React.useState("");
  const [createError, setCreateError] = React.useState(false);
  const [showAdaptationField, setShowAdaptationField] = React.useState(false);
  const dispatch = useDispatch();

  console.log("lead", lead);

  const onlyAcademicCourses = courses
    ?.slice()
    .filter((course) => course.type === "course");
  const [filteredGroups, setFilteredGroups] = React.useState(groups);

  // console.log(location, course, requisites, groups, nutrition);

  const academicStateInit = {
    lessons_type: "academic-year",
    id_lead: lead?.id_lead?.toString() || lastLeadId || "",
    full_name: contact?.name || "",
    kid_name: contact?.kid_name || "",
    year_of_birth:
      contact?.year_of_birth?.toString() ||
      lead?.year_of_birth?.toString() ||
      "",
    passport_contact: contact?.passport_contact || "",
    registration_contact: contact?.registration_contact || "",
    city: contact?.city || "",
    location: "",
    course: "",
    requisite: "",
    group: "",
    start_date: today,
    weeks: "",
    full_price: "",
    week_price: "",
    hours_per_week: "4",
    // manager: lead?.manager || defaultUser.id.toString(),
    user_id: defaultUser.id.toString() || "",
    contact_id:
      contact?.id_contact.toString() || lead?.id_contact.toString() || "",
    payments: [
      { date: today, amount: "111", count_week: "3" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
    ],
    utm_source: lead?.utm_source || "",
    utm_campaign: "",
    utm_term: "",
    hours_of_adaptation: "00:00:00",
  };

  const [inputs, setInputs] = React.useState(academicStateInit);

  const [tableData, setTableData] = useState([
    { date: today, amount: "111", count_week: "3" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
  ]);

  const handleDataChange = (index, field, value) => {
    const updatedData = [...tableData];
    updatedData[index][field] = value;
    setTableData(updatedData);
    // eslint-disable-next-line no-return-assign
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      payments: updatedData,
      // ...prevInputsState.payments[index][field] = value
    }));
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    // filtered groups, when user choose course('название программы').
    // We have course_id in groups array
    if (name === "course") {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        weeks: "",
        week_price: "",
        full_price: "",
      }));
      const filteredArray = filterArray(groups, value, inputs.location);
      setFilteredGroups([...filteredArray]);
      // check if course is kindergarten, then show adaptation field
      const checker = kindergartenCourses.some((item) => item.value === value);
      setShowAdaptationField(checker);
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        hours_of_adaptation: checker ? "23:59:00" : "00:00:00",
      }));
    }
    if (name === "location") {
      const filteredArray = filterArray(groups, inputs.course, value);
      setFilteredGroups(() => [...filteredArray]);
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        weeks: "",
        week_price: "",
        full_price: "",
      }));
    }
    if (name === "group") {
      const filtered = groups
        ?.slice()
        // eslint-disable-next-line eqeqeq
        .filter((group) => group.id == value);
      const [expanded] = filtered;
      const fullPrice = Number.parseFloat(
        Number(expanded.price_per_week) * Number(expanded.weeks)
      );
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        weeks: expanded.weeks,
        week_price: expanded.price_per_week,
        full_price: fullPrice.toString(),
      }));
    }
  };

  // TODO: вынести запросы, переписать через апишку все(вл всех 3х компонентах Camp, AcademicYear, Individual)
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkEmptyFields(inputs)) {
      notification.info(`вы не заполнили ${checkEmptyFields(inputs)}`);
      return;
    }
    contractApi
      .create({ contract: inputs })
      .then((response) => {
        setContractInfo(response.data);
        notification.success("Договор создан");
        // dispatch(fetchContactsFake());
        dispatch(fetchFakeLeads());
        // dispatch(
        //   updContactFromCreateContract({
        //     ...inputs,
        //     id_contact: inputs.contact_id,
        //     name: inputs.full_name,
        //     phone: contact?.phone
        //   })
        // );
        notification.success("Данные контакта обновлены");
        dispatch(
          updLead({
            ...inputs,
            id_contact: inputs.contact_id,
            name: inputs.full_name,
          })
        );
        notification.success("Данные Лидов обновлены");
      })
      .catch((e) => {
        setCreateError(`${e.message} ${e.response?.data?.message}`);
        notification.error("Не удалось создать договор");
      });
    setOpenConfirmation(true);
  };

  const deleteContract = () => {
    contractApi.remove({ contract: contractIinfo.contract_id }).then((resp) => {
      if (resp.data.status === "OK") {
        notification.success("Договор удален");
        setDisableActionBtns(true);
      }
    });
    setOpenConfirmation(true);
  };

  const saveContract = () => {
    contractApi.save(contractIinfo.file_name).then((resp) => {
      const href = URL.createObjectURL(resp.data);
      const link = document.createElement("a");
      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      link.href = href;
      link.setAttribute("download", `${contractIinfo.file_name}`);
      document.body.appendChild(link);
      // clean up "a" element & remove ObjectURL
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      notification.success("Договор сохранен");
      setOpenConfirmation(true);
    });
  };

  const handleTakeCalculation1 = () => {
    const infoToSend = {
      location: inputs.location,
      course: inputs.course,
      start_date: inputs.start_date,
      full_price: inputs.full_price,
    };
    // проверка есть ли все необходимые поля для getCalculations
    const emptyProperties = Object.keys(infoToSend).filter(
      (key) => infoToSend[key] === ""
    );
    if (emptyProperties.length > 0) {
      notification.info(
        'для рассчета необходимо заполнить поля: "Локация", "Название программы", "Дата начала", "Полная стоимость"'
      );
    } else {
      contractApi.getCalculations(infoToSend).then((response) => {
        // сетаем в табличку, для отображения на экране, так же в объекте, который пойдет на сервер перезапишем стэйт и добавим в payments данные
        setTableData(response.data);
        setInputs((prevInputsState) => ({
          ...prevInputsState,
          payments: response.data,
        }));
      });
    }
  };

  const handleTakeCalculation2 = () => {
    const infoToSend = {
      weeks: inputs.weeks,
      full_price: inputs.full_price,
      start_date: inputs.start_date,
      group_id: inputs.group,
      requisites: inputs.requisite,
      strategy: "per_week",
    };
    // проверка есть ли все необходимые поля для getCalculations
    const emptyProperties = Object.keys(infoToSend).filter(
      (key) => infoToSend[key] === ""
    );
    if (emptyProperties.length > 0) {
      notification.info(
        'для рассчета необходимо заполнить поля: "Объем курса", "Название программы", "Дата начала", "Реквизиты"'
      );
    } else {
      contractApi
        .getCalculations9parts(infoToSend)
        .then((response) => {
          // сетаем в табличку, для отображения на экране, так же в объекте, который пойдет на сервер перезапишем стэйт и добавим в payments данные
          setTableData(response.data);
          setInputs((prevInputsState) => ({
            ...prevInputsState,
            payments: response.data,
          }));
        })
        .catch((e) => alert(`${e.message} ${e.response?.data?.message}`));
    }
  };

  const handleTakeCalculation3 = () => {
    const infoToSend = {
      weeks: inputs.weeks,
      full_price: inputs.full_price,
      start_date: inputs.start_date,
      group_id: inputs.group,
      requisites: inputs.requisite,
      strategy: "9parts",
    };
    // проверка есть ли все необходимые поля для getCalculations
    const emptyProperties = Object.keys(infoToSend).filter(
      (key) => infoToSend[key] === ""
    );
    if (emptyProperties.length > 0) {
      notification.info(
        'для рассчета необходимо заполнить поля: "Объем курса", "Название программы", "Дата начала", "Реквизиты"'
      );
    } else {
      contractApi
        .getCalculations9parts(infoToSend)
        .then((response) => {
          // сетаем в табличку, для отображения на экране, так же в объекте, который пойдет на сервер перезапишем стэйт и добавим в payments данные
          setTableData(response.data);
          setInputs((prevInputsState) => ({
            ...prevInputsState,
            payments: response.data,
          }));
        })
        .catch((e) => alert(`${e.message} ${e.response?.data?.message}`));
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginRight: "50px",
          }}
        >
          <TextField
            margin="dense"
            size="small"
            name="full_name"
            label="ФИО Родителя"
            value={inputs.full_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="ФИО Ребенка"
            name="kid_name"
            value={inputs.kid_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Год рождения ребенка"
            name="year_of_birth"
            value={inputs.year_of_birth}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Паспортные данные"
            name="passport_contact"
            value={inputs.passport_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Данные о регистрации"
            name="registration_contact"
            value={inputs.registration_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Локация"
            fullWidth
            select
            name="location"
            value={inputs.location}
            onChange={(event) => handleInputsChange(event)}
          >
            {location.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Реквизиты"
            fullWidth
            select
            name="requisite"
            value={inputs.requisite}
            onChange={(event) => handleInputsChange(event)}
          >
            {requisites.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Название программы"
            fullWidth
            select
            name="course"
            value={inputs.course}
            onChange={(event) => handleInputsChange(event)}
          >
            {onlyAcademicCourses.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Учебная группа"
            fullWidth
            select
            name="group"
            value={inputs.group}
            onChange={(event) => handleInputsChange(event)}
          >
            {filteredGroups?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.short_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ display: showAdaptationField ? "block" : "none" }}
            // disabled={!showAdaptationField}
            hidden={!showAdaptationField}
            margin="dense"
            size="small"
            label="адаптация"
            fullWidth
            select
            name="hours_of_adaptation"
            value={inputs.hours_of_adaptation}
            onChange={(event) => handleInputsChange(event)}
          >
            <MenuItem key={0} value="00:00:00">
              Нет
            </MenuItem>
            <MenuItem key={0} value="23:59:00">
              Есть
            </MenuItem>
          </TextField>
          <TextField
            //  InputProps={{
            //   style: { fontSize: '12px' },
            //   inputProps: { className: 'small-input' },
            // }}
            margin="dense"
            type="date"
            size="small"
            label="Начало обучения"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="start_date"
            value={inputs.start_date}
            onChange={(event) => handleInputsChange(event)}
          />

          <TextField
            margin="dense"
            size="small"
            label="Объем курса"
            fullWidth
            name="weeks"
            value={inputs.weeks}
            onChange={(event) => handleInputsChange(event)}
          />

          <TextField
            margin="dense"
            size="small"
            label="Стоимость учебной недели"
            fullWidth
            name="price_per_week"
            value={inputs.week_price}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            margin="dense"
            size="small"
            label="Полная стоимость"
            fullWidth
            name="full_price"
            value={inputs.full_price}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            margin="dense"
            size="small"
            label="Количество часов в неделю"
            fullWidth
            name="hours_per_week"
            value={inputs.hours_per_week}
            onChange={(event) => handleInputsChange(event)}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            paddingTop: "10px",
          }}
        >
          <Table size="small" className="contract__payment_table">
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Кол-во недель</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      size="small"
                      value={row.date}
                      type="date"
                      onChange={(e) =>
                        handleDataChange(index, "date", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      type="number"
                      size="small"
                      value={row.amount}
                      onChange={(e) =>
                        handleDataChange(index, "amount", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      type="number"
                      size="small"
                      value={row.count_week}
                      onChange={(e) =>
                        handleDataChange(index, "count_week", e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="contract__actions">
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Создать договор
            </Button>
            <Button
              onClick={handleTakeCalculation1}
              variant="contained"
              color="primary"
            >
              Рассчитать 10 платежей
            </Button>
            <Button
              onClick={handleTakeCalculation2}
              variant="contained"
              color="primary"
            >
              Рассчитать по неделям
            </Button>
            <Button
              onClick={handleTakeCalculation3}
              variant="contained"
              color="primary"
            >
              Рассчитать по месяцам
            </Button>
          </div>
        </div>
        <CreateConfirmDialog
          name={inputs?.full_name}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          setError={setCreateError}
          error={createError}
          disableActionBtns={disableActionBtns}
          setDisableActionBtns={setDisableActionBtns}
          del={() => {
            deleteContract();
          }}
          save={() => {
            saveContract();
          }}
        />
      </div>
      <pre>{JSON.stringify(inputs, null, 2)}</pre>
    </>
  );
}

export default CreatingAcademicYear;
