const { stage } = window.clientConfig;

const trackerURL = () => {
  switch (stage) {
    case "v1":
      return "https://stemlab.school/tracker/crm/dev";
    case "v2":
      return "https://stemlab.school/tracker/crm/prod";
    case "qa":
      return "https://stemlab.school/tracker/crm/pp";
    default:
      return "";
  }
};


export default trackerURL;
