/* eslint-disable consistent-return */
import PivotTable from "react-pivottable/PivotTable";
import "../scss/table.scss";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { fetchPaymentsSchedule } from "../redux/actions/paymentsScheduleActions";

function PaymentsSchedule({ title }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchPaymentsSchedule());
  }, []);

  const paymentsData = useSelector(
    ({ payments_schedule }) => payments_schedule
  );

  const paymentsDataCopy = JSON.parse(JSON.stringify(paymentsData));

  const allLocations = useSelector(
    ({ entitiesTypes }) => entitiesTypes.location
  );
  const [location, setlocation] = React.useState("все филиалы");
  const handleChangeLocation = (event) => {
    setlocation(event.target.value);
  };

  // const data = (callback) =>
  //   new Promise((resolve) => {
  //     if (paymentsData.payload !== undefined) {
  //       resolve(paymentsData.payload.map((obj) => callback(obj)));
  //     }
  //   });

  const selectorByLocations = (source) => {
    if (!source) {
      return;
    }
    if (source && location === "все филиалы") {
      return source;
    }
    if (source) {
      return source.filter((item) => item.location_id === location);
    }
  };

  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>
        <div
          className="location__title"
          style={{ padding: "5px 30px 0px 30px", fontWeight: "bold" }}
        >
          Выберите филиал:
        </div>
        <Box sx={{ padding: "0 30px 15px 30px" }}>
          <FormControl sx={{ width: "fit-content", minWidth: "150px" }}>
            <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              label="location"
              size="small"
              onChange={handleChangeLocation}
            >
              {allLocations?.map((item) => (
                <MenuItem key={uuidv4()} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem value="все филиалы">все филиалы</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <div
          className="table__wrapper"
          style={{ overflowX: "auto", height: 700 }}
        >
          {paymentsData.loading ? (
            <Backdrop
              open={paymentsData.loading}
              sx={{
                bgcolor: "#fff",
                zIndex: 3,
                position: "relative",
                height: "800px",
                borderRadius: "10px",
              }}
            >
              <CircularProgress />
            </Backdrop>
          ) : (
            <PivotTable
              data={selectorByLocations(paymentsDataCopy.payload)}
              rows={["month"]}
              cols={["type"]}
              vals={["amount"]}
              aggregatorName="Sum"
              sorters={{ month: (a, b) => (b > a ? 1 : -1) }}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default PaymentsSchedule;
