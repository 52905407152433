import { loginApi } from "../../utils/api";

export const login = (data) => ({
  type: "LOGIN",
  payload: data,
});
export const logoutAction = () => ({
  type: "LOGOUT",
});

export const saveAction = (data) => ({
  type: "SAVE_SETTINGS",
  payload: data,
});

export const saveSortAction = (data) => ({
  type: "SAVE_SETTINGS_SORT",
  payload: data,
});

export const saveSortByDueAction = (data) => ({
  type: "SAVE_SETTINGS_SORT_BY_DUE",
  payload: data,
});

export const logout = () => (dispatch) => {
  dispatch(logoutAction());
  loginApi.logout();
};

export const saveSettings = (data) => (dispatch) => {
  dispatch(saveAction(data));
};

export const saveSortSettings = (data) => (dispatch) => {
  dispatch(saveSortAction(data));
};

export const saveSortByDueSettings = (data) => (dispatch) => {
  dispatch(saveSortByDueAction(data));
};
