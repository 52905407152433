/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import axios from "axios";
import "./ContractTemplates.scss";

const ContractTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [templates, setTemplates] = useState([
    "School_IP",
    "School_IIP",
    "Stem_programm",
    "StemG",
    "CampRB",
    "CampG",
    "Individual",
    "Садик",
  ]);

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.files[0]);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setFileName(droppedFile.name);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleReset = () => {
    setFile(null);
    setFileName("");
  };

  const handleDownload = () => {
    // if (selectedTemplate) {
    //     axios.get(`/templates/${selectedTemplate}`, { responseType: 'blob' })
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', `${selectedTemplate}.docx`);
    //             document.body.appendChild(link);
    //             link.click();
    //         })
    //         .catch((error) => {
    //             console.error('Error downloading the template:', error);
    //         });
    // } else {
    //     alert('Please select a template to download');
    // }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (file && selectedTemplate) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("template", selectedTemplate);

      axios
        .post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          alert("File uploaded successfully");
        })
        .catch((error) => {
          console.error("Error uploading the file:", error);
        });
    } else {
      alert("Please select a template and a file to upload");
    }
  };

  return (
    <div className="contract__templates">
      <div className="templates__sector">
        <h2>Скачать шаблон</h2>
        <div className="templates__form-group">
          <label htmlFor="choose-template">Выберите шаблон:</label>
          <select
            id="template"
            value={selectedTemplate}
            onChange={handleTemplateChange}
          >
            <option value="">Нет</option>
            {templates.map((template) => (
              <option key={template} value={template}>
                {template}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={!selectedTemplate}
          onClick={handleDownload}
          type="button"
          className="btn_template template-download"
        >
          Скачать
        </button>
      </div>
      <div className="templates__sector">
        <h2>Загрузить шаблон</h2>
        <form onSubmit={handleUpload} className="templates__upload-form">
          <div
            className="templates__dropzone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById("file-input").click()}
          >
            {fileName ? (
              <p>{fileName}</p>
            ) : (
              <p>Перетащите файл сюда, либо нажмите чтобы загрузить</p>
            )}
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <div className="template__upload_btns">
            <button type="submit" className="btn_template template-upload">
              Загрузить
            </button>
            <button
              disabled={!file}
              type="button"
              className="btn_template templay-reset"
              onClick={handleReset}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContractTemplates;
