const initialState = {
	new: [],
	ongoing: [],
	camp: [],
	payByDayAndByCity: [],
	firstVisit: [],
	loading: {
		new: false,
		ongoing: false,
	},
};

const reportReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_NEW_REPORT":
			return {
				...state,
				new: action.payload,
				loading: {
					...state.loading,
					new: action.loading,
				},
			};
		case "SET_ONGOING_REPORT":
			return {
				...state,
				ongoing: action.payload,
				loading: {
					...state.loading,
					ongoing: action.loading,
				},
			};
		case "SET_PAY_BY_DATE_BY_CITY_REPORT":
			return {
				...state,
				payByDayAndByCity: action.payload,
				loading: {
					...state.loading,
					payByDayAndByCity: action.loading,
				},
			};
		case "SET_CAMP_REPORT":
			return {
				...state,
				camp: action.payload,
				loading: {
					...state.loading,
					camp: action.loading,
				},
			};
		case "SET_FIRST_VISIT_REPORT":
			return {
				...state,
				firstVisit: action.payload,
				loading: {
					...state.loading,
					firstVisit: action.loading,
				},
			};
		case "LOADING_NEW_REPORT":
			return {
				...state,
				loading: {
					...state.loading,
					new: action.loading,
				},
			};
		case "LOADING_ONGOING_REPORT":
			return {
				...state,
				loading: {
					...state.loading,
					ongoing: action.loading,
				},
			};
		case "LOADING_FIRST_VISIT_REPORT":
			return {
				...state,
				loading: {
					...state.loading,
					firstVisit: action.loading,
				},
			};
		case "LOADING_CAMP_REPORT":
			return {
				...state,
				loading: {
					...state.loading,
					camp: action.loading,
				},
			};
		default:
			return state;
	}
};

export default reportReducer;
