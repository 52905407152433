import axios from "../../config/axiosConfig";

export default {
  get: () => axios.get("/contacts/get"),
  getHistory: (id_contact) => axios.get(`/history_contact/get?id=${id_contact}`),
  createContact: (lead) => axios.post(`/contact/create`, lead),
  updateContact: (contact) => axios.post(`/contact/update`, contact),
  addPost: (post) => axios.post(`/comment/add`, post),
  search: (value) => axios.get(`/contact/search?q=${value}`),
  editPost: (post) => axios.post(`/comment/edit`, post),
};
