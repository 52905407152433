/* eslint-disable arrow-body-style */
/* eslint-disable import/no-cycle */
import { contactsApi } from "../../utils/api";
import { log } from "../../utils/helpers/tracker";
import notification from "../../utils/notification/notification";
import { updLeadByUpdContact } from "./leadsActions";

export const getContacts = (data) => ({
  type: "GET_CONTACTS",
  payload: data,
  loading: false,
  loadingHistory: false,
});

export const addContact = (data) => ({
  type: "ADD_CONTACT",
  payload: data,
  loading: false,
  loadingHistory: false,
});

export const updContact = (data) => ({
  type: "UPD_CONTACT",
  payload: data,
  loading: false,
  loadingHistory: false,
});

export const updContactFromCreateContract = (data) => ({
  type: "UPD_CONTACT_FROM_CREATE_CONTRACT",
  payload: data,
  loading: false,
  loadingHistory: false,
});

export const getContactHistory = (data) => ({
  type: "GET_CONTACT_HISTORY",
  payload: data,
  loadingHistory: false,
});

export const setDataForContactDialog = (data) => ({
  type: "SET_DATA_FOR_CONTACT_DIALOG",
  payload: data,
  loadingHistory: false,
});

export const loadingContacts = () => ({
  type: "LOADING_CONTACTS",
  loading: true,
});

export const loadingHistory = () => ({
  type: "LOADING_HISTORY",
  loadingHistory: true,
});

export const fetchContacts = () => (dispatch) => {
  dispatch(loadingContacts());
  return contactsApi.get().then(({ data }) => dispatch(getContacts(data)));
};

export const fetchContactsFake = () => (dispatch) =>
  contactsApi.get().then(({ data }) => {
    console.log("fake");
    return dispatch(getContacts(data));
  });

export const addNewContact = (contact) => (dispatch) => {
  dispatch(loadingContacts());
  dispatch(addContact(contact));
  notification.success("Новый контакт добавлен");
};

export const updateContact = (contact) => (dispatch) => contactsApi
    .updateContact(contact)
    .then(({ data }) => {
      dispatch(updContact(data));
      notification.success("Данные контакта обновлены");
      dispatch(updLeadByUpdContact(data));
      notification.success("Данные Лидов обновлены");
    })
    .catch((error) => {
      log("Contact update", { error, contact });
      notification.error("Ошибка обновления контакта");
    });

export const fetchContactHistory = (id_contact) => (dispatch) => {
  dispatch(loadingHistory());
  return contactsApi.getHistory(id_contact).then(({ data }) => {
    dispatch(getContactHistory(data));
  });
};

export const addNewPost = (post) => (dispatch) => {
  contactsApi
    .addPost(post)
    .then(({ data }) => {
      if (data === "ok") {
        dispatch(fetchContactHistory(post.id_contact));
        notification.success("Комментарий добавлен");
      }
    })
    .catch((error) => {
      log("Add comment to contact", { error, post });
      notification.error("Ошибка добавления комментария");
    });
};

export const editPost = (post) => (dispatch) => {
  return contactsApi
    .editPost(post)
    .then(({ data }) => {
      notification.success("Комментарий изменен");
      return data;  // Return the data to be used in the .then() of the caller
    })
    .catch((error) => {
      log("Add comment to contact", { error, post });
      notification.error("Ошибка при редактировании комментария");
      throw error;  // Optionally rethrow the error if you want to handle it further up
    });
};
export const setDataForDialog = (data) => (dispatch) => {
  dispatch(setDataForContactDialog(data));
  // notification.success("Комментарий добавлен");
};
