import { debtApi } from '../../utils/api';

export const getDebt = (data) => ({
	type: 'GET_DEBTS',
	payload: data,
	loading: false,
});
export const loadingDebt = () => ({
	type: 'LOADING_DEBT',
	loading: true,
});

export const fetchDebt = () => (dispatch) => {
	dispatch(loadingDebt());
	debtApi.get().then(({ data }) => dispatch(getDebt(data.debts)));
};
