import React, { memo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import isLoggedIn from "../utils/helpers/isLoggedIn";

function Auth() {
  const location = useLocation();


  return isLoggedIn() ? (
    <Redirect to="/app" />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
}

export default memo(Auth);
