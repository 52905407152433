import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  fetchTeachersPlan,
  postAppointments,
  postSubstitution,
  removeAppointments,
  removeSubstitute,
  updAppointments,
  updSubstitution,
} from "../../redux/actions/appointmentsActions";
import "../../scss/index.scss";
import AppointmentsView from "../../components/AppointmentsView/AppointmentsView";
import { setPageTitle } from "../../redux/actions/globalActions";
import { getUserLocation } from "../../utils/helpers/getDefaultUser";

const mapStateToProps = (state) => ({
  loading: state.appointmentsAndSubstitute.loading,
  appointments: state.appointmentsAndSubstitute.appointments,
  substitute: state.appointmentsAndSubstitute.substitute,

  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: true,
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getTeachersPlan: (params) => {
    dispatch(fetchTeachersPlan(params));
  },
  addAppointment: (data) => dispatch(postAppointments(data)),
  updateAppointment: (data) => {
    dispatch(updAppointments(data));
  },
  addSubstitution: (data) => {
    dispatch(postSubstitution(data));
  },
  updateSubstitution: (data) => {
    dispatch(updSubstitution(data));
  },
  removeSub: (id) => {
    dispatch(removeSubstitute(id));
  },
  removeAssigned: (id) => {
    dispatch(removeAppointments(id));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function AppointmentsPage({ ...props }) {
  const {
    loading,
    getTeachersPlan,
    title,
    appointments,
    setTitle,
  } = props;
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const [locations, setLocations] = useState(null);

  useEffect(() => {
    const userLocations = getUserLocation();
    if (userLocations && userLocations.length > 0) {
      setLocations(userLocations);
    }
  }, []);

  useEffect(() => {
    if (locations && locations.length > 0) {
      getTeachersPlan(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>

        {!appointments || !locations ? (
          <Backdrop
            open={!appGlobalState.loading || loading}
            sx={backdropStyles}
          >
            <CircularProgress />{" "}
          </Backdrop>
        ) : (
          <AppointmentsView label="Назначения" locations={locations} {...props}/>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsPage);
