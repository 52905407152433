import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { getNameFromId } from "./entityNameById";

// get value for columns with singleselect component
export const selectGetterFunc = (params) => {
  if (params.value) {
    return params.value.id || params.value;
  }
  return "";
};
// format value for columns with singleselect component
// finding entity name by id
export const selectFormatterFunc = (params, entities) => {
  const { field } = params;
  if (params.value) {
    if(!getNameFromId(params.value, entities[field])) {
      console.log('ошибка:', params);
      return 'ошибка';
    }
    const { label } = getNameFromId(params.value, entities[field]);
    return label;
  }
  return "";
};
// return formatted date on set
export const dateSetterFunc = (params, fieldName) => {
  if (params.value) {
    const formattedDate = format(new Date(params.value), "yyyy-MM-dd");
    return { ...params.row, [fieldName]: formattedDate };
  }
  return "";
};
// format date on get
export const dateGetterFunc = (params) => {
  if (params.value) {
    return format(new Date(params.value), "yyyy-MM-dd");
  }
  return format(new Date(), "yyyy-MM-dd");
};
// format date for user only
export const dateFormatterFunc = (params) => {
  if (params.value) {
    return format(new Date(params.value), "dd.MM.yyyy", { locale: ru });
  }
  return format(new Date(), "yyyy-MM-dd");
};

export const dateTimeSetterFunc = (params) => {
  if (params.value) {
    return format(new Date(params.value), "yyyy-MM-dd'T'HH:mm");
  }
  return format(new Date(), "yyyy-MM-dd HH:mm");
};
// format date for user only
export const dateTimeFormatterFunc = (params) => {
  if (params.value) {
    return format(new Date(params.value), "dd.MM.yyyy HH:mm");
  }
  return format(new Date(), "yyyy-MM-dd HH:mm");
};
