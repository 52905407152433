import React, { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { setPageTitle } from "../../redux/actions/globalActions";
import { attendanceApi } from "../../utils/api";
import AttendanceReports from "../../components/AttendanceReports/AttendanceReports";
import AdvicePopup from "../../components/AdvicePopup/AdvicePopup";

const AttendanceReportsPage = ({ globalState, ...props }) => {
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const initialInputsState = {
    groups: "",
    user_id: "",
    subjects: "",
  };

  const [initFilters, setInitFilters] = useState(null);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    setLoading(true);
    attendanceApi.getReport(initialInputsState).then((res) => {
      console.log(res);
      setLoading(false);
      res.data.filters[0].groups;
      setInitFilters(res.data);
    });
  }, []);

  return (
    <>
      <AdvicePopup>
        <p> Посещаемость </p>
      </AdvicePopup>
      <div className="content-component__wrapper">
        {loading || globalState.loading ? (
          <Backdrop open={!globalState.loading} sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <AttendanceReports
            initFilters={initFilters}
            loading={loading}
            {...props}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    modify: true,
    view: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceReportsPage);
