/* eslint-disable array-callback-return */
import React, { useEffect } from "react";

import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./Journal.css";
import { v4 as uuidv4 } from "uuid";
import { Box, Divider } from "@mui/material";
import DatePicker from "../../utils/helpers/datePicker";
import { daySwitcherFromZero } from "../../utils/helpers/daySwitchers";
import { lessonsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

function Journal(props) {
  const { getJournal, journal } = props;
  const [calendarView, setCalendarView] = React.useState(false);
  const [children, setChildren] = React.useState("");

  const { entities } = props;
  const subjects = entities.subject;

  const names = journal.map((obj) => Object.keys(obj).map((key) => key));

  const getDate = (value) => {
    getJournal({
      date: value.format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (names.length > 0) {
      setChildren(names[0]);
    }
  }, [journal]);

  const downloadFileById = (id) => {
    lessonsApi
      .download(id)
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        // create "a" HTML element with href to file & click
        link.href = href;
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition?.split(";")[1].split("=")[1];
        link.setAttribute("download", `${filename}` || `${id}`);
        document.body.appendChild(link);
        // clean up "a" element & remove ObjectURL
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log("error", error);
        notification.error("при скачивании файла произошла ошибка");
      });
  };

  return journal.length > 0 ? (
    <>
      <Box sx={{ padding: "0 30px 20px 30px" }}>
        <Stack
          spacing={{ xs: 4, sm: 8 }}
          direction={{ xs: "column", sm: "row" }}
        >
          <Button
            size="small"
            className="open_close_btn"
            variant="contained"
            onClick={() => {
              setCalendarView(!calendarView);
            }}
          >
            {calendarView ? "Закрыть календарь " : "Открыть календарь"}
          </Button>
          {/* <Button
            className="open_close_btn"
            disabled={!date || !calendarView}
            variant="contained"
            onClick={() => {
              getPersonalJournal();
            }}
          >
            Отправить
          </Button> */}
        </Stack>

        {calendarView ? <DatePicker pushValueToJournal={getDate} /> : null}
      </Box>

      <FormControl sx={{ padding: "0 30px 20px 30px" }}>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          name="demo-radio-buttons-group"
          defaultValue={names.length ? names[0]?.join() : null}
        >
          {" "}
          {names
            ? names.map((name) => (
                <FormControlLabel
                  key={name}
                  value={`${name}`}
                  control={<Radio />}
                  label={`${name}`}
                  onClick={() => {
                    setChildren(name);
                  }}
                />
              ))
            : null}
        </RadioGroup>
      </FormControl>

      {children.length ? (
        <div className="personal_journal_wrapper">
          <table rules="col" className="personal_journal">
            {journal.map((item) =>
              // 1
              Object.keys(item).map(() => {
                // 2.
                // object:
                // [{date, day_week, lesson:[date,files,home_work,review,theme,title]}, {}, {} ]
                const object = item[children.join()];
                return (
                  <>
                    {/* <div key={uuidv4()}>{object ? children.join() : null}</div> */}
                    {object?.map((arrayByDates) => (
                      <tbody key={arrayByDates.date}>
                        <tr key={uuidv4()}>
                          <th
                            key={arrayByDates.date}
                            colSpan={4}
                            style={{
                              background: "#e9f5fb",
                              color: "#000",
                              borderCollapse: "separate",
                              borderSpacing: "5px",
                              borderTop: "solid 30px #fff",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            {arrayByDates.date},
                            {daySwitcherFromZero(arrayByDates.day_week)}
                          </th>
                        </tr>
                        <tr
                          style={{ fontStyle: "italic" }}
                          className="desktop_journal"
                          key={uuidv4()}
                        >
                          <th key="subject" style={{ width: "15%" }}>
                            Предмет
                          </th>
                          <th key="topic" style={{ width: "30%" }}>
                            Тема
                          </th>
                          <th key="HW" style={{ width: "30%" }}>
                            Домашнее задание
                          </th>
                          <th key="review" style={{ width: "25%" }}>
                            Заметки
                          </th>
                        </tr>
                        {arrayByDates.lesson.map((lesson) => (
                          <React.Fragment key={lesson.id || uuidv4()}>
                            <tr className="mobile_journal">
                              <th colSpan={2}>
                                {lesson.title
                                  ? findLabelByID(lesson.title, subjects)
                                  : "--"}
                              </th>
                            </tr>
                            <tr className="mobile_journal">
                              <th style={{ background: "#eee", width: "15%" }}>
                                Тема:{" "}
                              </th>
                              <td>{lesson.theme ? lesson.theme : "--"}</td>
                            </tr>
                            <tr className="mobile_journal">
                              <th style={{ background: "#eee", width: "15%" }}>
                                Задание:{" "}
                              </th>
                              <td>
                                {lesson.home_work ? lesson.home_work : "--"}
                                {lesson.files && (
                                  <div>
                                    <Divider />
                                    {lesson.files.map((id, index) => (
                                      <div className="attachments__box">
                                        файл {index + 1}
                                        <span
                                          className="download_attachment"
                                          onClick={() => downloadFileById(id)}
                                        >
                                          Скачать файл
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </td>
                            </tr>
                            {lesson.review !== "" && (
                              <tr className="mobile_journal">
                                <th
                                  style={{ background: "#eee", width: "15%" }}
                                >
                                  Заметки:{" "}
                                </th>
                                <td>{lesson.review ? lesson.review : null}</td>
                              </tr>
                            )}

                            <tr className="desktop_journal">
                              <td>
                                {lesson.title
                                  ? findLabelByID(lesson.title, subjects)
                                  : "--"}
                              </td>
                              <td>{lesson.theme ? lesson.theme : "--"}</td>
                              <td>
                                {lesson.home_work ? lesson.home_work : "--"}

                                {lesson.files && (
                                  <div>
                                    <Divider />
                                    {lesson.files.map((id, index) => (
                                      <div className="attachments__box">
                                        файл {index + 1}
                                        <span
                                          className="download_attachment"
                                          onClick={() => downloadFileById(id)}
                                        >
                                          Скачать файл
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </td>
                              <td>{lesson.review ? lesson.review : null}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                        {/* <tr colSpan={3}>
                          <td style={{ borderRight: "none" }}> {null} </td>
                        </tr> */}
                        <div
                          style={{
                            borderRadius: "1px",
                            width: "100%",
                            height: "3px",
                          }}
                        >
                          {" "}
                        </div>
                      </tbody>
                    ))}
                  </>
                );
              })
            )}
          </table>
        </div>
      ) : null}
    </>
  ) : (
    <div
      className="error"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
        marginBottom: "50px",
      }}
    >
      {" "}
      Нет данных для отображения.{" "}
    </div>
  );
}

export default Journal;
