import { Backdrop, CircularProgress, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import { React, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import Journal from "../../components/Journal/Journal";
import {
  fetchJournal,
  fetchSupportHistory,
  getError,
} from "../../redux/actions/journalActions";
import "../../scss/index.scss";
import { journalApi } from "../../utils/api";
import Balance from "../../components/Journal/Balance";
import { setPageTitle } from "../../redux/actions/globalActions";
import Support from "../../components/Journal/Support";

// eslint-disable-next-line react-hooks/rules-of-hooks

function DairyContainer({ ...props }) {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const userData = useSelector(({ user }) => user);
  const error = useSelector(({ journal }) => journal.error);

  const {
    loadingJournal,
    loadingHistory,
    getJournal,
    title,
    setTitle,
    getSupportHistory,
    supportHistory,
    entities,
  } = props;

  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(false);
  // const handleShowBalance = () => {
  //   console.log("showbala", showBalance);
  //   setShowBalance(!showBalance);
  // };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    getJournal({
      date: today,
    });
  }, []);
  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    if (userData.permissions.includes("personal_balance.view")) {
      journalApi.getBalance().then((data) => setBalance(data));
    }
  }, []);

  useEffect(() => {
    getSupportHistory();
  }, []);

  const useStyles = makeStyles((theme) => ({
    tab: {
      textDecoration: "none",
      color: " rgba(0, 0, 0, 0.6)",
      opacity: 0.6,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    activeTab: {
      textDecoration: "underline!important",
      color: "#2999DD!important",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className="content-component__wrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className="table__title">{title}</h4>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="transparent"
          >
            <Tab
              size="small"
              label="дневник"
              value={0}
              onClick={() => {
                setShowBalance(false);
              }}
              className={`${classes.tab} ${
                activeTab === 0 ? classes.activeTab : ""
              }`}
            />
            /
            <Tab
              label="баланс"
              size="small"
              value={1}
              onClick={() => {
                setShowBalance(true);
              }}
              className={`${classes.tab} ${
                activeTab === 1 ? classes.activeTab : ""
              }`}
            />
            <Tab
              label="техподдержка"
              size="small"
              value={2}
              className={`${classes.tab} ${
                activeTab === 2 ? classes.activeTab : ""
              }`}
            />
          </Tabs>
        </div>
        {error && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            {" "}
            {error.includes("does`t have a children") ? (
              <>У вас нет детей</>
            ) : (
              <>Нет данных</>
            )}{" "}
          </div>
        )}
        {loadingJournal || loadingHistory || appGlobalState.loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <>
            {activeTab === 0 && (
              <Journal {...props} getJournal={getJournal} balance={balance} />
            )}
            {activeTab === 1 && <Balance balance={balance} />}
            {activeTab === 2 && (
              <Support supportHistory={supportHistory} entities={entities} />
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingJournal: state.journal.loadingJournal,
  loadingHistory: state.journal.loadingHistory,
  journal: state.journal.payload,
  entities: state.entitiesTypes,
  supportHistory: state.journal.supportHistory,
});

const mapDispatchToProps = (dispatch) => ({
  getJournal: (data) => {
    dispatch(fetchJournal(data)).catch((e) => {
      dispatch(getError(e));
    });
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
  getSupportHistory: () => {
    dispatch(fetchSupportHistory());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(DairyContainer);
