import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React from "react";

const CancelDialog = ({ open, handleClose, disableUser }) => {

	const [openDialog, setOpenDialog] = React.useState(false);

	React.useEffect(() => {
		setOpenDialog(open);
	  }, [open]);

	const onCloseHandler = () => {
		setOpenDialog(false);
		handleClose();
	};

	const confirmHandler = () => {
		disableUser();
		setOpenDialog(false);
	};

	return (
		<div>
			<Dialog open={openDialog} onClose={onCloseHandler}>
				<DialogTitle>Деактивировать пользователя</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Вы действительно хотите деактивировать пользователя?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCloseHandler}>Назад</Button>
					<Button onClick={confirmHandler} color="error">
						Деактивировать
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CancelDialog;
