const initialState = {
  info: JSON.parse(localStorage.getItem("user")) || {},
  permissions: JSON.parse(localStorage.getItem("permissions")) || [],
  settings: JSON.parse(localStorage.getItem("settings")) || {},
};

// Function to remove localStorage items with a specific prefix
const removeItemsWithPrefix = (prefix) => {
  const keysToRemove = Object.keys(localStorage).filter((key) =>
    key.startsWith(prefix)
  );
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
};
const prefixToRemove = "dataGridFilters_";

const loginReducer = (state = initialState, action) => {
  const json = JSON.parse(localStorage.getItem("settings"));
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions)
      );
      localStorage.setItem("access", JSON.stringify(action.payload.access));
      return {
        ...state,
        info: action.payload.user,
        permissions: [...action.payload.permissions],
      };
    case "LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      removeItemsWithPrefix("dataGridFilters_");
      return {
        ...state,
        info: {},
        permissions: [],
      };
    case "SAVE_SETTINGS":
      localStorage.setItem(
        "settings",
        JSON.stringify({
          filter: action.payload,
          sort: json?.sort,
          sort_by_due: json?.sort_by_due,
        })
      );
      return {
        ...state,
        settings: {
          filter: action.payload,
          sort: state.settings.sort,
          sort_by_due: state.settings.sort_by_due,
        },
      };
    case "SAVE_SETTINGS_SORT":
      localStorage.setItem(
        "settings",
        JSON.stringify({
          filter: json?.filter,
          sort: action.payload,
          sort_by_due: json?.sort_by_due,
        })
      );
      return {
        ...state,
        settings: {
          filter: state.settings.filter,
          sort: action.payload,
          sort_by_due: state.settings.sort_by_due,
        },
      };
    case "SAVE_SETTINGS_SORT_BY_DUE":
      localStorage.setItem(
        "settings",
        JSON.stringify({
          filter: json?.filter,
          sort: json?.sort,
          sort_by_due: action.payload,
        })
      );
      return {
        ...state,
        settings: {
          filter: state.settings.filter,
          sort: state.settings.sort,
          sort_by_due: action.payload,
        },
      };
    default:
      return state;
  }
};
export default loginReducer;
