/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import "./LeadsKanban.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { set } from "date-fns";
import notification from "../../utils/notification/notification";
import LeadCard from "../LeadCard/LeadCard";
import { sortByField } from "../../utils/helpers/sorting";
import ParentForLeadsAndContact from "../ModalsForSalesWithAgreementsAndLeads/ParentForLeadsAndContact";
import LoadingFilePopup from "./LoadingFilePopup";
import { saveSettings } from "../../redux/actions/loginActions";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";
import { log } from "../../utils/helpers/tracker";
import { getDefaultUser } from "../../utils/helpers/getDefaultUser";

const LEADS_PER_PAGE = 15;

const PageSelector = ({ totalPages, currentPage, onPageChange }) => {
  const handleSelectChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    onPageChange(selectedPage);
  };

  return (
    <select
      value={currentPage}
      onChange={handleSelectChange}
      className="leads__page_selector"
    >
      {Array.from({ length: totalPages }, (_, index) => (
        <option key={index + 1} value={index + 1}>
          {index + 1}
        </option>
      ))}
    </select>
  );
};

function LeadsKanban({ loadingLeads, ...props }) {
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const dispatch = useDispatch();
  const allleads = props.leads.payload;
  const error = useSelector(({ leads }) => leads.error);
  const settings = useSelector(({ user }) => user.settings);

  const [leads, setLeads] = useState([
    {
      id_lead: "",
      status: "",
      name: "",
      date_created: "",
      phone: "",
      manager: "",
      utm_source: "",
      comment: [],
    },
  ]);

  const [updLead, setUpdLead] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const users = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const activeUsers = useSelector(
    ({ entitiesTypes }) => entitiesTypes.active_users
  );
  users?.sort(sortByField("label"));
  const newUsers = [...users];
  const [inputLoader, setInputLoader] = React.useState(false);

  const leadsCounter = (leadsArray, status) => {
    if (!leadsArray) {
      return "0";
    } else {
      return leadsArray?.filter((lead) => lead.status === status).length;
    }
  };

  const leadsInWorkToday = () => {
    const today = new Date().toISOString().split("T")[0];
    return allleads.filter(
      (lead) =>
        lead.status === "in_work" &&
        new Date(lead.date_due).toISOString().split("T")[0] === today &&
        lead.manager === getDefaultUser().id
    ).length;
  };

  const leadsCounterForUser = () => {
    if (!allleads) {
      return "0";
    } else {
      return allleads?.filter(
        (lead) =>
          lead.status === "in_work" && lead.manager === getDefaultUser().id
      ).length;
    }
  };

  const [input, setInput] = React.useState({
    value: settings?.filter || "0",
  });
  const [openPopup, setOpenPopup] = React.useState(false);

  const [LeadModalOpen, setLeadModalOpen] = React.useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleLeadOpen = () => {
    setLeadModalOpen(true);
  };
  const handleLeadClose = () => {
    setLeadModalOpen(false);
  };

  const handleDragStart = (e, lead) => {
    e.dataTransfer.setData("lead", JSON.stringify(lead));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDoubleClick = (lead) => {
    setUpdLead(lead);
    handleLeadOpen();
    log(
      "Lead Opened",
      { lead, manager: lead.manager, viewTime: new Date().toISOString() },
      { type: "Lead Interaction" }
    );
    console.log("Lead Opened:", lead);
  };

  const handleDrop = (e, status) => {
    // const lead = JSON.parse(e.dataTransfer.getData("lead")); !! do not delete
    notification.warning("Запрещенное действие", {
      position: "middle",
    });

    // ------ *    !!! not remove! code for moving cards!!!   -----

    // if (
    //   (lead.status === "in_work" && status === "new") ||
    //   lead.status === "rejected" ||
    //   lead.status === "disqualified"
    // ) {
    //   console.log("НЕЛЬЗЯ");
    //   notification.warning("Запрещенное действие", {
    //     position: "middle",
    //   });
    //   return;
    // }
    // setLeads((prevLead) =>
    //   prevLead.map((t) => {
    //     if (t.id_lead === lead.id_lead) {
    //       t.status = status;
    //     }
    //     return t;
    //   })
    // );
    // console.log("status", status);
    // dispatch(updateLeadStatus({ ...lead, status }));
  };

  // ------ *    !!! not remove! code for moving cards!!!  -----

  // const getLeadStatus = (date_due) => {
  //   const twoHoursInMillis = 2 * 60 * 60 * 1000; // Two hours in milliseconds
  //   const dueDate = new Date(date_due);
  //   const currentTime = new Date();
  //   // Calculate the time difference in milliseconds
  //   const timeDiff = dueDate - currentTime;
  //   if (timeDiff < 0) {
  //     return "expired-lead";
  //   }
  //   if (timeDiff < twoHoursInMillis) {
  //     return "approaching-deadline-lead";
  //   }
  //   return "new-lead";
  // };

  // sort leads by dates in ascending or descending order //
  const [sortedLeadsForOutput, setSortedLeadsForOutput] = React.useState([]);
  const [sortDirection2, setSortDirection2] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortLeadsByDate = (date1, date2, direction) => {
    if (direction === "asc") {
      return new Date(date1) - new Date(date2);
    } else {
      return new Date(date2) - new Date(date1);
    }
  };

  const handleSort = (selectedSortBy) => {
    let newSortDirection;
    if (selectedSortBy === sortBy) {
      newSortDirection = sortDirection2 === "asc" ? "desc" : "asc";
    } else {
      newSortDirection = "asc";
    }

    setSortBy(selectedSortBy);
    setSortDirection2(newSortDirection);

    localStorage.setItem("activeSortBy", selectedSortBy);
    localStorage.setItem("activeSortDirection", newSortDirection);
  };

  React.useEffect(() => {
    const activeSortDirection = localStorage.getItem("activeSortDirection");
    const activeSortBy = localStorage.getItem("activeSortBy");
    const currentSalesPage = localStorage.getItem("currentSalesPage");
    if (activeSortDirection && activeSortBy) {
      setSortDirection2(activeSortDirection);
      setSortBy(activeSortBy);
    }
    if (currentSalesPage) {
      setCurrentPage(Number(currentSalesPage));
    }
  }, []);

  React.useEffect(() => {
    const sortedLeads2 = [...leads].sort((lead1, lead2) => {
      if (sortBy === "date_created") {
        return sortLeadsByDate(
          lead1.date_created,
          lead2.date_created,
          sortDirection2
        );
      } else if (sortBy === "date_due") {
        return sortLeadsByDate(lead1.date_due, lead2.date_due, sortDirection2);
      }
      return 0;
    });
    setSortedLeadsForOutput(sortedLeads2);
  }, [leads, sortDirection2, sortBy]);

  React.useEffect(() => {
    // setInputLoader(true);
    const copyAllLeads = allleads.slice();
    let filterLeads;
    if (input.value === "" || input.value === "0") {
      filterLeads = copyAllLeads;
    } else {
      filterLeads = copyAllLeads.filter((item) => item.manager == input.value);
    }

    setLeads(filterLeads);
  }, [input, allleads]);

  const selectionForPagination = (leadsArray, status) =>
    leadsArray
      .filter((lead) => lead.status === status)
      .slice((currentPage - 1) * LEADS_PER_PAGE, currentPage * LEADS_PER_PAGE);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    localStorage.setItem("currentSalesPage", selectedPage);
  };

  React.useEffect(() => {
    const maxLeadsCount = Math.max(
      leadsCounter(sortedLeadsForOutput, "new"),
      leadsCounter(sortedLeadsForOutput, "in_work"),
      leadsCounter(sortedLeadsForOutput, "done"),
      leadsCounter(sortedLeadsForOutput, "rejected"),
      leadsCounter(sortedLeadsForOutput, "disqualified")
    );
    // Calculate the total number of pages based on the maximum number of leads
    const calculatedTotalPages = Math.ceil(maxLeadsCount / LEADS_PER_PAGE);
    setTotalPages(calculatedTotalPages);
  }, [sortedLeadsForOutput]);

  const navigatePage = (direction) => {
    let newPage;
    if (direction === "first") {
      newPage = 1;
    } else if (direction === "last") {
      newPage = totalPages;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    }
    handlePageChange(newPage);
  };

  return inputLoader ? (
    <Backdrop open sx={backdropStyles}>
      <CircularProgress thickness={5} />{" "}
    </Backdrop>
  ) : (
    <div className="kanban">
      <div className="kanban__summary">
        <div className="kanban__summary_today">
          Ваши Лиды в работе сегодня: <span> {leadsInWorkToday()}</span>
        </div>
        <div className="kanban__summary_total">
          Ваши Лиды в работе всего: <span>{leadsCounterForUser()}</span>
        </div>
      </div>

      <div className="kanban__leads_search_block">
        <div className="search_input_kanban_leads">
          <Select
            fullWidth
            id="select-leads-kanban"
            size="small"
            name="filter"
            value={input.value}
            onChange={(event) => {
              setInput((prevInpurState) => ({
                ...prevInpurState,
                value: event.target ? event.target.value : "",
              }));
              dispatch(saveSettings(event.target ? event.target.value : ""));
            }}
          >
            <MenuItem value="0">Лиды всех пользователей</MenuItem>
            {activeUsersSelection(newUsers, activeUsers)}
          </Select>
        </div>

        <div className="leads_sorting_btns">
          <div>
            <div className="leads_sorting_btn_name">сортировка:</div>
            <div className="leads_sorting_btn">
              <Button
                sx={{ lineHeight: "1", width: "120px", marginRight: "10px" }}
                onClick={() => handleSort("date_due")}
                variant="contained"
                size="small"
                color={sortBy === "date_due" ? "success" : "primary"}
              >
                Дедлайн{" "}
                {sortBy === "date_due"
                  ? sortDirection2 === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </Button>
              <Button
                sx={{ lineHeight: "1", width: "120px" }}
                onClick={() => handleSort("date_created")}
                variant="contained"
                size="small"
                color={sortBy === "date_created" ? "success" : "primary"}
              >
                Создан{" "}
                {sortBy === "date_created"
                  ? sortDirection2 === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </Button>
            </div>
          </div>

          <div style={{ marginRight: "10px" }}>
            {" "}
            <Button
              sx={{ lineHeight: "1", width: "120px" }}
              variant="contained"
              onClick={handleOpenPopup}
              size="small"
            >
              Загрузить файл
            </Button>{" "}
          </div>
          <div className="leads_pagination">
            <Button
              onClick={() => navigatePage("first")}
              disabled={currentPage === 1}
              size="small"
              variant="contained"
            >
              Первая
            </Button>
            <Button
              onClick={() => navigatePage("prev")}
              disabled={currentPage === 1}
              size="small"
              variant="contained"
            >
              Пред
            </Button>
            <span>
              {" "}
              <PageSelector
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </span>
            <Button
              onClick={() => navigatePage("next")}
              disabled={currentPage * LEADS_PER_PAGE >= leads.length}
              size="small"
              variant="contained"
            >
              След
            </Button>
            <Button
              onClick={() => navigatePage("last")}
              disabled={currentPage * LEADS_PER_PAGE >= leads.length}
              size="small"
              variant="contained"
            >
              Последняя
            </Button>
          </div>
        </div>
      </div>
      {error && <h3>Обнаружена ошибка:{error.message}</h3>}
      <div className="board__leads">
        <div className="column">
          <h2>Новый({leadsCounter(sortedLeadsForOutput, "new")})</h2>
          <ul onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, "new")}>
            {selectionForPagination(sortedLeadsForOutput, "new")
              // .filter((lead) => lead.status === "new")
              .map((lead) => (
                <li
                  key={lead.id_lead}
                  draggable
                  onDragStart={(e) => handleDragStart(e, lead)}
                  onDoubleClick={() => {
                    handleDoubleClick(lead);
                  }}
                >
                  <LeadCard lead={lead} />
                </li>
              ))}
          </ul>
        </div>
        <div className="column">
          <h2>В работе ({leadsCounter(sortedLeadsForOutput, "in_work")})</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "in_work")}
          >
            {leads &&
              selectionForPagination(sortedLeadsForOutput, "in_work")
                // .filter((lead) => lead.status === "in_work")
                .map((lead) => (
                  <li
                    key={lead.id_lead}
                    draggable
                    onDragStart={(e) => handleDragStart(e, lead)}
                    onDoubleClick={() => {
                      handleDoubleClick(lead);
                    }}
                  >
                    <LeadCard lead={lead} />
                  </li>
                ))}
          </ul>
        </div>
        <div className="column">
          <h2>Обработан</h2>
          <ul onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, "done")}>
            {selectionForPagination(sortedLeadsForOutput, "done")
              // .filter((lead) => lead.status === "done")
              .map((lead) => (
                <li
                  key={lead.id_lead}
                  draggable
                  onDragStart={(e) => handleDragStart(e, lead)}
                  onDoubleClick={() => {
                    handleDoubleClick(lead);
                  }}
                >
                  <LeadCard lead={lead} />
                </li>
              ))}
          </ul>
        </div>
        <div className="column">
          <h2>Обработан.отказ</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "rejected")}
          >
            {selectionForPagination(sortedLeadsForOutput, "rejected")
              // .filter((lead) => lead.status === "rejected")
              .map((lead) => (
                <li
                  key={lead.id_lead}
                  draggable
                  onDragStart={(e) => handleDragStart(e, lead)}
                  onDoubleClick={() => {
                    handleDoubleClick(lead);
                  }}
                >
                  <LeadCard lead={lead} />
                </li>
              ))}
          </ul>
        </div>
        <div className="column">
          <h2>Некачественные</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "disqualified")}
          >
            {selectionForPagination(sortedLeadsForOutput, "disqualified")
              // .filter((lead) => lead.status === "disqualified")
              .map((lead) => (
                <li
                  key={lead.id_lead}
                  draggable
                  onDragStart={(e) => handleDragStart(e, lead)}
                  onDoubleClick={() => {
                    handleDoubleClick(lead);
                  }}
                >
                  <LeadCard lead={lead} />
                </li>
              ))}
          </ul>
        </div>
      </div>

      <ParentForLeadsAndContact
        open={LeadModalOpen}
        onClose={handleLeadClose}
        lead={updLead}
        type="lead"
      />
      {/* <ContactDialog
        open={ContactModalOpen}
        onClose={handleContactClose}
        contactFromContactDialog={contactFromSearch}
        contactHistory={contactHistory}
      /> */}
      <LoadingFilePopup open={openPopup} handleClose={handleClosePopup} />
    </div>
  );
}

export default LeadsKanban;
