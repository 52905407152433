import axios from "../../config/axiosConfig";
import { loading, loaded } from "./globalActions";

export const setUniqueTypes = (data) => ({
  type: "SET_UNIQUE_TYPES",
  payload: data,
});

export const addEntity = (entityType, entity) => ({
  type: "ADD_ENTITY",
  payload: { entityType, entity },
});

export const getUniqueEntities = () => (dispatch) => {
  dispatch(loading());
  axios.get("/entities-types/get").then((response) => {
    dispatch(setUniqueTypes(response.data));
    dispatch(loaded());
  });
};

export const getUniqueEntitiesHidden = () => (dispatch) => {
  axios.get("/entities-types/get").then((response) => {
    dispatch(setUniqueTypes(response.data));
  });
};


