/* eslint-disable consistent-return */
const initialState = {
  payload: [],
  error: false,
  loading: false,
};

const checkDuplicates = (s, a) =>
  s.some((item) => item.id_lead === a.id_lead) ? [...s] : [...s, a];

const updLeadsWhenUpdContact = (oldState, updatedLeads) => {
  const mergedArr = oldState.reduce((acc, cur) => {
    const found = updatedLeads.find((obj) => obj.id_lead === cur.id_lead);
    if (found) {
      acc.push(found);
    } else {
      acc.push(cur);
    }
    return acc;
  }, []);
  return mergedArr;
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEADS":
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case "SET_PERSONAL_LEADS":
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case "ADD_LEAD":
      return {
        ...state,
        payload: checkDuplicates(state.payload, action.payload),
        loading: false,
      };
    case "UPD_LEAD":
      return {
        ...state,
        payload: state.payload.map((lead) =>
          lead.id_lead === action.payload.id_lead ? action.payload : lead
        ),
        loading: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case "UPD_LEAD_BY_UPD_CONTACT":
      return {
        ...state,
        payload: updLeadsWhenUpdContact(state.payload, action.payload.leads),
      };

    case "UPD_MANAGER":
      return {
        ...state,
        payload: state.payload.map((lead) =>
          lead.id_lead === action.payload.id_lead ? action.payload : lead
        ),
        loading: false,
      };

    case "LOADING_LEADS":
      return {
        ...state,
        loading: action.loading,
      };
    case "UPD_LEAD_STATUS":
      return {
        ...state,
        // eslint-disable-next-line array-callback-return
        // 	payload: state.payload.map((item) =>  {
        //     if(item.id_lead === action.payload.id_lead)
        //     // eslint-disable-next-line no-return-assign
        //     //  item.status = action.payload.status;
        //   }
        // ),
        loading: false,
      };
    default:
      return state;
  }
};

export default contactsReducer;
