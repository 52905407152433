import React from "react";
import "../scss/SidebarButton.scss";
import * as Icons from "react-icons/bs";

function SidebarButton({ iconName, children }) {
  const icon = React.createElement(Icons[iconName]);
  return (
    <div className="sidebar-button">
      <div>{icon}</div>
      {children}
    </div>
  );
}

export default SidebarButton;
