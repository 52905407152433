 const colorList = [
  "#DD7E6B",
  "#EA9999",
  "#F9CB9C",
  "#FFE599",
  "#B6D7A8",
  "#A2C4C9",
  "#A4C2F4",
  "#9FC5E8",
  "#B4A7D6",
  "#D5A6BD",
  "#CC4125",
  "#E06666",
  "#F6B26B",
  "#FFD966",
  "#93C47D",
  "#76A5AF",
  "#6D9EEB",
  "#6FA8DC",
  "#8E7CC3",
  "#C27BA0",
  "#E6B8AF",
  "#F4CCCC",
  "#FCE5CD",
  "#FFF2CC",
  "#D9EAD3",
  "#D0E0E3",
  "#C9DAF8",
  "#CFE2F3",
  "#D9D2E9",
  "#EAD1DC",
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#DAF7A6",
  "#FFC300",
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#28B463",
  "#1F618D",
  "#BA4A00",
  "#AF7AC5",
  "#F1948A",
  "#7DCEA0",
  "#D5DBDB",
  "#E67E22",
  "#5D6D7E",
  "#D98880",
  "#16A085",
  "#F39C12",
  "#34495E",
  "#C0392B",
  "#8E44AD",
  "#2980B9",
  "#D4E6F1",
  "#F5B041",
  "#48C9B0",
  "#8E44AD",
  "#D68910",
  "#E59866",
  "#D5F5E3",
  "#EAECEE",
  "#A569BD",
  "#A04000",
  "#D35400",
  "#EB984E",
  "#52BE80",
  "#145A32",
  "#FAD7A0",
  "#A9DFBF",
  "#566573",
  "#5DADE2",
  "#2ECC71",
  "#CA6F1E",
  "#7FB3D5",
  "#F4D03F",
  "#52BE80",
  "#AF601A",
  "#3498DB",
  "#2E4053",
  "#9B59B6",
  "#1ABC9C",
  "#DFFF00",
  "#FFBF00",
  "#FF7F50",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
  "#CCCCFF",
];


export default colorList;