export const daySwitcher = (day) => {
  switch (day) {
    case 1:
      return "Понедельник";
    case 2:
      return "Вторник";
    case 3:
      return "Среда";
    case 4:
      return "Четверг";
    case 5:
      return "Пятница";
    case 6:
      return "Суббота";
    case 0:
      return "Воскресенье";
    default:
      return "нет такого дня";
  }
};

export const daySwitcherFromZero = (day) => {
    if(typeof(day) === 'string') {
       day = Number(day);
    }
  switch (day) {
    case 0:
      return "Понедельник";
    case 1:
      return "Вторник";
    case 2:
      return "Среда";
    case 3:
      return "Четверг";
    case 4:
      return "Пятница";
    case 5:
      return "Суббота";
    case 6:
      return "Воскресенье";
    default:
      return "нет такого дня";
  }
};
