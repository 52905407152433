import axios from "../../config/axiosConfig";

export default {
	getLeads: () => axios.get("/leads/get"),
	addLead: (lead) => axios.post("/lead/add", lead),
	updLeadStatus: (status) => axios.post("/status_lead/update", status),
	updLead: (lead) => axios.post("/lead/update", lead),
	updManager: (lead) => axios.post("/lead/manager_update", lead),
	uploadFile: (formData) => axios.post("/leads/upload", formData),
	checkDuplicate: (id) => axios.get(`check/duplicate?id=${id}`),
};
