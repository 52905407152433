/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
} from "@mui/material";
import { DialogTitle } from "@material-ui/core";
import { leadsApi } from "../../utils/api";

import "./LoadingFilePopup.scss";

const LoadingFilePopup = ({ open, handleClose }) => {
	const [fileName, setFileName] = useState({});
	const [loading, setLoading] = useState(false);

	const onCloseHandler = () => {
		handleClose();
	};

	const handleFileChange = (e) => {
		e.preventDefault;

		const file = e.target.files[0];

		setFileName(file);
	};

	const downloadFile = (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		formData.append("file", fileName);

		leadsApi
			.uploadFile(formData)
			.then((res) => {
				if (res.status === 200) {
					// alert(`Файл ${fileName.name} успешно загружен`);

					console.log(res);
					alert(
						`Статус: ${res.data.status},
Cообщение с сервера: ${res.data.message}`
					);
				}
				setLoading(false);
			})
			.catch((err) => {
				alert(err.response.data.message);
				console.log(err);
			});
	};

	return (
		<Dialog open={open} onClose={onCloseHandler} style={{ padding: "20px" }}>
			<DialogTitle>Загрузить файл 💖</DialogTitle>
			<form
				action="/success"
				method="post"
				encType="multipart/form-data"
				onSubmit={downloadFile}
				style={{
					padding: "20px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<label className="input-file">
					<input
						type="file"
						name="file"
						onChange={handleFileChange}
						className="input-file"
					/>

					<span className="input-file-btn">Выберите файл</span>
					{fileName && <span className="input-file-text">{fileName.name}</span>}
				</label>

				{/* <input type="submit" value="Upload" /> */}
				<DialogActions>
					{loading ? <CircularProgress color="inherit" size={20} /> : null}
					<Button type="submit" value="Upload">
						Загрузить
					</Button>
					<Button onClick={onCloseHandler}>Закрыть</Button>
				</DialogActions>
			</form>

			{/* <form
				action="/success"
				method="post"
				encType="multipart/form-data"
				onSubmit={downloadFile}
				style={{ padding: "20px" }}
			>
				<input
					type="file"
					name="file"
					onChange={handleFileChange}
					className="input-file"
				/>
				

			
				<DialogActions>
					{loading ? <CircularProgress color="inherit" size={20} /> : null}
					<Button type="submit" value="Upload">
						Загрузить
					</Button>
					<Button onClick={onCloseHandler}>Закрыть</Button>
				</DialogActions>
			</form> */}
		</Dialog>
	);
};

export default LoadingFilePopup;
