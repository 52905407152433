import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

function CancelReasonDialog({ open, handleClose, confirm }) {
	const reasons = useSelector((state) => state.entitiesTypes.reasons_cancelled);

	const [inputValue, setInputValue] = React.useState("");
	const [listCanceled, setList] = React.useState([]);

	const onChangeHandler = (event) => {
		setInputValue(event.target.value);
	};

	const onCloseHandler = () => {
		setInputValue("");
		handleClose();
	};

	const confirmHandler = () => {
		// to send to the server, we need an object like this: {id:..., message:...}
		const cancelData = listCanceled.find((elem) => elem.value === inputValue);
		confirm(cancelData);
		setInputValue("");
	};

	useEffect(() => {
		setList(reasons);
	}, [reasons]);

	return (
		<div>
			<Dialog open={open} onClose={onCloseHandler}>
				<DialogTitle>Отмена контракта</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Вы действительно хотите отменить контракт? Выберите из списка
						причину отмены.
					</DialogContentText>
					<TextField
						select
						value={inputValue}
						onChange={onChangeHandler}
						autoFocus
						margin="dense"
						id="name"
						label="Причина отмены"
						fullWidth
					>
						{listCanceled?.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</TextField>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCloseHandler}>Назад</Button>
					<Button
						onClick={confirmHandler}
						color="error"
						disabled={inputValue.length === 0}
					>
						Отменить контракт
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default CancelReasonDialog;
