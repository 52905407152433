import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "../../scss/index.scss";
import CRMTabs from "../../components/TabsComponent/CRMTabs";
import ContractTemplates from "../../components/ContractTemplates/ContractTemplates";
import { setPageTitle } from "../../redux/actions/globalActions";

const SettingsPage = ({...props }) => {
  const {
    setTitle,
    title,
  } = props;
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };


  useEffect(() => {
    setTitle(title);
  }, []);
  return (
    <>
      {appGlobalState.loading? (
        <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
          <CircularProgress />{" "}
        </Backdrop>
      ) : (
        <CRMTabs>
          <ContractTemplates value="1" label="Шаблоны" {...props} key="1" />
          <ContractTemplates value="2" label="Шаблоны" {...props} key="2" />
        </CRMTabs>
      )}
      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: false,
    remove: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
