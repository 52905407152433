const { stage } = window.clientConfig;

const socketURL = () => {
  switch (stage) {
    case "v1":
      return "wss://dev.stemlab.school";
    case "v2":
      return "wss://stemlab.school/ws";
    case "qa":
      return "wss://pp.stemlab.school/ws";
    case "docker":
      return "wss://docker.stemlab.school/ws";
    default:
      return "";
  }
};

export default socketURL;
