/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import "./TaskModal.scss";
import {
  ReusableTextField,
  RussianDatePicker,
} from "../ReusableInputs/ReusableInputs";
import { tasksApi } from "../../utils/api";
import {
  addNewComment,
  updateTask,
  updateTaskStatus,
} from "../../redux/actions/tasksActions";
import ConfirmationDialogForTask from "./ConfirmationDialogForTask";
import { getDefaultUser } from "../../utils/helpers/getDefaultUser";
import SimpleConfirmModal from "../ReusableSimpleConfirmatin/SimpleConfirmModal";

const TaskModalUpdate = ({ task, onClose, ...props }) => {
  console.log("TASK", task);

  const dispatch = useDispatch();
  const defaultUsr = getDefaultUser();
  const today = dayjs().format("YYYY-MM-DD 00:00:00");
  const dayAfterTwo = dayjs().add(2, "day").format("YYYY-MM-DD 00:00:00");

  const managers = useSelector(({ entitiesTypes }) => entitiesTypes?.user);
  const customers = useSelector(({ entitiesTypes }) => entitiesTypes?.customers);
  const allUsers = managers.concat(customers);
  const getComments = (t) => {
    const comments = [];
    if (!t.history) {
      return [];
    }
    // eslint-disable-next-line array-callback-return
    t.history.map((item) => {
      if (item.type === "comment") {
        comments.push(item);
      }
    });
    return comments;
  };

  const getStatusesActions = (t) => {
    const statuses = [];
    if (!t.history) {
      return [];
    }
    // eslint-disable-next-line array-callback-return
    t.history.map((item) => {
      if (item.type === "status") {
        statuses.push(item);
      }
    });
    return statuses;
  };

  const getDeadlinesActions = (t) => {
    const deadlines = [];
    if (!t.history) {
      return [];
    }
    // eslint-disable-next-line array-callback-return
    t.history.map((item) => {
      if (item.type === "deadline") {
        deadlines.push(item);
      }
    });
    return deadlines;
  };

  const getExecutorsActions = (t) => {
    const executors = [];
    if (!t.history) {
      return [];
    }
    // eslint-disable-next-line array-callback-return
    t.history.map((item) => {
      if (item.type === "executor") {
        executors.push(item);
      }
    });
    return executors;
  };

  const findRoleByUserId = (id, rolesArr) => {
    let role = "";
    Object.keys(rolesArr).map((key) => {
      if (rolesArr[key].includes(Number(id))) {
        role = key;
      }
    });
    return role;
  };
  const Id = task.id_task;
  const current_status = task.status;

  const findUsersByRole = (r, entities) => {
    const arr = [];
    entities.filter((item) =>
      r?.forEach((rol) => {
        if (item.value === rol.toString()) {
          arr.push(item);
        }
      })
    );
    return arr;
  };

  const initialTaskState = {
    title: task.title,
    status: task.status,
    description: task.description,
    new_comment: "",
    executor: task.executor,
    id_attachments: [],
    date_due: task.date_due,
  };
  const [taskValues, setTaskValues] = React.useState(initialTaskState);
  const [users, setUsers] = React.useState([]);
  const [status, setStatus] = React.useState(current_status);

  // simpleModal confirm when user try to change task status to "close"
  const [isStatusCheckModalOpen, setStatusCheckModalOpen] = useState(false);

  const openCheckModal = () => {
    setStatusCheckModalOpen(true);
  };
  const closeCheckModal = () => {
    setStatusCheckModalOpen(false);
  };
  const handleCheckModalConfirm = () => {
    console.log("Confirmed!");
    closeCheckModal();
  };

  const [role, setRole] = React.useState(task.role || "metodist");

  const handleModalClose = () => {
    setTaskValues(initialTaskState);
    setStatus(current_status);
    onClose(false);
  };

  const handleDateChange = (newValue) => {
    const formattedValue = newValue
      ? newValue.format("YYYY-MM-DD 00:00:00")
      : "";
    setTaskValues((prevState) => ({
      ...prevState,
      date_due: formattedValue,
    }));
    // console.log("taskValues", taskValues);
  };

  const [isExecutorChange, setIsExecutorChange] = React.useState(false);

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setTaskValues((prevState) => {
      if (prevState.executor !== value) {
        setIsExecutorChange(true);
        console.log("изменился");
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
    // console.log("taskValues", taskValues);
  };

  const handleRoleChange = (event, newRole) => {
    if (newRole) {
      setRole(newRole);
    }
    setTaskValues((prevState) => ({
      ...prevState,
      date_due: newRole === "metodist" ? dayAfterTwo : today,
    }));
  };

  const [new_comment, setComment] = React.useState("");

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const [expandComment, setExpandComment] = React.useState(false);
  const handleExpandComment = () => {
    setExpandComment(!expandComment);
  };

  // confirmation Dialog actions
  const [inputData, setInputData] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };
  const handleConfirmAction = (reasonValue) => {
    setInputData(reasonValue);
    dispatch(
      addNewComment({ id_task: Id, comment: `причина отказа:  ${reasonValue}` })
    );
    dispatch(updateTaskStatus({ id_task: Id, status: "denied_execution" }));
    setStatus("denied_execution");
    task.status = "denied_execution";
    console.log("Action confirmed!", reasonValue);
    setIsConfirmationOpen(false);
  };

  const onStatusChange = (event) => {
    if (event.target.value === "denied_execution") {
      handleOpenConfirmation();
      return;
    }
    if (event.target.value === "closed") {
      if (task.autor !== defaultUsr.id.toString()) {
        openCheckModal();
        return;
      }
    }
    dispatch(updateTaskStatus({ id_task: Id, status: event.target.value }));
    setStatus(event.target.value);
    task.status = event.target.value;
  };

  const handleCommentSubmit = () => {
    dispatch(addNewComment({ id_task: Id, comment: new_comment }));
    setComment("");
  };

  const handleCommentCancel = () => {
    setComment("");
    setExpandComment(false);
  };

  // simpleModal Notification when user send task
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const openNotificationModal = () => {
    setNotificationOpen(true);
  };
  const closeNotificationModal = () => {
    setNotificationOpen(false);
  };
  const hanleConfirm = () => {
    closeNotificationModal();
    handleModalClose();
  };

  const handleTaskUpdate = () => {
    const submit = {
      id_task: task.id_task,
      title: task.title,
      description: taskValues.description,
      type: role,
      executor: taskValues.executor,
      date_due: taskValues.date_due,
      new_comment: "",
      id_attachments: task.id_attachments,
      status: isExecutorChange ? "new" : status,
      history: task.history,
    };
    // props.updTask(submit);
    dispatch(updateTask(submit));
    openNotificationModal();
    console.log("submit", submit);
  };

  const handleTaskCancel = () => {
    handleModalClose();
  };

  React.useEffect(() => {
    tasksApi.roles().then((data) => setUsers(data.data));
    // console.log("users:", users);
    // console.log(
    //   "users[role]",
    //   users[role]?.map((user) => findLabelByID(user, managers))
    // );
  }, []);

  React.useEffect(() => {
    // console.log("findRoleByUserId", findRoleByUserId(task.executor, users));
    setRole(findRoleByUserId(task.executor, users));
  }, [users]);

  const tasksCreator = findLabelByID(task.autor, allUsers);
  const tasksExecutor = findLabelByID(task.executor, managers);
  return (
    <>
      <div className="popup__wrapper">
        <div className="popup-overlay" />
        <div className="task__popup update">
          <div className="popup__container">
            <CloseIcon
              style={{
                position: "absolute",
                right: "0%",
                top: "0%",
                cursor: "pointer",
              }}
              onClick={handleModalClose}
            />
            <div className="popup__left">
              <header
                className="popup__header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="popup__title">{task.title}</div>
                <Select
                  labelId="select-statuses"
                  label="Статус"
                  disabled={task.status === "closed"}
                  // variant="outlined"
                  value={status}
                  size="small"
                  onChange={onStatusChange}
                >
                  <MenuItem value="new">Новая</MenuItem>
                  <MenuItem value="in_work">В работе</MenuItem>
                  <MenuItem value="completed">Выполнена</MenuItem>
                  <MenuItem value="denied_execution">Отклонить</MenuItem>
                  <MenuItem value="closed">Закрыта</MenuItem>
                </Select>
              </header>

              <main className="popup__main">
                <div>
                  <ReusableTextField
                    label="Описание"
                    multiline
                    rows={4}
                    value={taskValues.description}
                    name="description"
                    onChange={handleInputsChange}
                  />

                  {/* <div>
                    <ReusableTextField
                      label="Описание"
                      multiline
                      rows={4}
                      value={taskValues.description}
                      name="description"
                      onChange={handleInputsChange}
                    />

                    <Typography component="label" edge="end">
                      добавить файл:
                      <input type="file" hidden onChange={handleFilesChange} />
                    </Typography>

                    <div style={{ marginTop: 16 }}>
                      {filePreviews.map((f, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={f.name + index}
                          style={{ marginBottom: 8 }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {filePreviews[index] ? (
                              <div>
                                <img
                                  src={filePreviews[index]}
                                  alt={filePreviews.name}
                                  style={{
                                    maxHeight: "100px",
                                    marginRight: 8,
                                    width: "auto",
                                  }}
                                />
                                <Chip
                                  label={fileInfo[index]}
                                  onDelete={() => removeFile(index)}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                  <div style={{ padding: "20px 0" }}>
                    Выберите исполнителя:
                    <ToggleButtonGroup
                      className="task__modal_executor_group"
                      size="small"
                      value={role}
                      exclusive
                      onChange={handleRoleChange}
                      aria-label="role"
                    >
                      <ToggleButton
                        value="metodist"
                        aria-label="metodist"
                        className="task__modal_role"
                      >
                        Методист
                      </ToggleButton>
                      <ToggleButton
                        value="administrator"
                        aria-label="administrator"
                      >
                        Администратор
                      </ToggleButton>
                      <ToggleButton value="teacher" aria-label="teacher">
                        Учитель
                      </ToggleButton>
                      <ToggleButton value="sales" aria-label="sales">
                        Продажник
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <ReusableTextField
                    select
                    label="Исполнитель"
                    value={taskValues.executor}
                    name="executor"
                    onChange={handleInputsChange}
                    options={findUsersByRole(users[role], managers)}
                  />
                </div>
                <RussianDatePicker
                  value={taskValues.date_due}
                  onChange={handleDateChange}
                  name="date_due"
                  inputFormat="YYYY-MM-DD"
                  label="Выберите дату"
                />
              </main>
              <div className="popup__subtitle">Комментарии</div>
              <div className="popup__descr">
                {getComments(task).map((item) => (
                  <li key={item.date}>
                    <span>
                      {dayjs(item.date).format("LL")}{" "}
                      {item.info.startsWith("причина отказа:") ? (
                        <b style={{ color: "#000" }}>{item.info}</b>
                      ) : (
                        <>{item.info}</>
                      )}{" "}
                      <b>{findLabelByID(item.creator, managers)}</b>{" "}
                    </span>
                  </li>
                ))}
              </div>
              <div className="popup__subtitle">Действия</div>
              <div className="popup__descr">
                {getStatusesActions(task).map((item) => (
                  <li key={item.date}>
                    {" "}
                    Пользователь <b>
                      {findLabelByID(item.creator, managers)}
                    </b>{" "}
                    перевел задачу из {item.info.old} в {item.info.new}.{" "}
                    {item.date}{" "}
                  </li>
                ))}
              </div>
              <div className="popup__descr">
                {getDeadlinesActions(task).map((item) => (
                  <li key={item.date}>
                    {" "}
                    Пользователь <b>
                      {findLabelByID(item.creator, managers)}
                    </b>{" "}
                    изменил дедлайн {dayjs(item.info.old).format("YYYY-MM-DD")}{" "}
                    &#10144; {dayjs(item.info.new).format("YYYY-MM-DD")}
                  </li>
                ))}
              </div>
              <div className="popup__descr">
                {getExecutorsActions(task).map((item) => (
                  <li key={item.date}>
                    {" "}
                    Пользователь <b>
                      {findLabelByID(item.creator, managers)}
                    </b>{" "}
                    изменил Исполнителя задачи{" "}
                    <b>
                      {" "}
                      {findLabelByID(item.info.old, managers)} &#10144;{" "}
                      {findLabelByID(item.info.new, managers)}
                    </b>
                  </li>
                ))}
              </div>
              <div>
                <div className="popup__footer">
                  <div
                    className="popup__subtitle"
                    onClick={handleExpandComment}
                  >
                    Добавить комментарий:
                  </div>
                  <div
                    className="wrapper__comments"
                    style={{ display: expandComment ? "flex" : "none" }}
                  >
                    <div>
                      <Avatar
                        sx={{
                          width: 35,
                          height: 35,
                          fontSize: "1rem",
                          bgcolor: "#ac69e5",
                        }}
                      >
                        {`${tasksCreator.charAt(0).toUpperCase()}`}
                      </Avatar>
                    </div>
                    <div className="tasks_footer_btn">
                      <div style={{ width: "100%" }}>
                        <ReusableTextField
                          text
                          multiline
                          rows={4}
                          label="Комментарий"
                          value={new_comment}
                          name="new_comment"
                          onChange={handleCommentChange}
                        />
                      </div>
                      <Button onClick={handleCommentSubmit}>
                        добавить комментарий
                      </Button>
                      <Button onClick={handleCommentCancel}>отменить</Button>
                    </div>
                  </div>
                </div>
                <div className="wrapper__buttons">
                  <Button onClick={handleTaskUpdate}>Сохранить</Button>
                  <Button onClick={handleTaskCancel}>Отмена</Button>
                </div>
              </div>
            </div>

            <div className="popup__right">
              <div className="wrapper__aside">
                <div className="popup__subtitle">Автор:</div>
                <div className="wrapper__avatar">
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: "1rem",
                      bgcolor: "#ac69e5",
                    }}
                  >
                    {`${tasksCreator.charAt(0).toUpperCase()}`}
                  </Avatar>
                  <div className="popup__subtitle_name">{tasksCreator}</div>
                </div>
              </div>
              <div className="wrapper__aside">
                <div className="popup__subtitle">Исполнитель:</div>
                <div className="wrapper__avatar">
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: "1rem",
                      bgcolor: "#ac69e5",
                    }}
                  >
                    {`${tasksExecutor.charAt(0).toUpperCase()}`}
                  </Avatar>
                  <div className="popup__subtitle_name">{tasksExecutor}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialogForTask
        open={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        title="Необходимо указать причину отказа"
        content=""
      />
      <SimpleConfirmModal
        isOpen={isStatusCheckModalOpen}
        onClose={closeCheckModal}
        onConfirm={handleCheckModalConfirm}
        content="Задачу может закрыть только ее создатель(автор)"
      />
      <SimpleConfirmModal
        isOpen={isNotificationOpen}
        onClose={closeNotificationModal}
        onConfirm={hanleConfirm}
        content={` Задача ${taskValues?.title} исполнителю ${
          tasksExecutor || ""
        } от ${taskValues?.date_due || ""} обновлена`}
      />
    </>
  );
};

export default TaskModalUpdate;
