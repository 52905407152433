import { sortByField } from "../../utils/helpers/sorting";

const initialState = {
  payload: [],
  searchResults: [],
  contactHistory: [],
  loading: false,
  loadingHistory: false,
  contactData: [],
};

// sorting history by date from nearest
// const historySortingByDate = (array) => {
//   array.sort((a, b) => {
//     const dateA = new Date(a.date_created);
//     const dateB = new Date(b.date_created);
//     return dateB - dateA;
//   });
//   return array;
// };

const add = (state, action) => {
  if (state.payload.length === 0) return [action.payload];
  // eslint-disable-next-line array-callback-return, consistent-return
  const mapped = state.payload.map((item) => {
    if (item.id_contact === action.payload.id_contact) {
      return [...state.payload];
    }
    return [...state.payload, action.payload];
  });
  console.log("mapped", mapped);
  return mapped;
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACTS":
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case "ADD_CONTACT":
      return {
        ...state,
        payload: [action.payload],
        loading: false,
      };
    case "UPD_CONTACT":
      return {
        ...state,
        contactData: {
          ...state.contactData,
          currentContact: action.payload.contact,
        },
        
        loading: false,
      };
    case "UPD_CONTACT_FROM_CREATE_CONTRACT":
      return {
        ...state,
        payload: state.payload.map((contact) =>
          // eslint-disable-next-line eqeqeq
          contact.id_contact == action.payload.id_contact
            ? action.payload
            : contact
        ),
        loading: false,
      };
    case "GET_CONTACT_HISTORY":
      return {
        ...state,
        contactHistory: [
          ...action.payload.leads,
          ...action.payload.comments,
        ].sort(sortByField("date_created")),
        loadingHistory: action.loadingHistory,
        // payload: [action.payload.contact],
      };
    case "SET_DATA_FOR_CONTACT_DIALOG":
      return {
        ...state,
        contactHistory: [
          ...action.payload.currentHistory.leads,
          ...action.payload.currentHistory.comments,
        ].sort(sortByField("date_created")),
        contactData: action.payload,
        loading: action.loading,
      };
    case "LOADING_CONTACTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "LOADING_HISTORY":
      return {
        ...state,
        loadingHistory: action.loadingHistory,
      };
    default:
      return state;
  }
};

export default contactsReducer;
