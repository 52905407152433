const initialState = {
  payload: [],
  loading: false,
};

const paymentsReducerTest = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAYMENTS':
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "LOADING_PAYMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case 'ADD_PAYMENT':
      return {
        ...state,
        payload: [...state.payload, action.payload],
        loading: action.loading,
      };
    case 'UPDATE_PAYMENT':
      return {
        ...state,
        payload: state.payload.map((payment) => (
          (payment.id === action.payload.id)
            ? action.payload : payment)),
        loading: action.loading,
      };
    case "CLEAR_PAYMENTS":
      return initialState;
    default:
      return state;
  }
};

export default paymentsReducerTest;
