import axios from "../../config/axiosConfig";

export default {
  get: () => axios.get("/lesson"),
  add: (data) => axios.post("/lesson/add", data),
  update: (data) => axios.post("/lesson/update", data),
  upload: (file) => axios.post(`/image/upload`, file),
  download: (id) => axios.get(`/file/get?id=${id}`, { responseType: "blob" }),
  remove: (id) => axios.get(`/remove/attachment?id=${id}`),
};
