import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import "./AdvicePopup.scss";
import { Close } from "@mui/icons-material";

// * AdvicePopup component used to display a help icon,
// * with a popup that contains the children passed to it
// * you can use html, text, or any other component as children

// * example:
// <strong>Редактирование урока: </strong> Дважды кликните на существующий
//        урок, чтобы отредактировать его. <br />
//        <strong>Создание урока:</strong> Дважды кликните на пустую клетку, чтобы
//        создать новый урок. <br />

const AdvicePopup = ({ children }) => {
  const [showAdvice, setShowAdvice] = useState(false);

  const toggleAdvice = () => {
    setShowAdvice(!showAdvice);
  };

  return (
    <div className="advice-container">
      <IconButton className="advice-icon" onClick={toggleAdvice}>
        <HelpIcon className="help-icon" />
      </IconButton>

      {showAdvice && (
        <div className="advice-popup">
          <span className="close-btn" onClick={toggleAdvice}>
            <Close />
          </span>
          {children}
        </div>
      )}
    </div>
  );
};

export default AdvicePopup;
