// find entity type from id. return entity type object
/**
 * @param id {string} - entity id
 * @param entityType {object[]} - array of entity types
 * @returns {object} - object of type
 */
export const getNameFromId = (id, entityType) =>
	entityType.find((type) => type.value === id);
// find entity type from id. return entity type name
/**
 * @param id {string[]} - entity id
 * @param entityType {object[]} - array of entity types
 * @returns {string} - name of type
 */
export const getNameFromArrayId = (id, entityType) => {
	const names = [];
	id.map((item) => {
		// const  teacherName = entityType?.find((obj) => obj.value === item);
		const  teacherName = entityType?.find((obj) => obj.value === item);
		 return names.push(teacherName?.label || 'UNKNOWN/DELETED');
	});
	return names;
};