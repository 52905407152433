/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
const initialState = {
  payload: [],
  loading: true,
  days: {},
};

// group lessons object
// (exapm: {topic: '11111111', students: Array(2), group: {…}, date: '2022-03-14',}...etc)
// by date like "date1":{lesson1Data, lesson2Data.... }
const groupByDate = (source) => {
  const key = "date";
  const reduced = source.reduce((acc, x) => {
    if (Object.keys(x).length !== 0) {
      const group = {
        ...x.group,
        students: x.students.map((s) => ({ ...s, isPresence: s.isPresence })),
        completed: x.completed,
        id: x.lesson_id,
        topic: x.topic,
        message: x.message,
        home_work: x.home_work,
        id_temporary_arrangement: x.id_temporary_arrangement,
        temporary_arrangement: x.temporary_arrangement,
        id_appointment: x.id_appointment,
        default_topic: x.default_topic,
        subject: x.subject,
        time: x.time,
        timing: x.timing,
        id_attachments: x.id_attachments,
      };

      (acc[x[key]] = acc[x[key]] || []).push(group);
    }
    return acc;
  }, {});

  return reduced;
};

// find changed lesson in state and replace changed data
const updateGroup = (days, lesson) => {
  const groups = days[lesson.date];
  if (!lesson) {
    return;
  }
  if (groups) {
    const exist = groups.find((g) => g.id_group === lesson.group);
    if (exist) {
      exist.students = lesson.students.slice();
      exist.topic = lesson.topic.slice();
      exist.home_work = lesson.home_work;
      exist.description = lesson.description;
      exist.id = lesson.lesson_id;
      exist.message = lesson.message;
      exist.completed = lesson.completed;
      exist.id_temporary_arrangement = lesson.id_temporary_arrangement;
      exist.id_appointment = lesson.id_appointment;
      exist.default_topic = lesson.default_topic;
      exist.subject = lesson.subject;
      exist.id_attachments = lesson.id_attachments;
    }
  }
  // eslint-disable-next-line consistent-return
  return days;
};

const updAttachments = (days, lesson_id, id_attachment) => {
  // console.log(lesson_id);
  // console.log(id_attachment);
  Object.keys(days).map((key) => {
    // console.log(...days[key], lesson_id);
    days[key].map((item) => {
      // eslint-disable-next-line eqeqeq
      if (item.id == lesson_id) {
        // console.log("item.id_attachments", item.id_attachments);
        return item.id_attachments.push(id_attachment);
      }
      return item;
    });
  });
  return days;
};

const removeAttachment = (days, lesson_id, id_attachment) => {
  // console.log(lesson_id);
  console.log("id_attachment", id_attachment);
  console.log("TYPE", typeof id_attachment);
  Object.keys(days).map((key) => {
    // console.log(...days[key], lesson_id);
    days[key].map((item) => {
      // eslint-disable-next-line eqeqeq
      if (item.id == lesson_id) {
        console.log("item.id_attachments", item.id_attachments);
        if (!item.id_attachments) {
          return;
        }
        const filtered = item.id_attachments.filter(
          (attachment) => attachment !== id_attachment
        );
        item.id_attachments = filtered;
      }
      return item;
    });
  });
  return days;
};

const ScheduleOfClassesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LESSONS":
      // console.log("приходит в payload", action.payload);
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
        days: groupByDate(action.payload),
      };

    case "LOADING_LESSONS":
      return {
        ...state,
        loading: action.loading,
      };

    case "POST_LESSON":
      return {
        ...state,
        loading: action.loading,
        days: updateGroup({ ...state.days }, action.payload),
      };

    case "UPDATE_LESSON":
      return {
        ...state,
        loading: action.loading,
        days: updateGroup({ ...state.days }, action.payload),
      };
    case "UPDATE_ATTACHMENTS":
      return {
        ...state,
        days: updAttachments(
          { ...state.days },
          action.payload.lesson_id,
          action.payload.id_attachment
        ),
      };
    case "REMOVE_ATTACHMENT":
      return {
        ...state,
        days: removeAttachment(
          { ...state.days },
          action.payload.lesson_id,
          action.payload.id_attachment
        ),
      };
    default:
      return state;
  }
};

export default ScheduleOfClassesReducer;
