import axios from "../../config/axiosConfig";

export default {
  get: () => axios.get("/tasks/get"),
  roles: () => axios.get("/users_role/get"),
  add: (data) => axios.post("/task/add", data),
  update: (data) => axios.post("/task/update", data),
  upload: (file) => axios.post("/image/upload", file),
  add_comment: (comment) => axios.post("task/add_comment", comment),
  status_update: (new_status) => axios.post("task/status_update?", new_status),
};


