const initialState = {
  appointments: [],
  substitute: [],
  loading: false,
  error: "",
};

const substitutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEACHERS_PLAN":
      return {
        ...state,
        substitute: action.payload.substitute,
        appointments: action.payload.appointments,
        config: action.payload.config,
        loading: action.loading,
      };
    case "LOADING_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "ADD_APPOINTMENTS":
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
        loading: action.loading,
        error: action.error,
      };
    case "CANCEL_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "UPDATE_APPOINTMENTS":
      return {
        ...state,
        appointments: state.appointments.map((item) => {
          // console.log("item", item);
          // console.log("action.payload", action.payload);
          // eslint-disable-next-line eqeqeq
          if (item.id == action.payload.id) {
            // console.log("item.id === action.payload.id", typeof item.id, typeof action.payload.id);
            return { ...item, ...action.payload };
          }
          // console.log("NOT")
          return item;
        }
          // item.id == action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    case "ADD_SUBSTITUTION":
      return {
        ...state,
        substitute: [...state.substitute, action.payload],
        loading: action.loading,
      };
    case "UPDATE_SUBSTITUTION":
      return {
        ...state,
        substitute: state.substitute.map((item) =>
          // eslint-disable-next-line eqeqeq
          item.id == action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    case "REMOVE_APPOINTMENTS":
      return {
        ...state,
        appointments: state.appointments.filter(
          (item) => item.id !== action.payload.appointment
        ),
        loading: action.loading,
      };
    case "REMOVE_SUBSTITUTION":
      return {
        ...state,
        appointments: state.appointments.map((item) => {
          if (item.id === action.payload.appointment) {
            item.is_substitute = "";
          }
          return item;
        }),
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default substitutionsReducer;
