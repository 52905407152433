import React, { useState } from "react";
import { Dialog, Button } from "@mui/material";
import {
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

const WorkingOutPopUp = ({
  open,
  handleClose,
  dataWorkingOut,
  contractInfo,
}) => {
  const [listOfGroups, setListOfGroups] = useState("");
  const [value, setValue] = useState([]);
  const [infoWorkingOut, setInfoWorkingOut] = useState([]);
  const [dateWorkingOut, setDateWorkingOut] = useState("");
  const [colorCard, setColorCard] = useState(false);

  const onCloseHandler = () => {
    handleClose();
  };

  const handleGroupChange = (event) => {
    setListOfGroups(event.target.value);
    const dataAboutGroup = dataWorkingOut.filter(
      (el) => el.name_group === event.target.value
    );
    setInfoWorkingOut(...dataAboutGroup);

    const freeDates = dataWorkingOut
      .filter((el) => el.name_group === event.target.value)
      .map((el) => el.date)[0];

    setValue(freeDates);
  };

  const sendToAppoint = () => {
    const dataSend = {
      temporary_arrangement: {
        contract_id: contractInfo.contract_id || contractInfo.id,
        group_for_temporary_arrangement:
          infoWorkingOut.group_for_temporary_arrangement.toString(),
        date: dateWorkingOut,
      },
    };
    contractApi
      .send(dataSend)
      .then(notification.success("Дата отрабоки выбрана"))
      .catch((err) => {
        notification.error("Не удалось назначить отработку");
        console.error(err);
      });
    handleClose();
    setListOfGroups("");
    setValue([]);
    setColorCard(false);
  };

  const handleDate = (event) => {
    setDateWorkingOut(event.target.value);
    setColorCard(true);
  };

  const sortDataWorkingOut = [];
  for (let i = 0; i < dataWorkingOut.length; i++) {
    if (dataWorkingOut[i].course === contractInfo.course) {
      sortDataWorkingOut.unshift(dataWorkingOut[i]);
    } else {
      sortDataWorkingOut.push(dataWorkingOut[i]);
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={onCloseHandler}>
        <DialogTitle>Отработки</DialogTitle>
        <DialogContentText sx={{ ml: 3 }}>
          {dataWorkingOut.length > 0
            ? "Выберите группу и дату отработки"
            : "Отработки отсутствуют"}
        </DialogContentText>

        {dataWorkingOut.length > 0 && (
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ m: 3, minWidth: 400 }}>
              <InputLabel htmlFor="group">Группа</InputLabel>
              <Select
                autoFocus
                value={listOfGroups}
                onChange={handleGroupChange}
                label="Группа"
                loadingtext="Поиск..."
                inputProps={{
                  name: "Groups",
                  id: "group",
                }}
              >
                {sortDataWorkingOut.map((group) => (
                  <MenuItem
                    value={group.name_group}
                    key={group.group_for_temporary_arrangement}
                    date={group.date}
                  >
                    {group.name_group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              {value.map((date) => (
                <TextField
                  key={date}
                  sx={{ m: 3, minWidth: 120 }}
                  id="outlined-read-only-input"
                  label="Дата"
                  defaultValue={date}
                  onClick={handleDate}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ))}
            </div>
          </Box>
        )}

        <DialogActions>
          <Button onClick={onCloseHandler}>Назад</Button>
          <Button
            onClick={sendToAppoint}
            disabled={!dateWorkingOut || !colorCard || dataWorkingOut.length === 0}
          >
            Назначить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkingOutPopUp;
