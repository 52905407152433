/* eslint-disable import/no-cycle */
import axios from "axios";
import { tasksApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getTasks = (data) => ({
  type: "GET_TASKS",
  payload: data,
  loading: false,
});

export const setPersonalTasks = (data) => ({
  type: "SET_PERSONAL_TASKS",
  payload: data,
  loading: false,
});

export const addTask = (data) => ({
  type: "ADD_TASK",
  payload: data,
  loading: false,
});

export const updTask = (data) => ({
  type: "UPD_TASK",
  payload: data,
  loading: false,
});

export const addComment = (data) => ({
  type: "ADD_COMMENT",
  payload: data,
  loading: false,
});

export const loadingTasks = () => ({
  type: "LOADING_TASKS",
  loading: true,
});
export const updTaskStatus = (data) => ({
  type: "UPD_TASK_STATUS",
  payload: data,
  loading: false,
});

export const setError = (error) => ({
  type: "SET_ERROR",
  payload: error,
  loading: false,
});

export const fetchTasks = () => (dispatch) => {
  dispatch(loadingTasks());
  return tasksApi
    .get()
    .then(({ data }) => dispatch(getTasks(data)))
    .catch((error) => {
      dispatch(setError(error));
    });
};

export const addNewTask = (task) => (dispatch) => {
  dispatch(loadingTasks());
  return tasksApi
    .add(task)
    .then(({ data }) => {
      dispatch(addTask(data));
      notification.success("Задача добавлена");
    })
    .catch((error) => {
      dispatch(setError(error));
    });
};

export const addNewComment = (comment) => (dispatch) => {
  dispatch(loadingTasks());
  return tasksApi.add_comment(comment).then(({ data }) => {
    if (data === "ok") {
      console.log(comment);
      notification.success("Комментарий добавлен");
      console.log("Комментарий добавлен", comment);
      dispatch(addComment(comment));
    } else {
      notification.error("При добавлении комментария произошла ошибка");
    }
  });
};

export const updateTaskStatus = (taskUpdData) => (dispatch) => {
  dispatch(loadingTasks());
  return tasksApi.status_update(taskUpdData).then(({ data }) => {
    // console.log("status", data)
    notification.success("Статус обновлен ");
    // console.log("taskUpdData", data);
    dispatch(updTaskStatus(data));
  });
};

export const updateTask = (task) => (dispatch) => {
  dispatch(loadingTasks());
  return tasksApi
    .update(task)
    .then(({ data }) => {
      dispatch(updTask(data));
      console.log(data);
      notification.success("Задача обновлена");
    })
    .catch((error) => {
      dispatch(setError(error));
    });
};

export const setUserPersonalTasks = () => (dispatch) => {
  axios.get("/notification/get").then((response) => {
    dispatch(setPersonalTasks(response.data.tasks));
  });
};

// export const updateManager = (lead) => (dispatch) =>
//   // dispatch(loadingLeads());
//   leadsApi.updManager(lead).then(({ data }) => {
//     notification.success("Менеджер изменен");
//     dispatch(updManager(data));
//   });

// export const updateLeadStatus = (lead) => (dispatch) =>
//   // dispatch(loadingLeads());
//   leadsApi.updLeadStatus(lead).then(({ data }) => {
//     notification.success("Статус лида изменен");
//     dispatch(updLeadStatus(data));
//   });
