import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const BackdropPartOfScreen = ({ open }) => {
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  return (
    <Backdrop open={open} sx={backdropStyles}>
      <CircularProgress value={50} />{" "}
    </Backdrop>
  );
};

export default BackdropPartOfScreen;
