import { scheduleViewApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getTopics = (data) => ({
	type: "GET_TOPICS",
	payload: data,
	loading: false,
});

export const setSchedules = (data) => ({
	type: "SET_SCHEDULES",
	payload: data,
	loading: false,
});

export const loadingSchedules = () => ({
	type: "LOADING_SCHEDULES",
	loading: true,
});

export const isUserMethodist = (data) => ({
	type: "IS_METHODIST",
	payload: data,
});

export const showTeacherPage = (data) => ({
	type: "VIEW_TEACHER_PAGE",
	payload: data,
});

export const clearSchedules = () => ({
	type: "CLEAR_SCHEDULES",
});

export const addTopic = (data) => ({
	type: "ADD_TOPIC",
	payload: data,
	loading: false,
});

export const badRequest = () => ({
	type: "BAD_REQUEST",
});

export const goodRequest = () => ({
	type: "GOOD_REQUEST",
});

export const setUpdatedTopic = (data) => ({
	type: "UPDATE_TOPIC",
	payload: data,
	loading: false,
});

export const deleteTopic = (id) => ({
	type: "REMOVE_TOPIC",
	payload: id,
	loading: false,
});

export const error = (err) => ({
	type: "ERROR",
	payload: null,
	loading: false,
	error: err,
});

export const fetchTopics = () => (dispatch) => {
	dispatch(loadingSchedules());
	scheduleViewApi.topicGet().then(({ data }) => {
		dispatch(getTopics(data));
	});
};

export const setSchedulesView = (obj) => (dispatch) => {
	dispatch(loadingSchedules());
	scheduleViewApi
		.postData({ ...obj })
		.then((data) => {
			console.log("DATA ACTION", data.data.schedules);
			dispatch(setSchedules(data.data.schedules));
			dispatch(goodRequest());
			notification.success("Данные отправлены");
		})
		.catch((err) => {
			alert(err.response.data.message);
			dispatch(error(err.response.data.message));
		});
	// .catch((err) => alert(err.response.data.message), dispatch(error));
	// .catch(() => dispatch(badRequest()));
};

export const sendTopic = (obj) => (dispatch) => {
	dispatch(loadingSchedules());
	scheduleViewApi.addTopic({ ...obj }).then(({ data }) => {
		notification.success("Тема занятия добавлена");
		dispatch(addTopic(data.topics));
	});
};

export const updateTopic = (obj) => (dispatch) => {
	dispatch(loadingSchedules());
	return scheduleViewApi.update(obj).then(({ data }) => {
		notification.success("Тема изменена");
		dispatch(setUpdatedTopic(data));
	});
};

export const removeTopic = (idObj) => (dispatch) => {
	dispatch(loadingSchedules());
	return scheduleViewApi.remove(idObj).then(() => {
		notification.success("Тема удалена");
		dispatch(deleteTopic(idObj.topic));
	});
};

// export const updateSelectedLesson = (obj) => (dispatch) => {
//   dispatch(loadingLessons());
//   lessonsApi.update({ ...obj }).then(({ data }) => {
//     dispatch(updateLesson(data.lesson));
//     notification.success("Данные отправлены");
//   });
// };
