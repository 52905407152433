/* eslint-disable array-callback-return */
import React from "react";
import { useDispatch } from "react-redux";
import {
  getDefaultUser,
  getPermissions,
} from "../../utils/helpers/getDefaultUser";
import socketURL from "../../utils/helpers/socketURL";
import notification from "../../utils/notification/notification";
import { addLead, updLead } from "../../redux/actions/leadsActions";
import { addTask, updTask } from "../../redux/actions/tasksActions";
import {
  addContact,
  fetchContactHistory,
} from "../../redux/actions/contactsActions";
import { log } from "../../utils/helpers/tracker";

const ConnectWebSocketComponent = () => {
  const socketRef = React.useRef(null);
  const [ws, setWs] = React.useState(null);
  const defaultUser = getDefaultUser();
  const permissions = getPermissions();
  const dispatch = useDispatch();

  const getToken = () => {
    const cookies = document.cookie;
    if (cookies.startsWith("st_pp")) {
      // console.log("cookie", cookies.substring("st_pp".length + 1));
      return cookies.substring("st_pp".length + 1);
    }
    return null;
  };

  const connectWebSocket = () => {
    // check if the user is not authenticated, do not enable sockets
    const defUser = getDefaultUser();
    const token = getToken();
    if (Object.keys(defUser).length === 0) {
      console.log("user is not authenticated, connection unavailable");
      return;
    }
    const url = socketURL();
    const socket = new WebSocket(url);
    // const socket = new WebSocket("wss://pp.stemlab.school/ws");
    // const socket = new WebSocket("wss://stemlab.school/ws");
    socketRef.current = socket;
    console.log(
      "currentUser:",
      defUser,
      "currentToken:",
      token,
      "WSRefference:",
      socketRef?.current
    );

    socket.onopen = () => {
      console.log("WebSocket Connected");
      const auth = JSON.stringify({
        authenticate: token,
      });
      socket.send(auth);
      console.log("Auth sent", auth);
      setWs(socket);

      const sendPing = () => {
        if (socket.readyState === WebSocket.OPEN) {
          const pingMessage = "ping";
          socket.send(pingMessage);
          console.log("Sent:", pingMessage);
        }
      };
      // Send "ping" every 7 seconds
      const pingInterval = setInterval(sendPing, 7000);

      const handleWebSocketMessage = (json) => {
        console.log("WebSocket message:", json);

        if (
          json.type === "new_entities" &&
          Object.keys(json.payload[0]).includes("task")
        ) {
          dispatch(addTask(json.payload[0].task));
          log(
            "New Task Added",
            { task: json.payload[0].task },
            "socket_connection"
          );
          if (defaultUser.id.toString() === json.payload[0].task.executor) {
            notification.success(
              `Вам пришла новая задача ${json.payload[0].task.title}`
            );
          } else {
            notification.success(
              `новая задача в CRM: ${json.payload[0].task.title}`
            );
          }
        }

        if (
          json.type === "update_entities" &&
          Object.keys(json.payload[0]).includes("task")
        ) {
          dispatch(updTask(json.payload[0].task));
          log(
            "Task Updated",
            { task: json.payload[0].task },
            "socket_connection"
          );
          if (defaultUser.id.toString() === json.payload[0].task.executor) {
            notification.success(
              ` У Вас обновилась задача  ${json.payload[0].task.title}`
            );
          } else {
            notification.success(
              `обновилась задача в CRM:  ${json.payload[0].task.title}`
            );
          }
        }

        if (
          json.type === "new_entities" &&
          Object.keys(json.payload[0]).includes("lead")
        ) {
          dispatch(addLead(json.payload[0].lead));
          dispatch(addContact(json.payload[0]?.contact));
          log(
            "Lead & Contact Added",
            { lead: json.payload[0].lead, contact: json.payload[0]?.contact },
            "socket_connection"
          );
          if (defaultUser.id.toString() === json.payload[0].lead.manager) {
            notification.success(
              `Вам пришел новый ЛИД  ${json.payload[0].lead.name}`
            );
          } else {
            notification.success(
              `новый ЛИД в CRM: ${json.payload[0].lead.name}`
            );
          }
        }
        if (
          json.type === "update_entities" &&
          Object.keys(json.payload[0]).includes("lead")
        ) {
          dispatch(updLead(json.payload[0].lead));
          log(
            "Lead Updated",
            { lead: json.payload[0].lead },
            "socket_connection"
          );
          if (defaultUser.id.toString() === json.payload[0].lead.manager) {
            notification.success(
              `У вас обновился ЛИД ${json.payload[0].lead.name}`
            );
          } else {
            notification.success(
              `обновился ЛИД в CRM: ${json.payload[0].lead.name}`
            );
          }
        }
        if (json.type === "deadline_tasks") {
          notification.success(
            `У вас просрочены ${json.payload.length} задачи ${json.payload?.map(
              (task, index) => {
                dispatch(addTask(task));
                return `${index + 1}) ${task.title}\n`;
              }
            )}`
          );
          log("Deadline", { tasks: json.payload }, "socket_connection");
        }
      };

      socket.onmessage = (event) => {
        if (event.data === "pong") {
          // console.log("Received:", event.data);
        } else {
          console.log("Received:", event.data);
          const json = JSON.parse(event.data);
          handleWebSocketMessage(json);
        }
      };

      socket.onclose = (event) => {
        clearInterval(pingInterval);
        if (event.wasClean) {
          console.log(
            `Closed cleanly, code=${event.code}, reason=${event.reason}`
          );
        } else {
          console.log("Connection died");
          // Reconnect to WebSocket
        }
        console.log("WebSocket Connection closed", event);
        connectWebSocket();
      };
    };
  };

  React.useEffect(() => {
    connectWebSocket();
    // we close ws connection, when component unmount
    return () => {
      if (
        socketRef.current &&
        socketRef.current.readyState !== WebSocket.CONNECTING
      ) {
        const disconnect = "disconnect";
        socketRef.current.send(disconnect);
        socketRef.current.close();
        console.log("Sent:", disconnect);
      }
    };
  }, []);
};

export default ConnectWebSocketComponent;
