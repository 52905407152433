import { withFormik } from "formik";

import store from "../../../redux/store";

import validate from "../../../utils/helpers/validation";
import { loginApi } from "../../../utils/api";
import { login } from "../../../redux/actions/loginActions";

import LoginForm from "../components/LoginForm";



const LoginFormContainer = withFormik({
  mapPropsToValues: () => ({ email: "" }),
  validate: (values) => {
    const errors = {};

    validate({ values, errors });

    return errors;
  },


 
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    
    setSubmitting(true);
    loginApi
      .auth(values)
      .then((response) => {
        console.log("response", response);
        const {responseURL} = response.request;
        if (responseURL.includes('/app/error?')) {
          const message = responseURL.split('/app/error?message=')[1];
          console.log("message", message);
          if (message) {
            // Decode and set the error message in Formik
            setErrors({ email: decodeURIComponent(message) });
            console.log("decodeURIComponent(message)", decodeURIComponent(message));
          } else {
            setErrors({ email: 'Unknown error occurred.' });
          }
        } else {
          const { from } = props.location.state || { from: { pathname: "/" } };
          store.dispatch(login(response.data));
          props.history.replace(from);
        }
        
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  },
})(LoginForm);

export default LoginFormContainer;
