const validate = ({ values, errors }) => {
  if (!values.email) {
    errors.email = "Введите email";
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/i.test(values.email)
  ) {
    errors.email = "Некорректный email";
  }
};
export default validate;
