export const getDefaultUser = () =>
  JSON.parse(localStorage.getItem("user")) || {};

export const getPermissions = () =>
  JSON.parse(localStorage.getItem("permissions")) || {};

export const getUserToken = () => {
  const cookies = document.cookie;
  console.log("cookies", cookies);
  if (cookies.startsWith("st_pp")) {
    return cookies.substring("st_pp".length + 1);
  }
  return null;
};

// loacations came from the server for each user

export const getUserLocation = () =>
  JSON.parse(localStorage.getItem("access")) || {};
