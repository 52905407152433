/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import CreatingAcademicYear from "./CreatingAcademicYear";
import CreatingCamp from "./CreatingCamp";
import CreatingIndividual from "./CreatingIndividual";
import { contactsApi, contractApi } from "../../utils/api";

const programs = [
  { value: "academic-year", label: "Учебный год" },
  { value: "camp", label: "Лагерь" },
  { value: "individual", label: "Индивидуальное занятие" },
];

function ContractTypeSelector({ lead, contactFromContactDialog }) {
  // console.log('selectorProps', lead, contactFromContactDialog);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const contactsBase = useSelector(({ contacts }) => contacts.payload);
  const leadsBase = useSelector(({ leads }) => leads.payload);

  const [stemlabData, setStemlabData] = React.useState(null);
  const [contactHistory, setHistory] = React.useState(null);
  const [disableContractCreate, setDisable] = React.useState(false);
  const [allContactLeads, setLeads] = React.useState(null);
  const [lastLeadID, setLastLeadID] = React.useState(null);
  const [currentLead, setCurrentLead] = React.useState(null);
  const [contact, setContact] = React.useState(null);

  const allEntities = useSelector(({ entitiesTypes }) => entitiesTypes);

  // TODO: remove to api or redux
  React.useEffect(() => {
    contractApi.getDataForContracts().then((data) => {
      setStemlabData({ ...data.data });
      // console.log("DATA", data.data);
      // console.log("allEntities", allEntities);
    });
  }, []);

  React.useEffect(() => {
    if (lead) {
      contactsApi.getHistory(lead?.id_contact).then((history) => {
        setHistory([...history.data.comments, ...history.data.leads]);
        setContact(history.data.contact);
        // console.log("history", history.data);
      });
    }
    if (contactFromContactDialog) {
      contactsApi
        .getHistory(contactFromContactDialog?.id_contact)
        .then((history) => {
          setHistory([...history.data.comments, ...history.data.leads]);
          setContact(history.data.contact);
          // console.log("history", history.data);
        });
    }
  }, []);

  // смотрим есть ли лиды у контакта, сетаем их в allContactLeads(не сами лиды, а данные - ид, когда создан и т.д.)
  React.useEffect(() => {
    let leads = [];
    if (contactHistory && !lead) {
      leads = contactHistory.filter((item) => item.comments);
      // console.log('leads>>>>>>>', leads);
      // console.log('contactHistory>>>>>>>', contactHistory)
      if (leads.length === 0) {
        setDisable(true);
        // console.log("НЕТ СОЗДАННЫХ ЛИДОВ");
        return;
      }
      // console.log("ЛИДЫ ИЗ КОММЕНТАРИЕВ ID", leads);
      setLeads(leads);
      console.log(allContactLeads);
    }
  }, [contactHistory, setHistory]);

  // ищем данные о последнем лиде в allContactLeads
  React.useEffect(() => {
    let last = "";
    let objectWithLatestDate = null;
    allContactLeads?.map((item) => {
      const currentDate = item.date_created;
      if (currentDate > last) {
        last = currentDate;
        objectWithLatestDate = item;
        // console.log(
        //   "это данные о последнем лиде >>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
        //   objectWithLatestDate
        // );
      }
      setLastLeadID(objectWithLatestDate);
    });
  }, [allContactLeads]);

  // ищем сам последний лид в ридаксе
  React.useEffect(() => {
    const finded = leadsBase.find(
      (item) => item.id_lead == lastLeadID?.id_lead
    );
    setCurrentLead(finded);
    //  console.log('CURRENT>>>>>', finded)
  }, [lastLeadID]);

  // ищем контакт пользователя
  // React.useEffect(() => {
  //   let finded = null;
  //   if (lastLeadID) {
  //     finded = contactsBase.find(
  //       (item) => item.id_contact == currentLead.id_contact
  //     );
  //   }
  //   if (lead) {
  //     finded = contactsBase.find((item) => item.id_contact == lead.id_contact);
  //   }

  //   setContact(finded);
  //   console.log("контакт", finded);
  // }, [currentLead]);

  const [selectedProgram, setSelectedProgram] = useState("");
  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
  };

  const renderAdditionalFields = () => {
    if (selectedProgram === "academic-year") {
      return (
        <CreatingAcademicYear
          data={stemlabData}
          entities={allEntities}
          lead={lead || currentLead}
          contact={contact}
          lastLeadId={lastLeadID?.id_lead}
        />
      );
    }

    if (selectedProgram === "camp") {
      return (
        <CreatingCamp
          data={stemlabData}
          entities={allEntities}
          lead={lead || currentLead}
          contact={contact}
          lastLeadId={lastLeadID?.id_lead}
        />
      );
    }

    if (selectedProgram === "individual") {
      return (
        <CreatingIndividual
          data={stemlabData}
          entities={allEntities}
          lead={lead || currentLead}
          contact={contact}
          lastLeadId={lastLeadID?.id_lead}
        />
      );
    }

    return null;
  };

  return !stemlabData ? (
    <Backdrop open={!stemlabData} sx={backdropStyles}>
      <CircularProgress />{" "}
    </Backdrop>
  ) : (
    <Container maxWidth="100%" sx={{ marginLeft: 0 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {lastLead && <pre>{JSON.stringify(lastLead, null, 2)}</pre>} */}
        <Typography variant="h4" align="left">
          Вид услуги:
        </Typography>
        <div style={{ width: "80%", marginLeft: "20px" }}>
          <FormControl style={{ width: "47%" }}>
            <Select
              size="small"
              disabled={disableContractCreate}
              value={selectedProgram}
              onChange={handleProgramChange}
            >
              {programs.map((program) => (
                <MenuItem key={program.value.length} value={program.value}>
                  {program.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {disableContractCreate ? (
        <div>нет созданных Лидов у этого контакта. Создайте Лид</div>
      ) : (
        renderAdditionalFields()
      )}
    </Container>
  );
}

export default ContractTypeSelector;
