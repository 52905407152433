const entitiesTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_UNIQUE_TYPES":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_ENTITY":
      console.log('action.payload',action.payload);
      return {
        ...state,
        [action.payload.entityType]: [
          ...state[action.payload.entityType],
          action.payload.entity,
        ],
      };
    default:
      return state;
  }
};
export default entitiesTypesReducer;
