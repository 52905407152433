import axios from "axios";
import store from "../redux/store";
import { logout } from "../redux/actions/loginActions";
import notification from "../utils/notification/notification";

// stage prefix
const { stage } = window.clientConfig;

if (stage === "v2") {
  axios.defaults.baseURL = `https://stemlab.school/api/v2`;
}
if (stage === "v1") {
  axios.defaults.baseURL = `https://dev.stemlab.school/api/v1`;
}
if (stage === "qa") {
  axios.defaults.baseURL = `https://pp.stemlab.school/api/v2`;
}
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// global error handling
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (error.response?.status) {
      case 403:
        store.dispatch(logout());
        notification.error("Ошибка доступа");
        console.error(error.response?.data?.message || "Ошибка доступа");
        break;
      case 404:
        notification.error("Неверные данные");
        console.error(error.response?.data?.message || "Неверные данные");
        break;
      default:
        notification.error("Ошибка получения данных");
        console.error(
          error.response?.data?.message || "Ошибка получения данных"
        );
        break;
    }

    return Promise.reject(error);
  }
);

export default axios;
