/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react";
import {
  Backdrop,
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormHelperText from "@mui/material/FormHelperText";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import GroupsIcon from "@mui/icons-material/Groups";
import DescriptionIcon from "@mui/icons-material/Description";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import { useDispatch } from "react-redux";
import { DataGridPro, useGridApiRef } from "../../dataGridLib/x-data-grid-pro";
import "./Lessons.css";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { lessonsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import {
  deleteAttachment,
  updateAttachments,
} from "../../redux/actions/ScheduleOfClassesActions";
import SimpleYesNo from "../ReusableSimpleYesNo/ReusableSimpleYesNo";

import TimePickerCell from "./TimePickerCell";

// const fullNameFormatter = (name) => {
//   const cuted = name.value
//     .split(/\s+/)
//     .map((w, i) => (i ? `${w.substring(0, 1).toUpperCase()}.` : w))
//     .join(" ");
//   return cuted;
// };

export default function PupilsTable({ ...props }) {
  const {
    students,
    postLessons,
    updateLessons,
    getLessons,
    group,
    date,
    loading,
    user_id,
    handleClose,
    subjects,
  } = props;

  // check if timing is enabled in group. if true, return time picker cells
  const adaptationChecker = (incomingGroup) => {
    if (incomingGroup?.timing === true) {
      return [
        {
          field: "from",
          headerName: "Начало",
          flex: 1,
          renderCell: (params) => {
            if (params.row.adaptation === true) {
              return <TimePickerCell group={group} params={params} />;
            }
            return <div style={{ width: "100%", height: "100%" }}>---</div>;
          },
        },
        {
          field: "to",
          headerName: "Окончание",
          flex: 1,
          renderCell: (params) => {
            if (params.row.adaptation === true) {
              return <TimePickerCell group={group} params={params} />;
            }
            return <div style={{ width: "100%", height: "100%" }}>---</div>;
          },
        }
      ];
    }
    return [];
  };

  const columns = [
    {
      field: "name",
      headerName: "Имя ученика",
      editable: true,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Примечания",
      flex: 2,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    ...adaptationChecker(group),
  ];

  const columnsReview = [
    {
      field: "name",
      headerName: "Имя ученика",
      flex: 1,
      editable: true,
    },
    {
      field: "review",
      headerName: "Отзыв",
      flex: 2,
      editable: true,
    },
  ];

  <Backdrop open={loading}>
    <CircularProgress color="inherit" />
  </Backdrop>;
  console.log("props", props);
  // const Emojis = ["😀", "😄", "😊", "😎"];
  // const [isEdit, setIsEdit] = React.useState(false);
  // const [selectedEmoji, setSelectedEmoji] = React.useState("");
  // const [editingCellId, setEditingCellId] = React.useState(null);
  // const handleEmojiSelect = (emoji) => {
  //   setSelectedEmoji(emoji);
  //   console.log("selectedEmoji", selectedEmoji);
  //   console.log("students", students);
  //   console.log("editingCellId", editingCellId);
  //   students.map((item) => {
  //     console.log("item", item);
  //     if (item.id === editingCellId) {
  //       item.review = `${item.review} ${emoji}`;
  //     }
  //     return students;
  //   });
  // };

  // const handleEditStart = (params,event) => {
  //   console.log("params", params);
  //   console.log("event", event);
  //   setIsEdit(true);
  //   if (event.target.tagName === 'span' && event.target.className.includes('emoji_wrapper')) {
  //     event.stopPropagation(); // Prevent exiting editing mode
  //   }
  //   if(params.field === "review") {
  //     console.log("review");
  //     setEditingCellId(params.id);
  //     params.row.review = `${params.row.review} ${selectedEmoji}`;
  //   }
  // };

  const initialSelectValue = [
    subjects.find((item) => item.value === group.subject),
  ];
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const stopEditCellMode = () => {
    if (!document.querySelector(".click_on_me")) {
      return;
    }
    const clickElem = document.querySelector(".click_on_me");
    clickElem.click();
  };

  const [inputError, setinputError] = React.useState(false);
  const [subjectError, setSubjectError] = React.useState(false);

  const [isStatusCheckModalOpen, setStatusCheckModalOpen] =
    React.useState(false);
  const [fileID, setFileID] = React.useState("");
  const [fileIndex, setFileIndex] = React.useState("");
  const [attachments, setAttachments] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  const [attached, setAttached] = React.useState([]);

  /* lesson input */

  const initialStateTopic = group.topic ? group.topic : "";
  const initialStateHomework = group.home_work ? group.home_work : "";
  const [inputValue, setInputValue] = React.useState(initialStateTopic);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setinputError(false);
  };
  const [navigator, setNavigator] = React.useState(0);

  // if only one subject today in group, it choose automatically
  // const [subjectSelectValue, setSubject] = React.useState(
  // 	group.subject.length === 1 ? [group.subject[0]] : ""
  // );

  const [subjectSelectValue, setSubject] = React.useState(
    initialSelectValue[0].label
  );
  const [subjectValue, setSubjectValue] = React.useState("");

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);

    const newValue = findLabelByID(event.target.value, subjects);
    // console.log("newValue", newValue);
    setSubjectValue(
      subjects.find((item) => item.label === event.target.value).value
    );
    setSubjectError(false);
  };

  const handleReviewAction = () => {
    setTimeout(() => {
      setNavigator("review");
      stopEditCellMode();
    }, 300);
  };

  /* Description input */
  const initialDescriptionState = "";
  const [descriptionValue, setDescription] = React.useState(
    initialDescriptionState
  );
  const handleDescriptioChange = (event) => {
    setDescription(event.target.value);
  };

  const openCheckModal = () => {
    setStatusCheckModalOpen(true);
  };
  const closeCheckModal = () => {
    setStatusCheckModalOpen(false);
  };
  const handleCheckModalConfirm = () => {
    lessonsApi.remove(fileID).then((resp) => {
      if (resp.data === "ok") {
        setPreview((prev) => prev.filter((item, index) => index !== fileIndex));
        dispatch(
          deleteAttachment({
            lesson_id: group.id,
            id_attachment: fileID,
          })
        );
      }
    });
    closeCheckModal();
  };

  /* HomeWork input */
  const [homeworkValue, setHomework] = React.useState(initialStateHomework);
  const handleHomeworkChange = (event) => {
    setHomework(event.target.value);
  };

  /* add files input and button */
  const handleFileUpload = (event) => {
    const f = event.target.files[0];
    let id = "";
    if (f) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview([...preview, e.target.result]);
      };
      reader.readAsDataURL(f);
    }
    if (!event.target.files[0]) {
      return;
    }
    setAttached((prev) => [...prev, f.name]);
    const formData = new FormData();
    if (group.id === "None") {
      id = "";
    } else {
      id = group.id;
    }
    formData.append("image", f);
    formData.append("lesson_id", String(id));
    lessonsApi
      .upload(formData)
      .then((response) => {
        setAttachments([...attachments, response.data.id_attachment]);
        notification.success(`файл ${f.name} добавлен`);
        // dispatch(
        //   updateAttachments({
        //     lesson_id: group.id === "None" ? "" : group.id,
        //     id_attachment: response.data.id_attachment,
        //   })
        // );
      })
      .catch((e) => alert(`Произошла ошибка : ${e.message}, ${e.code}`));
  };

  const downloadFile = (id) => {
    lessonsApi
      .download(id)
      .then((resp) => {
        const href = URL.createObjectURL(resp.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${id}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        notification.error("при скачивании файла произошла ошибка");
      });
  };

  const removeFile = (id, index) => {
    openCheckModal();
    setFileID(id);
    setFileIndex(index);
  };

  /* return sorted student(presence) array from current opened table */
  const selectedPupilSearcher = (selectsArray) => {
    students.map((item) => {
      if (selectsArray.includes(item.id)) {
        item.isPresence = true;
      } else {
        item.isPresence = false;
      }
      return students;
    });
  };

  const saveChangedDescriptionCell = (params) => {
    students.map((item) => {
      if (item.id === params.id && params.field === "description") {
        item.description = params.value;
      }
      return students;
    });
  };

  const saveChangedReviewCell = (params) => {
    students.map((item) => {
      if (item.id === params.id) {
        item.review = params.value;
      }
      return students;
    });
  };

  /*  return selected students IDs for selectRows func */
  const currentGroupSelect = (st) => {
    const selected = [];
    // eslint-disable-next-line array-callback-return
    st.map((item) => {
      if (item.isPresence) {
        selected.push(item.id);
      }
    });
    return selected;
  };

  React.useEffect(() => {
    if (navigator === 1 || navigator === "review") {
      apiRef.current.selectRows(currentGroupSelect(students));
      // console.log("selectedRows", students);
    }
  }, [navigator]);

  const handleSendSubmit = () => {
    stopEditCellMode();
    if (!inputValue || !subjectSelectValue) {
      setinputError(!inputValue);
      setSubjectError(!subjectSelectValue);
      setNavigator(0);
    }
    if (inputValue && subjectSelectValue) {
      setinputError(false);
      const data = {
        lesson: {
          date,
          group: group.id_group,
          topic: inputValue,
          default_topic: group.default_topic,
          message: descriptionValue,
          home_work: homeworkValue,
          students: students.map((item) => {
            if (item.isPresence === undefined) {
              item.isPresence = false;
            }
            return item;
          }),
          subject:
            subjectValue ||
            subjects.find((item) => item.label === subjectSelectValue).value,
          user_id: String(user_id),
          completed: true,
          id_appointment: group.id_appointment,
          id_temporary_arrangement: group.id_temporary_arrangement,
          temporary_arrangement: group.temporary_arrangement,
          id_attachments: attachments || [],
        },
      };
      postLessons(data);
      setTimeout(() => {
        getLessons();
      }, 2000);
      handleClose();
    }
  };

  const handleChangeSubmit = () => {
    stopEditCellMode();
    if (!inputValue || !subjectSelectValue) {
      setinputError(!inputValue);
      setSubjectError(!subjectSelectValue);
      setNavigator(0);
    }
    if (inputValue && subjectSelectValue) {
      const data = {
        lesson: {
          date,
          group: group.id_group,
          topic: inputValue,
          default_topic: group.default_topic,
          lesson_id: String(group.id),
          message: descriptionValue,
          home_work: homeworkValue,
          students,
          subject:
            subjectValue ||
            subjects.find((item) => item.label === subjectSelectValue).value,
          user_id: String(user_id),
          completed: true,
          id_appointment: group.id_appointment,
          id_temporary_arrangement: group.id_temporary_arrangement,
          temporary_arrangement: group.temporary_arrangement,
          id_attachments: group.id_attachments.concat(attachments) || [],
        },
      };
      updateLessons(data);
      handleClose();
    }
    // console.log(group);
  };

  return (
    <>
      {navigator === 0 && (
        <div className="lesson__main_text">
          <div sx={{ display: "flex", flexDirection: "column" }}>
            <div className="dafault_topick">
              Тема от методиста: {group.default_topic}{" "}
            </div>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <InputLabel id="subject-select">Выберите предмет</InputLabel>
              <Select
                labelId="subject-select"
                id="simple-select"
                // defaultValue={subjectSelectValue}
                value={subjectSelectValue}
                label="Выберите предмет"
                onChange={handleChangeSubject}
                placeholder="Выберите предмет"
                error={subjectError}
              >
                {subjects?.map((sub) => (
                  <MenuItem key={sub.value} value={sub.label}>
                    {sub.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {subjectError ? "Выберите предмет из списка" : null}
              </FormHelperText>
            </FormControl>
            <TextField
              size="small"
              sx={{ marginTop: "15px", width: "100%" }}
              onChange={handleInputChange}
              multiline
              maxRows={5}
              id="outlined-basic"
              label="Тема занятия"
              variant="outlined"
              value={inputValue}
              error={inputError}
              helperText={inputError ? "Введите тему занятия" : null}
            />
            <TextField
              size="small"
              sx={{ marginTop: "15px", width: "100%" }}
              onChange={handleHomeworkChange}
              multiline
              maxRows={5}
              id="outlined-homework"
              label="Домашнее задание"
              variant="outlined"
              value={homeworkValue}
            />
            <div
              style={{
                width: "97%",
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  style={{
                    color: group.topic ? "#2999DD" : "rgb(131, 131, 131)",
                    fontWeight: "bold",
                  }}
                >
                  Тема:{" "}
                </span>{" "}
                <span style={{ fontWeight: "400" }}>{group.topic}</span>{" "}
              </div>
              <div>
                <span
                  style={{
                    color: group.home_work ? "#2999DD" : "rgb(131, 131, 131)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Д/З:{" "}
                </span>{" "}
                <span style={{ fontWeight: "400" }}>
                  {group.home_work}
                </span>{" "}
              </div>
              <Divider />
              {preview.length > 0 && (
                <div className="preview__box">
                  <div className="preview__box_title">Вы добавили файлы:</div>{" "}
                  <div className="preview__box_images">
                    {preview.map((item, index) => (
                      <div className="preview__box_img_container">
                        <span
                          className="preview__box_delete_file"
                          onClick={() => {
                            removeFile(attachments[index], index);
                          }}
                        >
                          {" "}
                          <DeleteForeverIcon fontSize="40px" />{" "}
                        </span>
                        <img alt="file" src={item} />
                        {attached.length > 0 && <div>{attached[index]}</div>}
                      </div>
                    ))}{" "}
                  </div>
                </div>
              )}

              {group.id_attachments.length > 0 ? (
                <div>
                  Прикрепленных файлов: {group.id_attachments.length}
                  <div>
                    {group.id_attachments.map((id, index) => (
                      <div className="attachments__box">
                        файл {index + 1}
                        <span
                          className="download_attachment"
                          onClick={() => downloadFile(id)}
                        >
                          Скачать
                        </span>
                        <span
                          className="remove_attachment"
                          onClick={() => removeFile(id, null)}
                        >
                          Удалить
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {navigator === 1 && (
        <div style={{ width: "100vw", height: "75vh" }}>
          <DataGridPro
            getTreeDataPath={(row) => row.name}
            height="100%"
            sx={{
              fontSize: "12px",
            }}
            handleSendData={props.handleSendData}
            rows={props.students}
            columns={columns}
            disableSelectionOnClick
            checkboxSelection
            density="compact"
            flex
            apiRef={apiRef}
            onEditCellChange={(params, event) => {
              console.log("params", params);
              console.log("event", event);
            }}
            onSelectionModelChange={(selectionModel) => {
              // console.log("apiRef", apiRef);
              // console.log(selectionModel);
              selectedPupilSearcher(selectionModel);
            }}
            onCellEditCommit={(params) => {
              console.log("params", params);
              saveChangedDescriptionCell(params);
            }}
            components={{
              RowDetail: ({ row }) => (
                <div>
                  <div>{row.id}</div>
                </div>
              ),
            }}
          />
          <div className="click_on_me" />
        </div>
      )}

      {navigator === 2 && (
        <div className="lesson__main_description">
          <TextField
            sx={{ minWidth: "100%" }}
            size="small"
            width="80%"
            onChange={handleDescriptioChange}
            multiline
            maxRows={5}
            id="outlined-message"
            label="Примечания"
            variant="outlined"
            value={descriptionValue}
          />
          <div>
            <span
              style={{
                color: group.message ? "#2999DD" : "rgb(131, 131, 131)",
                fontWeight: "bold",
              }}
            >
              {" "}
              Примечания к группе:{" "}
            </span>{" "}
            <div
              style={{
                fontWeight: "400",
                whiteSpace: "normal",
              }}
            >
              {group.message}
            </div>{" "}
          </div>
        </div>
      )}

      {navigator === "review" && (
        <div style={{ width: "100vw", height: "75vh" }}>
          <DataGridPro
            handleSendData={props.handleSendData}
            rows={props.students}
            height="100%"
            sx={{
              fontSize: "12px",
            }}
            density="compact"
            columns={columnsReview}
            disableSelectionOnClick
            checkboxSelection
            flex
            apiRef={apiRef}
            onSelectionModelChange={(selectionModel) => {
              // console.log("apiRef", apiRef);
              // console.log(selectionModel);
              selectedPupilSearcher(selectionModel);
            }}
            onCellEditCommit={(params) => {
              saveChangedReviewCell(params);
            }}
          />
          <div className="click_on_me" />
        </div>
      )}

      <div className="navigation_wrapper">
        {/* <div className="emoji_wrapper">
          {isEdit && (
            <div style={{ marginTop: "10px" }}>
              {Emojis.map((emoji) => (
                <span
                  key={emoji}
                  role="img"
                  aria-label="emoji"
                  onClick={() => handleEmojiSelect(emoji)}
                >
                  {emoji}
                </span>
              ))}
            </div>
          )}
        </div> */}

        <div className="upload_wrapper">
          <label>
            <input
              type="file"
              placeholder="Выберите файл"
              hidden
              name="file"
              onChange={handleFileUpload}
            />
            <span className="upload_span_element">Загрузить файл</span>
          </label>
        </div>
        <div className="navigation_controls">
          <BottomNavigation
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
            showLabels
            value={navigator}
            onChange={(event, newValue) => {
              setTimeout(() => {
                setNavigator(newValue);
              }, 300);
              stopEditCellMode();
            }}
          >
            <BottomNavigationAction
              style={{
                border: "1px solid #2999dd",
                borderRadius: "5px",
                width: "32%",
              }}
              label="Информация"
              icon={<DensityMediumIcon />}
            />
            <BottomNavigationAction
              style={{
                border: "1px solid #2999dd",
                borderRadius: "5px",
                width: "32%",
              }}
              label="Ученики"
              icon={<GroupsIcon />}
            />
            <BottomNavigationAction
              style={{
                border: "1px solid #2999dd",
                borderRadius: "5px",
                width: "32%",
              }}
              label="Примечания"
              icon={<DescriptionIcon />}
            />
          </BottomNavigation>
          <BottomNavigation
            showLabels
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {group.completed ? (
              <BottomNavigationAction
                onClick={handleChangeSubmit}
                style={{
                  border: "1px solid #2999dd",
                  borderRadius: "5px",
                  backgroundColor: "rgb(102, 187, 106)",
                }}
                label="Изменить"
                showlabel="true"
                icon={<SendIcon />}
              />
            ) : (
              <BottomNavigationAction
                onClick={handleSendSubmit}
                style={{
                  border: "1px solid #2999dd",
                  borderRadius: "5px",
                  backgroundColor: "rgb(102, 187, 106)",
                }}
                showlabel="true"
                label="Отправить"
                icon={<SendIcon />}
              />
            )}
            <BottomNavigationAction
              onClick={handleReviewAction}
              style={{
                border: "1px solid #2999dd",
                borderRadius: "5px",
                backgroundColor:
                  navigator === "review" ? "rgba(41, 162, 221, 0.25)" : null,
              }}
              label="Отзывы"
              showlabel="true"
              icon={<CommentIcon />}
            />
            <BottomNavigationAction
              onClick={handleClose}
              style={{
                border: "1px solid #2999dd",
                borderRadius: "5px",
                backgroundColor: "rgba(200, 15, 15, 0.7)",
              }}
              label="Закрыть"
              icon={<CloseIcon />}
            />
          </BottomNavigation>
        </div>
        <SimpleYesNo
          isOpen={isStatusCheckModalOpen}
          onClose={closeCheckModal}
          onConfirm={handleCheckModalConfirm}
          content="Вы хотите удалить файл?"
        />
      </div>
    </>
  );
}
