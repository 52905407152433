import axios from "../../config/axiosConfig";

export default {
  /**
   * @param {string} param it's number of the contract to be searched
   */
  get: (param) => axios.get(`/contract/search?param=${param}`),
  update: (data) => axios.post("/debt_and_credit/update", data),
  /**
   * @param contract object with contract info
   * @param {string} contract.contracts_id id of the contract to be canceled
   * @param {string} contract.message reason for cancel
   */
  cancel: (contract) => axios.post("/contract/cancel", contract),
  /**
   * @param {string} id it's id of the contract to be choose for working out
   */
  getId: (id) => axios.get(`/contract/set/working_out?id=${id}`),
  /**
   * @param working_out object with working_out info
   * @param {string} working_out.contracts_id id of the contract to be working_out
   * @param {string} working_out.group_for_temporary_arrangement id group for working_out
   * @param {string} working_out.date the date for working_out
   */
  send: (working_out) => axios.post("/contract/add/working_out", working_out),

  /** get list with reasons for rejection */
  getList: () => axios.get("/get/list/cancelled"),

  // endponts for sales page and creating contract
  getDataForContracts: () => axios.get(`/contract_data/get`),
  create: (contract) => axios.post(`/contract/add`, contract),
  save: (name) =>
    axios.get(`/contract/download?q=${name}`, { responseType: "blob" }),
  remove: (contract) => axios.post(`/contract/remove`, contract),
  getAllContracts: () => axios.get(`/contracts/get`),
  getCalculations: (data) => axios.post(`/calculations/get`, data),
  getCalculations9parts: (data) => axios.post(`/calculate/parts/get`, data),

  contractExtend: (params) => axios.post(`/contract/extend`, params), // extend contract {contract_id, course_id, group_id} - params
  getKG: () => axios.get(`/contract/get_kg`), //  get programs and groups for kindergartens
};

//
