/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import "./TasksKanban.scss";
import { useDispatch } from "react-redux";
// import { updatetaskstatus } from "../../redux/actions/tasksActions";
import { InputLabel, MenuItem, Select } from "@mui/material";
import notification from "../../utils/notification/notification";
import { ReusableTextField } from "../ReusableInputs/ReusableInputs";
import { sortByField } from "../../utils/helpers/sorting";
import TaskCard from "../TaskCard/TaskCard";
import TaskModalUpdate from "../TaskModal/TaskModalUpdate";
import {
  addNewComment,
  updateTaskStatus,
} from "../../redux/actions/tasksActions";
import SimpleConfirmModal from "../ReusableSimpleConfirmatin/SimpleConfirmModal";
import { getDefaultUser } from "../../utils/helpers/getDefaultUser";
import ConfirmationDialogForTask from "../TaskModal/ConfirmationDialogForTask";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";

function TasksKanban({ tasksData, ...props }) {
  console.log("props", props);
  // get roles from props
  // const { users: roles } = props;
  // console.log("roles", roles);
  // const backdropStyles = {
  //   bgcolor: "#fff",
  //   zIndex: 2,
  //   position: "relative",
  //   height: "800px",
  //   borderRadius: "10px",
  // };

  const dispatch = useDispatch();
  const defUser = getDefaultUser();

  const [tasks, setTasks] = useState(null);
  const [openCard, isOpenCard] = useState(false);
  const [infoModal, setInfoModal] = useState();
  const tasksCounter = (tasksArray, status) => {
    if (!tasksArray) {
      return "0";
    } else {
      return tasksArray?.filter((lead) => lead.status === status).length;
    }
  };
  const users = props.entitiesTypes.user;
  users.sort(sortByField("label"));
  const activeUsers = props.entitiesTypes.active_users;
  // console.log("users", users);

  // simpleModal confirm when user try to change task status to "close"
  const [isStatusCheckModalOpen, setStatusCheckModalOpen] = useState(false);
  const openCheckModal = () => {
    setStatusCheckModalOpen(true);
  };
  const closeCheckModal = () => {
    setStatusCheckModalOpen(false);
  };
  const handleCheckModalConfirm = () => {
    console.log("Confirmed!");
    closeCheckModal();
  };

  // confirmation Dialog actions when user try to move task to "denied_execution"
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };
  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };
  const [denided, setDenidedTask] = React.useState("");
  const handleConfirmAction = (reasonValue) => {
    dispatch(
      addNewComment({
        id_task: denided.id_task,
        comment: `причина отказа:  ${reasonValue}`,
      })
    );
    dispatch(
      updateTaskStatus({ id_task: denided.id_task, status: "denied_execution" })
    );
    setTasks((prevtask) =>
      prevtask.map((t) => {
        if (t.id_task === denided.id_task) {
          t.status = "denied_execution";
        }
        return t;
      })
    );
    // console.log("Action confirmed!", denided);
    setIsConfirmationOpen(false);
  };

  const handleDragStart = (e, task) => {
    e.dataTransfer.setData("task", JSON.stringify(task));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // const handleDoubleClick = (task) => {
  //   setUpdtask(task);
  //   handletaskOpen();
  //   console.log("updtask", updtask);
  // };

  // task.status это текущий статус задачи
  // status это статус про событии drop
  const handleDrop = (e, status) => {
    const task = JSON.parse(e.dataTransfer.getData("task"));
    // console.log("e", e);
    // console.log("task", task);
    // console.log("statusDrop", status);
    if (status === "closed" && task.autor !== defUser.id.toString()) {
      openCheckModal();
      return;
    }
    if (task.status === "closed") {
      notification.warning("Запрещенное действие. Задача уже закрыта");
      return;
    }
    if (status === "denied_execution") {
      handleOpenConfirmation();
      setDenidedTask(task);
      return;
    }
    setTasks((prevtask) =>
      prevtask.map((t) => {
        if (t.id_task === task.id_task) {
          t.status = status;
        }
        return t;
      })
    );
    dispatch(updateTaskStatus({ id_task: task.id_task, status }));

    // console.log("status", status);
  };

  const handleDoubleClick = (task) => {
    isOpenCard(true);
    setInfoModal(task);
  };

  const onClose = () => {
    isOpenCard(false);
  };

  // ------ *    !!! not remove! code for moving cards!!!  -----

  // const [sortDirection, setSortDirection] = useState(
  //   settings?.sort || "по возрастанию"
  // );
  // const [sortByDueDirection, setSortByDueDirection] = useState(
  //   settings?.sort_by_due || "по убыванию"
  // );

  // const sorttasksByDateDue = (task1, task2) =>
  //   task1.date_due < task2.date_due ? 1 : -1;
  // const sorttasksByDateDueBackward = (task1, task2) =>
  //   task1.date_due > task2.date_due ? 1 : -1;
  // const sorttasksByDateCreatedFromLast = sortedtasks
  //   .slice()
  //   .sort(sortByField("date_created"));
  // const sorttasksByDateCreatedFromFirst = sortedtasks
  //   .slice()
  //   .sort(sortByField("date_created"));
  // const sorttasksDistantTime = sortedtasks.slice().sort(sorttasksByDateDue);
  // const sorttasksDistantTimeBackward = sortedtasks
  //   .slice()
  //   .sort(sorttasksByDateDueBackward);

  // const [sortedtasksForOutput, setSorted] =
  //   React.useState(sorttasksDistantTime);

  // const handleSortingtasks = () => {
  //   setSortDirection((prevState) =>
  //     prevState === "по возрастанию" ? "по убыванию" : "по возрастанию"
  //   );
  // };

  // const handleSortingtasksByDateCreated = () => {
  //   setSortByDueDirection((prevState) =>
  //     prevState === "по возрастанию" ? "по убыванию" : "по возрастанию"
  //   );
  // };

  // React.useEffect(() => {
  //   if (sortDirection === "по убыванию") {
  //     setSorted(sorttasksDistantTimeBackward);
  //   }
  //   if (sortDirection === "по возрастанию") {
  //     setSorted(sorttasksDistantTime)
  //   }
  // }, [input, sortDirection, tasks]);

  // React.useEffect(() => {
  //   if (sortByDueDirection === "по возрастанию") {
  //     setSorted(sorttasksByDateCreatedFromLast);
  //     dispatch(saveSortByDueSettings("по возрастанию"));
  //   }
  //   if (sortByDueDirection === "по убыванию") {
  //     setSorted(sorttasksByDateCreatedFromFirst);
  //     dispatch(saveSortByDueSettings("по убыванию"));
  //   }
  // }, [input, sortByDueDirection, tasks]);

  // const handleOpenPopup = () => {
  //   setOpenPopup(true);
  // };

  // const handleClosePopup = () => {
  //   setOpenPopup(false);
  // };

  const defaultFilters = JSON.parse(localStorage.getItem("tasks_settings"));
  const [filter, setFilter] = React.useState(defaultFilters?.filter || "all");

  React.useEffect(() => {
    if (!defaultFilters || defaultFilters?.filter === "all") {
      setTasks(tasksData);
    } else {
      const filtered = tasksData.filter(
        (item) => item.executor === defaultFilters?.filter
      );
      setTasks(filtered);
    }
    // console.log(tasks);
  }, [tasksData, props.updTask]);

  return (
    //   <Backdrop open sx={backdropStyles}>
    //     <CircularProgress />{" "}
    //   </Backdrop>
    // ) : (
    <div className="kanban">
      <div className="kanban__search_block">
        <div className="search_input_kanban">
          <InputLabel shrink htmlFor="select-tasks-kanban">
            Фильтровать задачи
          </InputLabel>
          <Select
            id="select-tasks-kanban"
            fullWidth
            size="small"
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value);
              if (event.target.value === "all") {
                setTasks(tasksData);
              } else {
                const filtered = tasksData.filter(
                  (item) => item.executor === event.target.value
                );
                setTasks(filtered);
                console.log("filtered", filtered);
              }
              localStorage.setItem(
                "tasks_settings",
                JSON.stringify(
                  {
                    filter: event.target.value,
                  } || {}
                )
              );
            }}
          >
            <MenuItem value="all">Задачи всех пользователей</MenuItem>
            {activeUsersSelection(users, activeUsers)}
          </Select>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "250px",
            marginRight: "20px",
          }}
        >
          По дедлайну:
          <Button variant="contained" onClick={handleSortingtasks} size="small">
            {sortDirection}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "250px",
            marginRight: "20px",
          }}
        >
          По дате создания:
          <Button
            variant="contained"
            onClick={handleSortingtasksByDateCreated}
            size="small"
          >
            {sortByDueDirection}
          </Button>
        </div> */}
      </div>
      {/* {error && <h3>Обнаружена ошибка:{error.message}</h3>} */}
      <div className="board__tasks">
        <div className="column">
          <h2>Новая({tasksCounter(tasks, "new")})</h2>

          <ul onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, "new")}>
            {tasks &&
              tasks
                .filter((task) => task.status === "new")
                .map((task) => (
                  <li
                    key={task.id_task}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task)}
                    onDoubleClick={() => {
                      handleDoubleClick(task);
                    }}
                  >
                    <TaskCard task={task} />
                  </li>
                ))}
          </ul>
        </div>
        <div className="column">
          <h2>В работе({tasksCounter(tasks, "in_work")})</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "in_work")}
          >
            {tasks &&
              tasks
                .filter((task) => task.status === "in_work")
                .map((task) => (
                  <li
                    key={task.id_task}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task)}
                    // onTouchStart={() => {
                    //   handleDoubleClick(task);
                    // }}
                    onDoubleClick={() => {
                      handleDoubleClick(task);
                    }}
                  >
                    <TaskCard task={task} />
                  </li>
                ))}
          </ul>
        </div>
        <div className="column">
          <h2>Выполнена</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "completed")}
          >
            {tasks &&
              tasks
                .filter((task) => task.status === "completed")
                .map((task) => (
                  <li
                    key={task.id_task}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task)}
                    onDoubleClick={() => {
                      handleDoubleClick(task);
                    }}
                  >
                    <TaskCard task={task} />
                  </li>
                ))}
          </ul>
        </div>
        <div className="column">
          <h2>Отказано в выполнении</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "denied_execution")}
          >
            {tasks &&
              tasks
                .filter((task) => task.status === "denied_execution")
                .map((task) => (
                  <li
                    key={task.id_task}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task)}
                    onDoubleClick={() => {
                      handleDoubleClick(task);
                    }}
                  >
                    <TaskCard task={task} />
                  </li>
                ))}
          </ul>
        </div>
        <div className="column">
          <h2>Закрыта</h2>
          <ul
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, "closed")}
          >
            {tasks &&
              tasks
                .filter((task) => task.status === "closed")
                .map((task) => (
                  <li
                    key={task.id_task}
                    draggable
                    onDragStart={(e) => handleDragStart(e, task)}
                    onDoubleClick={() => {
                      handleDoubleClick(task);
                    }}
                  >
                    <TaskCard task={task} />
                  </li>
                ))}
          </ul>
        </div>

        {openCard && (
          <TaskModalUpdate task={infoModal} onClose={onClose} {...props} />
        )}
        <SimpleConfirmModal
          isOpen={isStatusCheckModalOpen}
          onClose={closeCheckModal}
          onConfirm={handleCheckModalConfirm}
          content="Задачу может закрыть только ее создатель(автор)"
        />
        <ConfirmationDialogForTask
          open={isConfirmationOpen}
          onClose={handleCloseConfirmation}
          onConfirm={handleConfirmAction}
          title="Необходимо указать причину отказа"
          content=""
        />
      </div>
    </div>
  );
}

export default TasksKanban;
