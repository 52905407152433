/* eslint-disable react/no-array-index-key */
import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import "./SimpleTabs.scss";

const SimpleTabs = ({ locations, onLocationChange, selectedLocation }) => {
  const handleChange = (event, newValue) => {
    onLocationChange(newValue);
  };

  const entitiesTypes = useSelector((state) => state.entitiesTypes.location);
  if (!entitiesTypes || !locations) {
    return <div>Загрузка филиалов...</div>;
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedLocation}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        className="location-tabs"
      >
        {locations?.map((child, index) => (
          <Tab
            label={`${findLabelByID(child, entitiesTypes)}`}
            key={index}
            value={child}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default SimpleTabs;
