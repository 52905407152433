import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Button, CircularProgress, Link } from "@mui/material";
import DataGrid from "../DataGrid/DataGrid";
import { getContracts } from "../../redux/actions/contractActions";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import notification from "../../utils/notification/notification";
import { contractApi } from "../../utils/api";

const backdropStyles = {
  bgcolor: "#fff",
  zIndex: 2,
  position: "relative",
  height: "800px",
  borderRadius: "10px",
};

const AgreementsBase = ({ permissions, loadingContracts, ...props }) => {
  // eslint-disable-next-line no-debugger
  const dispatch = useDispatch();

  // passing customer information to the modal window
  const allContracts = useSelector(({ contracts }) => contracts);
  const entities = useSelector(({ entitiesTypes }) => entitiesTypes);

  const saveContract = (name) => {
    if (!name) {
      notification.info("невозможно скачать, договор удален");
      return;
    }
    contractApi.save(name).then((resp) => {
      const href = URL.createObjectURL(resp.data);
      const link = document.createElement("a");
      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      link.href = href;
      link.setAttribute("download", `${name}`);
      document.body.appendChild(link);
      // clean up "a" element & remove ObjectURL
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      notification.success("Договор сохранен");
    });
  };

  React.useEffect(() => {
    dispatch(getContracts());
  }, []);

  const CellRenderer = (value) => {
    let cellColor;

    switch (value) {
      case "active":
        cellColor = "#00ff00"; 
        break;
      case "cancelled":
        cellColor = "#ffcc00"; 
        break;
      case "closed":
        cellColor = "#ff0000"; 
        break;
      default:
        cellColor = "#ffffff"; 
    }

    const cellStyle = {
      backgroundColor: cellColor,
      width: "100%",
    };

    return <div style={cellStyle}>{value}</div>;
  };

  const columns = [
    {
      field: "id_contract",
      hide: true,
    },
    {
      field: "full_name",
      headerName: "Имя",
      flex: 2,
    },
    {
      field: "doc_name",
      headerName: "договор",
      renderCell: (params) => (
        <Button
          onClick={() => {
            saveContract(params.row.doc_name);
          }}
          size="small"
        >
          {params.row.doc_name}
        </Button>
      ),
    },
    {
      field: "phone",
      headerName: "Телефон",
      flex: 1,
    },
    {
      field: "date_of_create",
      headerName: "Создан",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Локация",
      valueFormatter: (params) =>
        findLabelByID(params.value, entities.location),
      flex: 1,
    },
    {
      field: "full_price",
      headerName: "Полная стоимость",
      flex: 1,
    },
    {
      field: "kid_name",
      headerName: "Имя ребенка",
      flex: 1,
    },
    {
      field: "course",
      headerName: "Программа",
      valueFormatter: (params) => findLabelByID(params.value, entities.course),
      flex: 1,
    },
    {
      field: "group_id",
      headerName: "Группа",
      valueFormatter: (params) => findLabelByID(params.value, entities.group),
      flex: 1,
    },
    {
      field: "status",
      headerName: "Статус",
      flex: 1,
      renderCell: (params) => CellRenderer(params.value),
    },
  ];

  return (
    <>
      {loadingContracts ? (
        <Backdrop open={loadingContracts} sx={backdropStyles}>
          <CircularProgress />{" "}
        </Backdrop>
      ) : (
        <>
          <DataGrid
            columns={columns}
            entityName="contracts"
            getRowId={(row) => `${row.id_contract}`}
            permissions={permissions}
            data={allContracts.contracts}
            loading={loadingContracts}
            {...props}
          />
        </>
      )}
    </>
  );
};

export default AgreementsBase;
