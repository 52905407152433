const initialState = {
  payload: [],
  supportHistory: [],
  loadingJournal: false,
  loadingHistory: false,
};

const sortJournal = (source) => {
  // eslint-disable-next-line array-callback-return
  source.map((item) => {
    const key = Object.keys(item);
    item[key].sort((a, b) => (a.date > b.date ? 1 : -1));
    item[key].map((obj) =>
      obj.lesson.sort((a, b) => (a.date > b.date ? 1 : -1))
    );
  });
  return source;
};

const dairyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_JOURNAL":
      return {
        ...state,
        payload: sortJournal(action.payload),
        loadingJournal: action.loading,
      };
    case "LOADING_JOURNAL":
      return {
        ...state,
        loadingJournal: action.loading,
      };
    case "LOADING_SUPPORT_HISTORY":
      return {
        ...state,
        loadingHistory: action.loading,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload.response.data.message,
      };
    case "SET_SUPPORT_HISTORY":
      return {
        ...state,
        supportHistory: action.payload,
        loadingHistory: action.loading,
      };
    // case "CLEAR_CONTRACT":
    // 	return initialState;
    default:
      return state;
  }
};

export default dairyReducer;
