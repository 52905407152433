const initialState = {
	payload: [],
	loading: false,
};

const paymentsScheduleReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_PAYMENTS_SCHEDULE":
			return {
				...state,
				payload: action.payload,
				loading: action.loading,
			};
		case "LOADING_PAYMENTS_SCHEDULE":
			return {
				...state,
				loading: action.loading,
			};
		default:
			return state;
	}
};

export default paymentsScheduleReducer;
