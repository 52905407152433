import React, { useEffect, useState } from "react";
import MultipleDatePicker from "react-multiple-datepicker";
// import DatePicker from "react-multi-date-picker";
import { connect, useSelector } from "react-redux";
import "./GroupsPage.scss";
import {
  Box,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  addNewGroup,
  fetchGroups,
  removeGroup,
  updateGroup,
  clearGroups,
} from "../../redux/actions/groupsActions";
import DataGrid from "../../components/DataGrid/DataGrid";
import {
  selectGetterFunc,
  selectFormatterFunc,
  dateSetterFunc,
  dateGetterFunc,
  dateFormatterFunc,
} from "../../utils/helpers/dataGridFormatters";
import { getNameFromArrayId } from "../../utils/helpers/entityNameById";
import Multiselect from "../../components/DataGrid/Multiselect";
import { setPageTitle } from "../../redux/actions/globalActions";
import notification from "../../utils/notification/notification";

// required structure and names of props
const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.groups.payload,
  loading: state.groups.loading,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: state.user.permissions.includes("group.add"),
    modify: state.user.permissions.includes("group.modify"),
    remove: state.user.permissions.includes("group.remove"),
  },
});
// required names for actions
const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(fetchGroups()).catch(() => dispatch(clearGroups())),
  addData: (obj) => {
    if (!obj.group.weeks) {
      notification.info("Необходимо заполнить поле 'Кол-во недель'");
      return;
    }
    dispatch(addNewGroup(obj)).catch(() => dispatch(clearGroups()));
  },
  updateData: (obj) =>
    dispatch(updateGroup(obj)).catch(() => dispatch(clearGroups())),
  removeData: (id) =>
    dispatch(removeGroup(id)).catch(() => dispatch(clearGroups())),
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function GroupsPage({ entitiesTypes, title, setTitle, ...props }) {
  // console.log("props GROUPS", props);
  const style = {
    "& .MuiSelect-single": {
      padding: "13px 12px",
    },
    subtitle: {
      paddingBottom: "10px",
    },
    span: {
      fontWeight: 400,
    },
  };

  useEffect(() => {
    setTitle(title);
  }, []);

  const teachers = useSelector((state) => state.entitiesTypes.user);

  const [selectValue, setSelectValue] = React.useState(false);
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  // ---------------- year, camp date-handlers, work with calendar
  const daySwitcher = (day) => {
    switch (day) {
      case "0":
        return "Пн";
      case "1":
        return "Вт";
      case "2":
        return "Ср";
      case "3":
        return "Чт";
      case "4":
        return "Пт";
      case "5":
        return "Сб";
      case "6":
        return "Вс";
      default:
        return "";
    }
  };

  const getDayOfWeek = (weekDaysArr) => {
    if (!weekDaysArr) {
      return [];
    }
    const outputArr = [];
    for (let i = 0; i < weekDaysArr.length; i++) {
      outputArr.push(daySwitcher(weekDaysArr[i]));
    }
    return outputArr;
  };

  const formatDate = (oneDate) => {
    let dd = oneDate.getDate();
    if (dd < 10) dd = `0${dd}`;

    let mm = oneDate.getMonth() + 1;
    if (mm < 10) mm = `0${mm}`;

    let yy = oneDate.getFullYear();
    if (yy < 10) yy = `0${yy}`;

    return `${yy}-${mm}-${dd}`;
  };

  const formatArrayDateCalendar = (calendarDatesArr) => {
    const outputArr = [];
    for (let i = 0; i < calendarDatesArr.length; i++) {
      outputArr.push(formatDate(calendarDatesArr[i]));
    }
    return outputArr;
  };
  // ---------------- year, camp date-handlers, work with calendar

  const getTeacherFromEntities = (teachersArr) => {
    const outputArr = [];
    if (!teachersArr) {
      return [];
    }
    if (teachers) {
      teachers.map((item) =>
        // eslint-disable-next-line array-callback-return
        teachersArr.map((teacher) => {
          if (item.value === teacher) outputArr.push(item.label);
        })
      );
    }
    return outputArr;
  };

  const detailsInitState = {
    short_name: "",
    teacher: "",
    students_count: "",
    camp: "",
    days: "",
  };

  const [selectDaysWeek, setSelectDaysWeek] = useState([]);

  const [campDates, setDates] = useState("");

  const handleSelectDateChange = (event) => {
    setSelectDaysWeek(event.target.value);
  };

  const [details, setRowDetails] = useState(detailsInitState);

  const handleRowDetails = (row) => {
    if (row.row.camp === true && row.field !== "day_of_the_week") {
      setSelectValue(true);
      const dates = row.row.training_day.map((item) => new Date(item));
      setDates(dates);
    }
    if (row.row.camp === false && row.field !== "day_of_the_week") {
      setSelectValue(false);
      setSelectDaysWeek(row.row.day_of_the_week);
    }
    setRowDetails({
      short_name: row.row.short_name,
      teacher: getTeacherFromEntities(row.row.teacher).join(" / "),
      students_count: row.row.students_count,
      camp: row.row.camp ? "Лагерь" : "Учебный год",
      days: row.row.camp
        ? row.row.training_day.join(" / ")
        : getDayOfWeek(row.row.day_of_the_week).join(" / "),
    });
    // console.log("details", details);
  };

  const amountFormatterFunc = (params) => {
    if (!params.value) {
      return "-";
    }
    return `${params.value}р.`;
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "short_name",
      headerName: "Название",
      editable: true, // see isCellEditable in DataGrid for details
      flex: 1,
    },
    {
      field: "course",
      headerName: "Уч. программа",
      editable: true,
      type: "singleSelect",
      valueOptions: entitiesTypes.course,
      valueGetter: selectGetterFunc,
      valueFormatter: (params) => selectFormatterFunc(params, entitiesTypes),
      flex: 1,
    },
    {
      field: "location",
      headerName: "Место проведения",
      editable: true,
      editableOnAdd: true, // see isCellEditable in DataGrid for details
      type: "singleSelect",
      valueOptions: entitiesTypes.location,
      valueGetter: selectGetterFunc,
      valueFormatter: (params) => selectFormatterFunc(params, entitiesTypes),
      flex: 1,
    },
    {
      field: "teacher",
      headerName: "Преподаватель",
      editable: false,
      flex: 1,
      // valueGetter: (params) => {
      //   if (params.row.isNew || !params.value || !params.value.length) {
      //     return [entitiesTypes.default.teacher];
      //   }
      //   if (params.value) {
      //     const namesArray = getNameFromArrayId(
      //       params.value,
      //       entitiesTypes.user
      //     );
      //     return namesArray.join(", ");
      //   }
      //   return "";
      // },
      valueGetter: (params) => {
        if (params.row.isNew || !params.value || !params.value.length) {
          return [entitiesTypes.default.teacher];
        }
        return params.value;
      },
      valueFormatter: (params) => {
        if (params.value) {
          const namesArray = getNameFromArrayId(
            params.value,
            entitiesTypes.user
          );
          return namesArray.join(", ");
        }
        return " ";
      },
      // valueSetter: (params) => ({ ...params.row, teacher: params }),
      renderEditCell: (params) => (
        <Multiselect options={entitiesTypes.user} {...params} />
      ),
      // defaultValue: true, // see setDefaultValue func in DataGrid for details
    },
    {
      field: "start_date",
      headerName: "Дата начала",
      type: "date",
      editable: true,
      valueGetter: dateGetterFunc,
      valueSetter: (params) => dateSetterFunc(params, "start_date"),
      valueFormatter: dateFormatterFunc,
      flex: 1,
    },
    {
      field: "students",
      hide: true,
    },
    {
      field: "students_count",
      headerName: "Количество учеников",
      editable: false,
      defaultValue: true,
      flex: 0.2,
      valueGetter: (params) => params.value || "0",
      valueFormatter: (params) => {
        if (params.value === "0") {
          return "--";
        }
        const pupils = `${params.value} чел.`;
        return pupils;
      },
    },
    {
      field: "camp",
      headerName: "Учебный год/ Лагерь",
      editable: true,
      flex: 0.6,
      type: "singleSelect",
      renderEditCell: () => (
        <Select
          fullWidth
          input={<OutlinedInput />}
          value={selectValue}
          onChange={handleSelectChange}
          sx={style}
        >
          <MenuItem value={false}>Учебный год</MenuItem>
          <MenuItem value>Лагерь</MenuItem>
        </Select>
      ),
      // eslint-disable-next-line consistent-return
      valueSetter: (params) => ({ ...params.row, camp: selectValue }),
      valueFormatter: (params) => {
        if (params.value === false) return "учебный год";
        return "лагерь";
      },
    },
    {
      field: "day_of_the_week",
      headerName: "Дни учебы",
      editable: true,
      flex: 0.6,
      // eslint-disable-next-line consistent-return
      renderEditCell: () => {
        if (selectValue) {
          return (
            <div>
              <MultipleDatePicker
                className="multi_date_picker"
                value={campDates}
                // onChange={handlePickerChanger}
                onSubmit={(dates) => {
                  setDates(dates);
                }}
              />
              {/* <DatePicker
                className="multi_date_picker"
                value={campDates}
                multiple
                format="YYYY-MM-DD"
                onChange={(dates) => {
                  setDates(dates);
                  console.log(dates);
                }}
              /> */}
            </div>
          );
        }
        if (!selectValue) {
          return (
            <Select
              fullWidth
              multiple
              input={<OutlinedInput />}
              value={selectDaysWeek}
              onChange={handleSelectDateChange}
              sx={style}
            >
              <MenuItem value="6">воскресенье</MenuItem>
              <MenuItem value="0">понедельник </MenuItem>
              <MenuItem value="1">вторник</MenuItem>
              <MenuItem value="2">среда</MenuItem>
              <MenuItem value="3">четверг</MenuItem>
              <MenuItem value="4">пятница</MenuItem>
              <MenuItem value="5">суббота</MenuItem>
            </Select>
          );
        }
      },
      // eslint-disable-next-line consistent-return
      valueGetter: (params) => {
        if (params.row.camp === true) {
          // console.log(params.row);
          params.value = params.row.training_day;
          return params.row.training_day;
        }
        params.value = getDayOfWeek(params.row.day_of_the_week);
        return getDayOfWeek(params.row.day_of_the_week);

        // return params.value;
      },
      valueSetter: (params) => {
        if (params.row.camp === true) {
          // console.log(params.row);
          return {
            ...params.row,
            training_day: formatArrayDateCalendar(campDates),
            day_of_the_week: [],
          };
        }
        return {
          ...params.row,
          day_of_the_week: selectDaysWeek,
          training_day: [],
        };
      },
      // eslint-disable-next-line consistent-return
      valueFormatter: (params) => {
        if (params.value) return params.value;
      },
    },
    {
      field: "training_day",
      hide: true,
    },
    {
      field: "weeks",
      headerName: "Кол-во недель",
      editable: true,
      flex: 0.5,
      editProps: {
        required: true, // Add required field validation
      },
    },
    {
      field: "price_per_week",
      headerName: "Цена",
      editable: true,
      flex: 0.2,
      valueFormatter: (params) => amountFormatterFunc(params),
    },
  ];

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>

      <Box sx={{ padding: "0 30px 0 30px" }}>
        <Stack>
          <Typography variant="h6" component="span" sx={style.subtitle}>
            Название: <span style={style.span}>{details.short_name}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={style.subtitle}>
            Преподаватель: <span style={style.span}>{details.teacher}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={style.subtitle}>
            Количество человек:{" "}
            <span style={style.span}>{details.students_count}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={style.subtitle}>
            Вид: <span style={style.span}>{details.camp}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={style.subtitle}>
            Расписание: <span style={style.span}>{details.days}</span>
          </Typography>
        </Stack>
      </Box>

      <DataGrid
        onCellClick={(event, row) => handleRowDetails(event, row)}
        columns={columns}
        entityName="group"
        dataGridName="groups"
        {...props}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
