import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Lessons from "../../components/Lessons/Lessons";
import {
  postSelectedLesson,
  setLessonsToState,
  updateSelectedLesson,
} from "../../redux/actions/ScheduleOfClassesActions";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";

function LessonsContainer({ ...props }) {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const { loading, getLessons, title, setTitle } = props;

  useEffect(() => {
    getLessons();
  }, []);

  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <>
      <div className="content-component__wrapper">
        {/* <h4 className="table__title">{title}</h4> */}
        {loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <Lessons {...props} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.schedule.loading,
  lessons: state.schedule.payload,
  days: state.schedule.days,
});

const mapDispatchToProps = (dispatch) => ({
  getLessons: (data) => {
    dispatch(setLessonsToState(data));
  },
  postLessons: (lessons) => {
    dispatch(postSelectedLesson(lessons));
  },
  updateLessons: (lessons) => {
    dispatch(updateSelectedLesson(lessons));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LessonsContainer);
