// * Important: the request to the server must already contain content type blob
// * otherwise the file will not be downloaded. Do it globally in the axiosConfig.js file.
// * or do in actions, for example, in the reportsLinks.js file.
// * or to response, not response.data !

const downloadFile = (
  response,
  defaultFileName = "Name_of_file.docx",
  overrideFileName = null
) => {
  const confirmation = window.confirm("Вы хотите скачать файл?");
  if (!confirmation) {
    return;
  }
    let blob;
    const contentType =
      response.headers["content-type"] ||
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    // CORS can block access to the Content-Disposition header, so we need to check both
    // (в нетворке может быть видно, в респонсе нет)
    const contentDisposition = response.headers["content-disposition"];
    let fileName = defaultFileName;

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      );
      if (fileNameMatch.length > 1) {
        fileName = fileNameMatch[1].replace(/['"]/g, ""); // Extract and clean filename
      }
    }
    if (overrideFileName) {
      fileName = overrideFileName;
    }
    if (response.data instanceof Blob) {
      blob = response.data;
    } else {
      blob = new Blob([response.data], { type: contentType });
    }

    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
export default downloadFile;
