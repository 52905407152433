import { useSnackbar } from "notistack";

let useSnackbarRef;
export const SnackbarConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg, options = {}) {
    this.toast(msg, { ...options, variant: "success" });
  },
  warning(msg, options = {}) {
    this.toast(msg, { ...options, variant: "warning" });
  },
  info(msg, options = {}) {
    this.toast(msg, { ...options, variant: "info" });
  },
  error(msg, options = {}) {
    this.toast(msg, { ...options, variant: "error" });
  },
  toast(msg, options = {}) {
    const opt = {
      variant: "default",
      ...options,
    };
    useSnackbarRef.enqueueSnackbar(msg, opt);
  },
};
