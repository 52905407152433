import { customersApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getCustomers = (data) => ({
  type: "GET_CUSTOMERS",
  payload: data,
  loading: false,
});

export const loadingCustomers = () => ({
  type: "LOADING_CUSTOMERS",
  loading: true,
});

export const addCustomer = (data) => ({
  type: "ADD_CUSTOMER",
  payload: data,
  loading: false,
});

export const updCustomer = (data) => ({
  type: "UPDATE_CUSTOMER",
  payload: data,
  loading: false,
});

export const deleteCustomer = (data) => ({
  type: "REMOVE_CUSTOMER",
  payload: data,
  loading: false,
});

export const cancelCustomersPost = () => ({
	type: "CANCEL_CUSTOMERS_POST",
	payload: null,
	loading: false,
});

export const fetchCustomers = () => (dispatch) => {
  dispatch(loadingCustomers());
  console.log("hello");
  customersApi.get().then(({ data }) => dispatch(getCustomers(data.customers)));
};

export const postCustomer = (obj) => (dispatch) => {
  dispatch(loadingCustomers());
  return customersApi
    .add(obj)
    .then(({ data }) => {
      notification.success("Пользователь добавлен");
      if (data === "ok") {
        // dispatch(addCustomer(data));
        window.location.pathname = "/app/parents-create";
      }
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelCustomersPost());
    });
};

export const updateCustomer = (obj) => (dispatch) => {
  dispatch(loadingCustomers());
  return customersApi.update(obj).then(({ data }) => {
    notification.success("Данные о пользователе обновлены");
    console.log("redux", data);
    // dispatch(updCustomer(data));
    window.location.reload();
  });
};

export const removeCustomer = (data) => (dispatch) => 
  // dispatch(loadingCustomers());
   customersApi.remove(data).then((response) => {
    notification.success('Пользователю назначен статус "disabled"');
    console.log("id", response.data);
    dispatch(deleteCustomer(response.data));
  })
;
