import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "../../scss/index.scss";
import CRMTabs from "../../components/TabsComponent/CRMTabs";
import ContactsBase from "../../components/SalesView/ContactsBase";
import Leads from "../../components/SalesView/LeadsKanban";
// import Tasks from "../../components/Sales /Tasks";
import AgreementsBase from "../../components/SalesView/AgreementsBase";
import { fetchContacts } from "../../redux/actions/contactsActions";
import { fetchLeads } from "../../redux/actions/leadsActions";
import { setPageTitle } from "../../redux/actions/globalActions";

function Sales({ ...props }) {
  const {
    loadingLeads,
    fetchContact,
    fetchLead,
    leads,
    contacts,
    setTitle,
    title,
  } = props;
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  // useEffect(() => {
  //   if (leads.payload.length === 0) {
  //     fetchContact();
  //   }
  // }, []);

  useEffect(() => {
    if (contacts.payload.length === 0) {
      fetchLead();
    }
  }, []);

  useEffect(() => {
    setTitle(title);
  }, []);
  return (
    <>
      {/* <div className="content-component__wrapper"> */}

      {appGlobalState.loading || loadingLeads ? (
        <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
          <CircularProgress />{" "}
        </Backdrop>
      ) : (
        <CRMTabs>
          <Leads value="1" label="Лиды" {...props} key="1" />
          <AgreementsBase value="2" label="Договоры" {...props} key="2" />
          <ContactsBase
            value="3"
            label="Расширенный поиск ТЕСТ"
            {...props}
            key="3"
          />
        </CRMTabs>
      )}
      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingContacts: state.contacts.loading,
  loadingLeads: state.leads.loading,
  loadingContracts: state.contracts.loading, // contacts and contRacts state
  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  contacts: state.contacts,
  leads: state.leads,
  permissions: {
    add: false,
    modify: false,
    remove: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  fetchContact: (data) => {
    dispatch(fetchContacts(data));
  },
  fetchLead: (data) => {
    dispatch(fetchLeads(data));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
