/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "./TaskCard.scss";
import { format, parseISO } from "date-fns";
import { ru } from "date-fns/locale";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

const TaskCard = ({ task }) => {
  const managers = useSelector(({ entitiesTypes }) => entitiesTypes.user);

  // function settaskTimeStatus(date_due) {
  //   const twoHoursInMillis = 2 * 60 * 60 * 1000; // Two hours in milliseconds
  //   const dueDate = new Date(date_due);
  //   const currentTime = new Date();
  //   // Calculate the time difference in milliseconds
  //   const timeDiff = dueDate - currentTime;
  //   if (timeDiff < 0) {
  //     return "expired-task";
  //   }
  //   if (timeDiff < twoHoursInMillis) {
  //     return "approaching-deadline-task";
  //   }
  //   return "new-task";
  // }

  // time formatter
  const getTimeAndDate = (time) => {
    const timeAndDate = parseISO(time);
    const formattedDate = format(timeAndDate, "dd MMMM yyyy", {
      locale: ru,
    });
    return formattedDate;
  };

  // get comment from object with object as
  const getComment = (source) => {
    if (source) {
      const { comment = "" } = source[source.length - 1];
      return comment;
    }
    return "недопустимый объект";
  };

  const getCreator = (source) => {
    if (source) {
      const { creator = "неизвестный" } = source[source.length - 1];
      const finded = findLabelByID(creator, managers);
      return finded;
    }
    return "неизвестный";
  };

  const [expired, setExpired] = React.useState(false);
  const dateCompare = (date) => {
    const nowDateObject = dayjs();
    const dateDueObject = dayjs(date);
    if (nowDateObject < dateDueObject) {
      setExpired(false);
    } else if (nowDateObject > dateDueObject) {
      setExpired(true);
    } else {
      setExpired(false);
    }
  };

  React.useEffect(() => {
    dateCompare(task.date_due);
  }, [task.date_due]);

  const taskCreator = findLabelByID(task.autor, managers);
  const taskExecutor = findLabelByID(task.executor, managers);

  return (
    <div className={`task-card  ${task.status}`}>
      <Tooltip
        key={task.title}
        TransitionProps={{ timeout: 800 }}
        title={
          <Typography color="inherit" fontSize="12px">
            Создана: {task.date_created}
          </Typography>
        }
        arrow
        placement="top-end"
      >
        <div className="task-name">{task.title || ""}</div>
      </Tooltip>

      <div className="task-info-comment">{task.description}</div>

      <div className="task-details">
        <Tooltip
          key={task.date_due}
          TransitionProps={{ timeout: 800 }}
          title={
            <Typography color="inherit" fontSize="12px">
              Дедлайн: {task.date_due}
            </Typography>
          }
          arrow
          placement="top-end"
        >
          <div className="task-time-date">
            Дедлайн: {getTimeAndDate(task.date_due)}
          </div>
        </Tooltip>
      </div>
      <Tooltip
        key={task.author}
        TransitionProps={{ timeout: 800 }}
        title={
          <Typography color="inherit" fontSize="12px">
            Исполнитель: {taskExecutor}
            <br />
            Создатель: {taskCreator}
          </Typography>
        }
        arrow
        placement="top-end"
      >
        <div className="task-info-item manager">({taskExecutor})</div>
      </Tooltip>
      {expired &&
      task.status !== "closed" &&
      task.status !== "denied_execution" &&
      task.status !== "completed" ? (
        <div className="task-info-item expired">просрочено</div>
      ) : null}
    </div>
  );
};

export default TaskCard;
