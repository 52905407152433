import React from "react";
import "./CustomLink.scss";

const CustomLink = ({ url, label }) => (
  <a
    href={url}
    className="external-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    {label}
  </a>
);

export default CustomLink;
