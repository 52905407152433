/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
  
  
  
  // function filtering groups by selecting "location" or "course" or two at once
  const filterArray = (array, course, location) =>
    array?.filter(
      (item) => {
        if (course === "") {
          if (item.location_id === location) return item;
        }
        if (location === "") {
          if (item.course_id === course) return item;
        }
        if (item.course_id === course && item.location_id === location) {
          return item;
        }
      }
    );


    export default filterArray;
