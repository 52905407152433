const initialState = {
	loading: true,
	isBarShow: false,
	currentPage: "", 
	tab: ""
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
			};
		case "LOADED":
			return {
				...state,
				loading: false,
			};
		case "HIDE_SIDEBAR_ACTION":
			return {
				...state,
				hideSidebar: action.data,
			};
			case "SET_PAGE_TITLE":
				return {
					...state,
					currentPage: action.payload,
				};
			case "SET_SPREAD_SEARCH": 
			return {
				...state,
				tab: action.payload,
			};
		default:
			return state;
	}
};

export default globalReducer;
