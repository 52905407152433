import React, { useEffect } from "react";
import "./Balance.css";
import { DataGridPro } from "../../dataGridLib/x-data-grid-pro";

function Balance({ balance }) {
  const columns = [
    {
      field: "date",
      headerName: "Дата",
      type: "date",
      flex: 1,
      editable: false, // see isCellEditable in DataGrid for details
      // valueGetter: dateGetterFunc,
      // valueSetter: (params) => dateSetterFunc(params, "date"),
      // valueFormatter: dateFormatterFunc,
    },
    {
      field: "amount",
      headerName: "Сумма(Дебет)",
      flex: 1,
      editable: false,
      editableWithEmptyValue: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return `${params.value}р.`;
      },
    },
    {
      field: "credit",
      headerName: "Кредит",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return `${params.value}р.`;
      },
    },
  ];

  return (
    <div>
      {balance?.data.map((item) => {
        const itemKey = Object.keys(item)[0];
        return (
          <React.Fragment key={itemKey}>
            <div className="child_name">{itemKey}</div>
            <div
              className="child_balance_table"
              style={{ height: "auto", width: "100%" }}
            >
              <div style={{ height: 300, width: "100%" }}>
                <DataGridPro
                  rows={item[itemKey]}
                  columns={columns}
                  getRowId={(row) => `${row.id || 0}${row.date}`}
                  density="compact"
                  sx={{
                    boxShadow: 2,
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Balance;
