const initialState = {
  payload: [],
  loading: false,
  contracts: [],
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTRACT":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "UPDATE_CONTRACT":
      return {
        ...state,
        payload: state.payload.map((receivable) =>
          receivable.id === action.payload.id ? action.payload : receivable
        ),
        loading: action.loading,
      };
    case "LOADING_CONTRACT":
      return {
        ...state,
        loading: action.loading,
      };
    case "LOAD_REASONS":
      return {
        ...state,
        reasons: action.payload,
        working: "yes",
      };
    case "GET_CONTRACTS":
      return {
        ...state,
        contracts: action.payload,
        loading: action.loading,
      };
    case "CLEAR_CONTRACT":
      return initialState;
    default:
      return state;
  }
};

export default contractReducer;
