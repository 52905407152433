/* eslint-disable array-callback-return */

import dayjs from "dayjs";

/* eslint-disable consistent-return */
const initialState = {
  payload: [],
  error: false,
  loading: false,
};

const getDefaultUser = () => {
  const defaultUser = JSON.parse(localStorage.getItem("user")) || {};
  return defaultUser?.id || "";
};

const checkDuplicates = (s, a) =>
  s.some((item) => item.id_task === a.id_task) ? [...s] : [...s, a];

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASKS": // все
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };
    case "ADD_TASK":
      return {
        ...state,
        payload: checkDuplicates(state.payload, action.payload),
        loading: false,
      };

    case "UPD_TASK":
      return {
        ...state,
        payload: state.payload.map((task) => {
          if (task.id_task === action.payload.id_task) {
            return { ...task, ...action.payload };
          }
          return task;
        }),
        loading: false,
      };
    case "SET_PERSONAL_TASKS":
      return {
        ...state,
        payload: action.payload,
        loading: false,
      };

    case "ADD_COMMENT":
      return {
        ...state,
        payload: state.payload.map((task) => {
          if (task.id_task === action.payload.id_task) {
            task?.history?.push({
              date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              info: action.payload.comment,
              type: "comment",
              creator: getDefaultUser(),
            });
          }
          return task;
        }),
        loading: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case "UPD_TASK_STATUS":
      return {
        ...state,
        payload: state.payload.map((task) => {
          if (task.id_task === action.payload.id_task) {
            task.history.push(action.payload.info);
          }
          return task;
        }),
        loading: false,
      };

    case "LOADING_TASKS":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default tasksReducer;
