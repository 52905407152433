import React from "react";

import SnackProvider from "./utils/notification/SnackProvider";
import { SnackbarConfigurator } from "./utils/notification/notification";
import Routes from "./routes";
import MaintenancePage from "./pages/Maintenance/MaintenancePage";

// TODO debounce
// TODO home route without permissions

const clientConfig = window.clientConfig || {};
const isMaintenanceMode = clientConfig.maintenance || false;

function App() {
  return isMaintenanceMode ? (
    <MaintenancePage />
  ) : (
    <>
      <SnackProvider maxSnack={3} autohideduration={3000}>
        <SnackbarConfigurator />
        <Routes />
      </SnackProvider>
    </>
  );
}

export default App;
