export const sortByFieldBackward = (field) => (a, b) =>
  a[field] < b[field] ? 1 : -1;

export const sortByField = (field) => (a, b) => a[field] > b[field] ? 1 : -1;



const extractSortKey = (label) => {
  // Extract the first word from the label for alphabetical sorting
  const firstWord = label.split(' ')[0].toLowerCase();
  // Extract numeric class values and class-related keywords ("класс", "кл", "группа", or "сад")
  const classMatch = label.match(/(\d+)(\s*[а-я]*)?\s*(класс|кл|группа|сад)/i);
  // Extract class number if present
  const classNumber = classMatch ? parseInt(classMatch[1], 10) : null;
  // Extract class keyword if present (for secondary sorting)
  const classKeyword = classMatch ? classMatch[3].toLowerCase() : '';
  return { firstWord, classNumber, classKeyword };
};

export function sortGroupsByClassAndSad(entries) {
  return entries.sort((a, b) => {
      const keyA = extractSortKey(a.label);
      const keyB = extractSortKey(b.label);

      // Compare first word alphabetically
      if (keyA.firstWord < keyB.firstWord) return -1;
      if (keyA.firstWord > keyB.firstWord) return 1;

      // Compare by class number (if both have a number)
      if (keyA.classNumber !== null && keyB.classNumber !== null) {
          return keyA.classNumber - keyB.classNumber;
      }

      // If only one has a class number, it comes first
      if (keyA.classNumber !== null) return -1;
      if (keyB.classNumber !== null) return 1;
      
      return keyA.classKeyword.localeCompare(keyB.classKeyword);
  });
}

