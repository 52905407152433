const initialState = {
  payload: [],
  loading: false,
};

const debtReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DEBTS":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "LOADING_DEBT":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default debtReducer;
