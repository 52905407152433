import { applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import paymentsReducer from "./paymentsReducer";
import debtReducer from "./debtReducer";
import contractReducer from "./contractReducer";
import paymentsScheduleReducer from "./paymentsScheduleReducer";
import loginReducer from "./loginReducer";
import groupsReducer from "./groupsReducer";
import reportReducer from "./reportReducer";
import entitiesTypesReducer from "./entitiesTypesReducer";
import globalReducer from "./globalReducer";
import ScheduleOfClassesReducer from "./ScheduleOfClassesReducer";
import studentsKanbanReducer from "./studentsKanbanReducer";
import topicsAndDatesReducer from "./topicsAndDatesReducer";
import journalReducer from "./journalReducer";
import workingOutReducer from "./workingOutReducer";
import attendanceReducer from "./attendanceReducer";
import accessReducer from "./accessReducer";
import appointmentsReducer from "./appointmentsReducer";
import creatingCustomersReducer from "./creatingCustomersReducer";
import contactsReducer from "./contactsReducer";
import leadsReducer from "./leadsReducer";
import tasksReducer from "./tasksReducer";
import notificationsReducer from "./notificationsReducer";

// const specificReducer = combineReducers({
//   payments: paymentsReducer,
//   debt: debtReducer,
//   contracts: contractReducer,
//   pivot: paymentsScheduleReducer,
//   user: loginReducer,
//   groups: groupsReducer,
// });
//
// function entityReducer(state, action) {
//   if (action.entityType == 'DEBT') {
//     state.debt = debtReducer(state.debt, action);
//   }
//   return state;
// }
//
// function rootReducer(state, action) {
//   const reducer = (action.type == 'GET_ENTITY') ? entityReducer : specificReducer;
//   return reducer(state, action);
// }

const rootReducer = combineReducers(
  {
    globalState: globalReducer,
    payments: paymentsReducer,
    debt: debtReducer,
    contracts: contractReducer,
    payments_schedule: paymentsScheduleReducer,
    user: loginReducer,
    groups: groupsReducer,
    report: reportReducer,
    entitiesTypes: entitiesTypesReducer,
    schedule: ScheduleOfClassesReducer,
    studentsKanban: studentsKanbanReducer,
    topicsAndDates: topicsAndDatesReducer,
    temporary_arrangement: workingOutReducer,
    journal: journalReducer,
    attendance: attendanceReducer,
    access: accessReducer,
    appointmentsAndSubstitute: appointmentsReducer,
    customers: creatingCustomersReducer,
    contacts: contactsReducer,
    leads: leadsReducer,
    tasks: tasksReducer,
    notifications: notificationsReducer ,
  },
  applyMiddleware(thunk)
);

export default rootReducer;
